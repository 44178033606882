.table
  color: $base-font-color

  td,
  th
    border: 1px solid #dddddd
    border-width: 0 0 1px
    vertical-align: top
  th
    &:not([align])
      text-align: inherit
  tbody
    tr
      &:last-child
        td,
        th
          border-bottom-width: 0
  &.is-thw-30
    th
      width: 40%
  &.is-thw-25
    th
      width: 25%

  &.is-fullwidth
    width: 100%
    td,
    th
      padding: $half-gutter-width
  &.is-striped
    tbody
      tr:not(.is-selected)
        &:nth-child(even)
          background-color: $color-palegray
  &.is-responsive
    @include sm
      th
        min-width: 140px
    @include -sm
      tr,th
        display: block
        width: 100%
      td
        border: none
      tr:last-child
        th
          border-bottom: 1px solid #dddddd
