.l-col-side
  background: $color-beige
  padding: $gutter-width
  >*
    margin-bottom: $gutter-width
    &:last-child
      margin-bottom: 0
  @include md
    padding: 0
    padding-right: $half-gutter-width
    padding-left: 3rem





  .box-side
    padding: $size-3

    &.is-white
      background-color: $color-white

    &.is-nogutter
      padding: 0
    
    &.is-fullsize
      @include smmd
        width: 100%
  .box-side-bana
    // padding: $gutter-width
    a
      display: block
      margin-bottom: 10px
      &:last-child
        margin-bottom: 0
      img
        width: 100%

  hr
    border-top: 1px solid $color-beige
    margin: 1em 0


  @include smmd
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    align-items: flex-start

    >*
      width: 49%
      margin: $half-gutter-width 0
