=center($width, $height: 0)
  position: absolute
  @if $height != 0
    left: calc(50% - (#{$width} / 2))
    top: calc(50% - (#{$height} / 2))
  @else
    left: calc(50% - (#{$width} / 2))
    top: calc(50% - (#{$width} / 2))

@keyframes spinAround
  from
    transform: rotate(0deg)
  to
    transform: rotate(359deg)

=loader
  animation: spinAround 500ms infinite linear
  border: 2px solid $color-gray
  border-radius: 290486px
  border-right-color: transparent
  border-top-color: transparent
  content: ""
  display: block
  height: 1em
  position: relative
  width: 1em

%loader
  +loader

.button
  border: 1px solid transparent
  border-radius: 4px
  border-color: #dbdbdb
  box-shadow: none
  display: inline-flex
  height: 2.5em
  justify-content: flex-start
  line-height: $base-line-height
  font-size: $size-4
  position: relative
  vertical-align: top
  background-color: $color-white
  color: $base-font-color
  cursor: pointer
  justify-content: center
  // padding-bottom: calc(1em - 1px)
  padding-left: $size-1
  padding-right: $size-1
  // padding-top: calc(1em - 1px)
  text-align: center
  white-space: nowrap
  -webkit-appearance: none
  align-items: center
  &:hover
    @include sm
     opacity: 0.8

  &.is-primary
    background-color: $color-pink
    border-color: transparent
    color: $color-white
    &.is-active
      pointer-events: none
    &.is-outlined
      background-color: transparent
      border-color: $color-pink
      color: $color-pink
    &.is-loading
      &::after
        border: 2px solid $color-white
        border-radius: 290486px
        border-right-color: transparent
        border-top-color: transparent

  &.is-small
    font-size: $size-5
  &.is-medium
    font-size: $size-3
  &.is-big
    font-size: $size-1
  &.is-fullsize
    width: 100%

  &.is-rounded
    border-radius: $radius-rounded

  &.is-disable
    pointer-events: none
    background: $color-palegray
    color: $color-gray

  &.is-loading
    color: transparent !important
    pointer-events: none
    &::after
      @extend %loader
      +center(1em)
      position: absolute !important

  &.is-author
    border-radius: $radius-rounded
    padding: $half-gutter-width $gutter-width $half-gutter-width 2px
    img
      width: 2em
      height: 2em


  &.is-paragraph
    height: 3em
    line-height: 1.25
    .fz-i
      font-size: 150% !important
    .paragraph-inner
      font-size: $size-3
      display: flex
      flex-direction: column
      align-items: center
      padding: 0.5em 0.25em 0.5em
      @include md
        flex-direction: row
        padding: 0.25em
      span
        font-size: 75%
        padding-left: 1em
        @include md
          padding-right: 0.5em

button.button[disabled]
  pointer-events: none
  background: $color-palegray
  color: $color-gray



.link
  &.is-text
    cursor: pointer
    color: $color-blue
    border-bottom: 1px solid
    display: inline-block
  &.is-arrow
    border-bottom: 1px solid $color-palegray
    display: inline-flex
    justify-content: flex-start
    line-height: 1.25
    cursor: pointer
    align-items: center
    margin: 2px
    padding: 5px 0
  &.is-box
    display: inline-block
    padding: $half-gutter-width  $gutter-width
    margin: 5px
    border-radius: $radius
    font-weight: bold
    &.is-blue
      background: rgba($color-green,15%)
  &.is-icon
      border-bottom: 1px solid $color-palegray
      display: inline-flex
      justify-content: flex-start
      line-height: 1.25
      cursor: pointer
      align-items: center
      margin: 2px 4px
      padding: 5px 0
      .babysymbol
        font-size: $size-5

.button-flex
  border: 1px solid transparent
  border-radius: 4px
  border-color: $color-supergray
  box-shadow: none
  display: flex
  justify-content: flex-start
  font-size: $size-4
  position: relative
  vertical-align: top
  background-color: $color-white
  color: $base-font-color
  cursor: pointer
  padding: $gutter-width $half-gutter-width
  line-height: 1.25
  -webkit-appearance: none
  align-items: center
  + .button-flex
    margin-top: $half-gutter-width
  &:hover
    @include sm
     opacity: 0.8

  &.is-small
    font-size: $size-5
    padding: $half-gutter-width

  &.is-center
    justify-content: center

  &.is-arrow
    position: relative
    padding-right: 20px
    &:after
      display: inline-block
      font-family: "BabySymbols"
      content: "\e602"
      position: absolute
      right: 5px
      top: 50%
      transform: translateY(-50%)
      color: $color-pink
      font-weight: bold

  &.nolink
    cursor: default
    &:hover
      @include sm
       opacity: 1

  &.is-danger
    &.caution
      flex-direction: column
      align-items: self-start
      margin-left: 0
      margin-right: 0
      color: #333
      &.c-red
        color: #d22407
      .caution-message
        display: block
        font-weight: normal


