.l-header
  padding: $size-5 0
  border-bottom : 1px solid $color-palegray
  position: relative
  z-index: 100

.l-header-nav
  font-weight: bold
  display: none
  @include md
    display: block
  &>ul
    display: flex 
    @media screen and (max-width: 1430px)
      margin-right: 90px
      
    
    &>li
      position: relative
      width: calc(100% / 8)
      // height: 100% 
      // float: left
      // text-align: center
      // z-index: 9999
      &.hasul:after
        width: 0
        height: 0
        border: 0.313em solid transparent
        border-bottom: none
        border-top-color: $color-pink
        content: ''
        vertical-align: middle
        display: inline-block
        position: absolute
        right: 4%
        top: 40%
    a,span
      display: block
      display: flex
      height: 100%
      width: 100%
      align-items: center
      text-align: center
      justify-content: center
      line-height: 1.3
    span
      cursor: default
    ul.sub-menu
      opacity: 0
      visibility: hidden
      left: -9999px
      margin-top: -10px
      position: absolute
      border: 1px solid $color-palegray
      top: 100%
      width: 190px
      li
        position: relative
        background: #fff
        &:hover
          background: $color-palegray
          transition: background 0.3s
        a
          display: block
          padding: 10px
          text-align: left
    li:hover
      &>ul
        visibility: visible
        opacity: 1
        margin-top: 0
        transition: margin-top 0.3s ease
        left: 100%
      .sub-menu
        left: 0
