/* グルーバルメニュー

.l-gnav
  position: fixed
  z-index:  110
  top: 0
  right: 0
  height: 100%
  overflow: auto
  background: $color-beige
  transform: translateX(100%)
  transition: transform 0.6s
  width: 100%
  -webkit-overflow-scrolling: touch
  padding: $gutter-width
  @include md
    width: 600px

  &.is-active
    transform: translateX(0)

.l-status
  padding: $gutter-width
  img.is-senmonka
    width: 40px
    height: 40px
  .l-status-col-l
    border-right: 1px solid $color-pink
    justify-content: center
    align-items: center
    display: flex

.l-gnav-links
  padding: 5px $gutter-width
  font-size: $size-5
  .col-xs-12
    margin-bottom: 6px
  ul
    li
      + li
        margin-top: 6px
      p
        padding: 16px 12px
        background: $color-white
        .babysymbol
          margin-right: 8px
          color: $color-pink
          font-size: 18px
          vertical-align: middle
          top: -1px
      a
        display: block
        padding: 16px 12px
        letter-spacing: -0.02em
        background: $color-white
        color: $base-font-color
        .babysymbol
          margin-right: 8px
          color: $color-pink
          font-size: 18px
          vertical-align: middle
          top: -1px
      ul
        &>li
          + li
            border-top: 1px dotted $color-beige
            margin-top: 0
          &>a
            padding-left: 42px
.l-gnav-sns
  text-align: center
  a
    display: inline-block
    padding: 0 12px
    @include md
      padding: 0 10px

.gnav-toggle
  display: block
  position: fixed
  right: $size-4
  top: 8px
  width: 40px
  height: 40px
  cursor: pointer
  z-index: 115
  // background: $color-beige
  background: rgba($color-beige,70%)
  backdrop-filter: blur(3px)
    // border:   1px solid #fff
  text-align: center
  span
    display: block
    position: absolute
    width: 30px
    border-bottom: solid 1px $base-font-color
    -webkit-transition: .35s ease-in-out
    -moz-transition: .35s ease-in-out
    transition: .35s ease-in-out
    left: 5px
    &:nth-child(1)
      top: 10px
    &:nth-child(2)
      top: 19px
    &:nth-child(3)
      top: 28px
    &:nth-child(4)
      border: none
      color: #eee
      font-size: 9px
      font-weight: bold
      top: 34px
  &.is-active
    background: transparent
    span
      &:nth-child(1)
        top: 18px
        left: 6px
        -webkit-transform: rotate(-45deg)
        -moz-transform: rotate(-45deg)
        transform: rotate(-45deg)
      &:nth-child(2), &:nth-child(3)
        top: 18px
        -webkit-transform: rotate(45deg)
        -moz-transform: rotate(45deg)
        transform: rotate(45deg)


.ovf-hidden .g-overlay
  height: 100%
  opacity: 1
  transition: opacity 0.3s ease 0s, height 0s cubic-bezier(0.6, 0, 0.2, 1) 0s

.g-overlay
  background-color: rgba(195, 190, 186, 0.7)
  opacity: 0
  transition: opacity 0.3s ease 0s, height 0s cubic-bezier(0.6, 0, 0.2, 1) 0.5s
  height: 0
  z-index: 105
  position: fixed
  top: 0
  left: 0
  width: 100%
  overflow: hidden
