/* ADDITIONAL RESET
 * -----------------------------------------------*/

html
  font-size: 62.5%

img
  display: inline-block
  // max-width: 100%
  vertical-align: middle

input,
label,
select,
button,
textarea
  margin: 0
  border: 0
  padding: 0
  display: inline-block
  vertical-align: middle
  white-space: normal
  background: none
  font: inherit
  line-height: 1

input:focus,
button:focus,
select:focus
  outline: 0

/* Make webkit render the search input like a normal text field */
input[type=search]
  -webkit-appearance: textfield

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration
  display: none

/* Turn off scroll bars in IE unless needed */
textarea
  overflow: auto
  white-space: pre-line

*,
*::before,
*::after
  box-sizing: border-box

ul,ol
  list-style : none

/* GENERAL
 * -----------------------------------------------*/
html
    height: 100%
body
    height: 100%
    margin: 0

body
  color: $base-font-color
  font-family: $base-font-sans
  font-size: $base-font-size
  font-weight: $base-font-weight
  line-height: $base-line-height
  -webkit-text-size-adjust: none
  -moz-text-size-adjust: none
  -ms-text-size-adjust: none
  background: $color-white




/* HELPERS
 * -----------------------------------------------*/

a
  color: $base-font-color
  text-decoration: none
  transition: opacity 0.6s
  &:hover
    @include sm
     opacity: 0.8
  &:link
    color: $base-font-color
  &:visited
    color: $base-font-color
  &:active
    color: $base-font-color
