.l-contents
  // padding: 1em 0
  // background: $color-beige
  // padding-top: $size-2
  padding-bottom: $size-2
  &.is-bbclapp2
    padding: 10px 0 60px 0
  &.is-no-breadcrumb
    padding-top: 10px

  @include md
    background: $color-beige
    // padding-top: $size-1
    padding-bottom: $size-1
  .l-contents-inner
    &>.row
      margin-right: 0
      margin-left: 0
      @include md
        margin-right: $half-gutter-width
        margin-left: $half-gutter-width

      &>.l-col-main
        padding-right: 0
        padding-left: 0
        @include md
          padding-right: $half-gutter-width
          padding-left: $half-gutter-width



.l-col-main
  margin-bottom: $size-4
  overflow: hidden
  @include md
    margin-bottom: 0
  .l-col-main-inner
    // padding: $size-3
    // background-color: $color-white
    @include md
      padding: $size-3
      background-color: $color-white
