=arrow($color: transparent)
  border: 3px solid $color-pink
  border-radius: 2px
  border-right: 0
  border-top: 0
  content: " "
  display: block
  height: 0.625em
  margin-top: -0.4375em
  pointer-events: none
  position: absolute
  top: 50%
  transform: rotate(-45deg)
  transform-origin: center
  width: 0.625em

%arrow
  +arrow


// reset
input[type="radio"],input[type="checkbox"]
  margin: 0
  padding: 0
  width: 0
  background: none
  border: none
  border-radius: 0
  outline: none
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none

input[type=radio],input[type="checkbox"]
  display: none

form input[type=checkbox] + label
  margin: 0
  padding: 0
  background-image: none
form input[type=radio] + label
  margin: 0
  background-image: none

// セレクトボックス
.select
  display: inline-block
  max-width: 100%
  position: relative
  vertical-align: top
  margin-bottom: $size-4
  padding-right: 2em
  &:not(.is-multiple)
    height: 4em
  &:not(.is-multiple):not(.is-loading):not(.is-small)
    &::after
      @extend %arrow
      right: 1.125em
      top: 1.2em
      z-index: 4
  &.is-rounded
    select
      border-radius: $radius-rounded
      padding-left: 1em
  &.is-small
    height: 2em
    margin-bottom: 0
    padding-right: 0
    &::after
      @extend %arrow
      right: 0.75em
      top: 1em
      z-index: 4
    select
      padding: 0 1.5em 0 calc(.75em - 1px)!important
      height: 2em
  &.is-short
    height: 2em

  select
    -webkit-appearance: none
    align-items: center
    border: 2px solid $color-palepink
    border-radius: 4px
    box-shadow: none
    // height: 4em
    justify-content: flex-start
    line-height: 1.5
    padding-bottom: calc(.5em - 1px)
    padding-left: calc(.75em - 1px)
    padding-right: calc(.75em - 1px)
    padding-top: calc(.5em - 1px)
    position: relative
    vertical-align: top
    cursor: pointer
    display: block
    max-width: 100%
    outline: none
    background-color: $color-white
    border-radius: $radius-small
    color: $base-font-color
  &.is-fullwidth
    width: 100%
    padding-right: 0
    select
      width: 100%

  &.is-news
    font-weight: bold
    font-size: $size-3
    height: auto
    margin-bottom: 0
    select
      padding-right: calc(2.5em - 1px)
      padding-bottom: calc(0.75em - 1px)
      padding-left: calc(0.75em - 1px)
      padding-top: calc(0.75em - 1px)


// テキストフィールド,テキストエリア
.input
  // display: inline-block
  max-width: 100%
  position: relative
  vertical-align: top
  margin-bottom: $size-4
  &.is-rounded
    input,textarea
      border-radius: $radius-rounded
      padding-left: 1em
  input,textarea
    -webkit-appearance: none
    align-items: center
    border: 2px solid $color-palegray
    border-radius: 4px
    box-shadow: none
    // height: 4em
    justify-content: flex-start
    line-height: 1.5
    padding: 5px
    position: relative
    vertical-align: top
    cursor: pointer
    display: block
    max-width: 100%
    outline: none
    background-color: $color-white
    border-radius: $radius-small
    color: $base-font-color
    &::placeholder
      color: $color-gray
    &:focus
      border-color: $color-pink
      transition: border 0.3s
  &.is-fullwidth
    width: 100%
    input,textarea
      width: 100%
  &.is-search
    input
      padding-left: 40px
    &:before
      position: absolute
      display: inline-block
      font-family: "BabySymbols", sans-serif
      font-size: 120%
      font-style: normal
      font-weight: normal
      line-height: 1
      -webkit-font-smoothing: antialiased
      -moz-osx-font-smoothing: grayscale
      content: "\e627"
      z-index: 10
      top: 12px
      left: 12px
      color: $color-gray
  &.is-danger
    input,textarea
      border: 2px solid $color-red

// ラジオボタン
.radio
  display: inline-flex
  margin-right: 10px
  &:last-child
    margin-right: 0
  label
    display: block
    height: 2.8em
    justify-content: center
    line-height: 2.5
    background: $color-palegray
    padding: 0 10px
    border-radius: 6px
    font-weight: bold
    border: 2px solid $color-white
  input:checked + label
    background: $color-pink
    font-weight: bold
    color: $color-white
    transition: background .2s ease

  &.disabled
   color: $color-gray
   text-decoration: line-through



// チェックボックス
.checkbox
  display: inline-flex
  padding-right: 10px
  label
    display: flex
    position: relative
    padding: 1px 0
    align-items: center
    cursor: pointer
    &:before
      display: inline-block
      content: ""
      width: 24px
      height: 24px
      background-color: #cccccc
      border-radius: 50%
      margin-right: 5px
      position: relative
      // top: -2px
      flex: none
      top: 50%
      transform: translateY(-50%)
    &:after
      display: inline-block
      font-family: "BabySymbols"
      content: "\e642"
      position: absolute
      left: 4px
      top: 50%
      transform: translateY(-50%)
      color: $color-white
      font-weight: bold
    p
      line-height: 1.4
  input:checked + label:before
    background: $color-pink
    transition: background .2s ease
  &.disabled
    background: #cccccc
    text-decoration: line-through
