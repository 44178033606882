#clipDetail
  width: 40px
  height: 40px
  position: fixed
  right: -50px
  bottom: 100px
  z-index: 100
  transition: right 450ms
  &::after
    opacity: 0
    transition: opacity 450ms 
  &.is-in
    right: 20px
    transition: right 450ms
  &.is-on
    &::after
      opacity: 1
      position: absolute
      color: $color-pink
      font-family: "BabySymbols", sans-serif
      content: "\e642"
      top: -10px
      right: 0
      animation: show 0.3s linear 0s

  span
    position: relative
    width: 40px
    height: 40px
    border-radius: 6px
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center
    background: rgba($color-white,80%)
    backdrop-filter: blur(3px)
    box-shadow: 0px 2px 4px rgb(171 164 164 / 40%)
    &:before
      color: $base-font-color
      font-family: "BabySymbols", sans-serif
      content: "\e610"
