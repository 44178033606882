@charset "utf-8"


.lp-header

  padding: $gutter-width
  margin-bottom: $size-1
  background: #ffe9de
  background: url("#{$url_images}/ss/lp/lp_bgp.png"),-webkit-linear-gradient(to bottom,#ffedf1, #ffe9de, #ffffff)
  background: url("#{$url_images}/ss/lp/lp_bgp.png"),linear-gradient(to bottom,#ffedf1, #ffe9de, #ffffff)
  background-size: 400px 373px,100% 100%

  h1
    margin: 0 auto
    max-width: 480px
    text-align: center
    font-size: 2.5rem

    img
      width: 100%
      max-width: 240px
      display: block
      margin: 0 auto
      margin-bottom: 10px

  .lp-header-img-sp-wrap
    padding-top: 10px
    min-height: 200px
    max-height: 35vh
    overflow: hidden
    @include sm
      max-height: 380px
    .lp-header-img-sp
      width: 100%
      max-width: 300px
      margin: 0 auto
      display: block

.lp-ff-sm
  font-family: 'Shuei MaruGo B',sans-serif

.lp-section
  background-image: url("#{$url_images}/ss/lp/lp_bg.png")
  background-size: 30px

  // セクション：登録ボタン
  &.is-btn
    max-width: 450px
    margin: 0 auto

  // セクション：登録方法
  &.is-howto-regist
    background-color: #FFF9E8
    border-top: #F5ECD1 solid 2px
    border-bottom: #F5ECD1 solid 2px


    // ページネーション
    .lp-swiper-pagination_wrap
      .swiper-pagination
        position: relative
        .swiper-pagination-bullet
          font-family: 'Shuei MaruGo B',sans-serif
          width: 34px
          height: 34px
          text-align: center
          line-height: 32px
          font-size: 12px
          color: #D1BA75
          border: 1px solid #D1BA75
          background: $color-white
          opacity: 1
          position: relative
          margin: 0 8px
          transition: background 0.3s
          &:before
            content: "0"
          &:after
            content: ""
            width: 60%
            position: absolute
            height: 1px
            background: #D1BA75
            top: 50%
            right: 0
            transform: translate(100%,-50%)
          &:last-child
            &::after
              content: none

        .swiper-pagination-bullet-active
          color: $color-white
          background: #D1BA75


    // swiper
    .swiper-container
      position: relative
      padding: $gutter-width 0

      .swiper-wrapper
        // スライド
        .swiper-slide
          position: relative
          background: $color-white
          border: 1px solid #999999
          img
            width: 100%
          &:before
            position: absolute
            content: ""
            left: 0
            top: 0
            width: 0
            height: 0
            border-style: solid
            border-width: 50px 50px 0 0
            border-color: #D1BA75 transparent transparent transparent
          &:after
            position: absolute
            transform: rotate(315deg)
            display: block
            font-size: 14px
            white-space: pre
            color: #fff
            top: 7px
            left: 7px
            text-align: center
            z-index: 2
            line-height: 1.2
          &.is-slide-1
            &:after
              content: "01"
          &.is-slide-2
            &:after
              content: "02"
          &.is-slide-3
            &:after
              content: "03"
          &.is-slide-4
            &:after
              content: "04"
          &.is-slide-5
            &:after
              content: "05"

        .lp-slide-text
          padding: $gutter-width




  // セクション：アプリ○ユーザー×
  &.is-app-y
    background-color: #FFF5F7
    .lp-appy-q
      background: #EFB1B1
      padding: $gutter-width
      .box-border
        padding: $gutter-width
    .is-lp-y,.is-lp-n
      text-align: center
      font-family: 'Shuei MaruGo B',sans-serif

  // セクション：アプリ×ユーザー×
  &.is-app-n
    background: #96D6CD
    padding: $gutter-width
    .box-border
      padding: $gutter-width

// lp用ボタン
.button-lp
  &.is-lp-app
    text-align: center
    display: block
    padding: $gutter-width 0
    padding-bottom: 24px
    background: none
    border: none
    position: relative
    margin: 0 4px
    margin-bottom: 15px
    // font-weight: bold
    cursor: pointer
    &:after, &:before
      border-radius: 6px
      content: ''
      position: absolute
      top: 0
      left: 0
      bottom: 0
      right: 0
      border: 2px solid $color-pink
      transition: transform .2s
    &:after
      transform: translate(3px, 3px)
      background: $color-pink
    &:before
      transform: translate(-3px, -3px)
      background: $color-white
      z-index: 50
    &:hover
      @include sm
        &:after, &:before
            transform: translate(0)
    .inner
      line-height: 1.4
      padding: 0 35px 0 55px
      position: relative
      z-index: 100
      .c-pink
        font-size: 2rem
        font-family: 'Shuei MaruGo B', sans-serif
        font-weight: bold
        @include md
          font-size: 3rem

      &:after, &:before
        content: ""
        display: inline-block
        top: 50%
        transform: translateY(-50%)
        position: absolute
        z-index: 100
        background-size: contain
        background-repeat: no-repeat
      &:before
        width: 45px
        height: 45px
        left: 7px
        background-image: url("#{$url_images}/ss/lp/lp_app.png")
        @include md
          width: 55px
          height: 55px
      &:after
        width: 20px
        height: 20px
        right: 15px
        background-image: url("#{$url_images}/ss/lp/lp_icon_arrow.png")

  &.is-waku
    position: relative
    padding-top: 50px
    padding-bottom: 10px
    border: 2px solid #EFB1B1
    border-radius: $radius-large
    text-align: center
    img
      width: 120px
      top: -40px
      right: 50%
      transform: translateX(50%)
      position: absolute
      text-align: center
      margin: 0 auto
      padding: 4px
      background: $color-white

  &.is-round
    border: 2px solid #EFB1B1
    border-radius: $radius-rounded
    display: block
    text-align: center
    padding: 8px 0


  &.is-lp-y,&.is-lp-n
    padding: $half-gutter-width
    padding-left: 20px
    display: block
    position: relative
    line-height: 1.4
    border: 1px solid #e0e0e0
    border-radius: $radius-small
    &:before
      content: ""
      display: inline-block
      width: 20px
      height: 20px
      top: -5px
      left: -6px
      position: absolute
      z-index: 100
      background-size: contain
      background-repeat: no-repeat
  &.is-lp-y
    background: #FFF5F7
    &:before
      background-image: url("#{$url_images}/ss/lp/lp_icon_y.png")
  &.is-lp-n
    background: #F4FCFB
    &:before
      background-image: url("#{$url_images}/ss/lp/lp_icon_n.png")




// タイトル
.title


  // スライド
  &.is-lp-slide
    font-family: 'Shuei MaruGo B', sans-serif
    color: $color-navy


  &.is-lp-appy-q
    font-family: 'Shuei MaruGo B', sans-serif
    color: $color-white
    text-shadow: 0 1px 2px rgba(160,120,110,0.6)
    &:before
      content: ""
      display: inline-block
      z-index: 100
      background-size: contain
      background-repeat: no-repeat
      width: 45px
      height: 45px
      margin-right: 10px

  &.is-lp-appy-q
    &:before
      background-image: url("#{$url_images}/ss/lp/lp_titleicon_y.png")

  &.is-lp-1clm
    font-family: 'Shuei MaruGo B', sans-serif
    padding-top: 35px
    padding-bottom: 20px
    position: relative
    text-align: center
    font-size: 2rem
    &:before,&:after
      content: ""
      display: inline-block
      position: absolute
      z-index: 100
      background-size: contain
      background-repeat: no-repeat
      left: 50%
      transform: translateX(-50%)
    &:before
      width: 30px
      height: 30px
      top: 0
    &:after
      width: 36px
      height: 15px
      bottom: 0


.is-howto-regist
  .title
    &.is-lp-1clm
      &:before
        background-image: url("#{$url_images}/ss/lp/lp_icon_user.png")
      &:after
        background-image:   url("#{$url_images}/ss/lp/lp_deco_border-y.png")

.is-app-y
  .title
    &.is-lp-1clm.is-y
      &:before
        width: 47px
        height: 37px
        background-image: url("#{$url_images}/ss/lp/lp_icon_appy-usery.png")
      &:after
        background-image:   url("#{$url_images}/ss/lp/lp_deco_border-p.png")
    &.is-lp-1clm.is-n
      &:before
        width: 47px
        height: 37px
        background-image: url("#{$url_images}/ss/lp/lp_icon_appy-usern.png")
      &:after
        background-image:   url("#{$url_images}/ss/lp/lp_deco_border-g.png")
