
.l-footer
  padding-top: 20px
  border-top: 1px solid $color-palegray
  font-size: $size-5


  .l-footer-links
    // font-weight: bold

  .group-link-menu
    span
      margin-right: 5px
      font-size: $size-4
      color: $color-pink
      letter-spacing: -0.02em
    &>li
      &>a
        display: block
        padding: 5px 0
        @include md
          padding: 3px 0
    .group-link-menu-inner
      margin-left: 6px
      &>li
        border-left: 1px dotted $color-pink
        &>a
          display: block
          padding: 5px 0
          margin-left: 20px


  .l-footer-links-second
    padding: 8px 0
    border-top: 2px dotted $color-beige
    ul
      li
        padding: 4px

  .l-footer-about
    margin-top: 20px
    @include md
      margin-top: 0
    .l-footer-logo
      display: block
      margin: 10px 0

  .l-footer-sns
    margin-top: 20px
    a
      padding: 6px
      span
        font-size: $size-2
        // color: $color-pink

  .l-footer-org
    margin: 10px 0
    p
      margin-bottom: 5px
      background: $color-beige
      padding: 4px 0
      border-radius: $radius-rounded
      display: inline-block
      width: 200px


  .l-footer-copyright

    background: $color-beige
    padding: 10px 0
