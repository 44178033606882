@charset "utf-8"



#pregnancy-food, #fertility-food
  overflow-x: hidden
  img
    width: 100%
    height: auto
    & + p
      margin-top: 1.6rem
      @include md
        margin-top: 1.6rem
  h1
    & + *:not(h2)
      margin-top: 1.6rem
    & + h2
      margin-top: 0
  h2
    & + p
      margin-top: 1.6rem
  ul:not([class])
    margin: 1rem 0
    @include sm
      margin: 1.6rem 0
  p
    & + p
      margin-top: 1.6rem
    & + .recipe-headline-h2
      margin-top: 1.6rem
  hr
    border-top: none
    border-bottom-style: dashed
    border-width: 2px
    border-color: $color-pink
  .gutter-around
    & + .col-xs
      > *:first-child
        margin-top: 0
        @include sm
          margin-top: 1.6rem
  .bg
    color: $color-white
    background-color: $color-pink
    &.default
      color: $color-white !important
      background-color: $color-pink !important
  .bg-op
    color: $base-font-color
    background-color: $color-recipe-bg-gray
    border-color: $color-recipe-border-gray
    > span
      color: $color-pink
    &.default
      color: $base-font-color !important
      background-color: $color-recipe-bg-gray !important
      border-color: $color-recipe-bg-gray !important
      > span
        color: $color-pink !important
  .b-color
    border-color: $color-pink
  .color,.fc
    color: $color-pink
    &.default
      color: $color-pink !important
  .marker
    background-image: linear-gradient(rgba(255,255,255,0) 30%, rgba(255,116,141,.25) 70%)
  .icon, .icon-circle
    display: flex
    align-items: center
    &::before
      content: ""
      display: inline-block
      width: 32px
      height: 32px
      border-radius: 32px
      line-height: 32px
      margin-right: .5rem
      position: relative
      font-family: "BabySymbols"
      text-align: center
      padding-left: 0 !important
      background-repeat: no-repeat
      background-size: contain
      background-position: left center
    &.recipe
      &::before
        content: "\e90c"
    &.pregnancy
      &::before
        content: "\e607" !important
    &.fertility
      &::before
        content: "\e606" !important
  .icon
    &::before
      color: $color-pink
    &.guide
      &::before
        content: ""
        background-image: url("#{$url_images}/recipe/icon-pf-guide.svg")
    &.period
      &::before
        content: ""
        background-image: url("#{$url_images}/recipe/icon-pf-period.svg")
    &.symptom
      &::before
        content: ""
        background-image: url("#{$url_images}/recipe/icon-pf-symptom.svg")
    &.calorie
      &::before
        content: ""
        background-image: url("#{$url_images}/recipe/icon-pf-calorie.svg")
    &.salt
      &::before
        content: ""
        background-image: url("#{$url_images}/recipe/icon-pf-salt.svg")
  .icon-circle
    &::before
      background-color: $color-deeppink
      color: $color-white
  .button
    &.is-framebtn
      border: 2px solid $color-pink
      min-height: 37px
      height: auto
      border-radius: $radius-rounded
      padding: .25rem 5rem .25rem .5rem
      color: $color-gray
      font-weight: normal
      overflow: hidden
      white-space: normal
      line-height: 1.2
      font-size: 1.2rem
      position: relative
      background-color: $color-white
      // box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.35)
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      @include md
        line-height: 1.3
        font-size: 1.4rem
      &:hover
        opacity: 1
      &::before
        content: "\e627"
        font-family: "BabySymbols"
        font-size: 130%
        color: $color-white
        display: inline-block
        height: 100%
        width: 3.5rem
        background: $color-pink
        text-align: center
        position: absolute
        right: 0
        top: 0
        line-height: 35px
        @include md
          width: 5rem
      & + .is-framebtn
        margin-top: .5rem
      &.after-search
        color: $base-font-color
        font-weight: 600
  .recipe-fixed-carousel, #recipe-detail-top, .recipe-detail-top
    .icon-circle
      &::before
        width: 24px
        min-width: 24px
        height: 24px
        padding-left: 0
        line-height: 24px


// 妊娠食・妊活食 デザインパーツ テーブル

.recipe-table-separate,.recipe-table-border,.recipe-table-recipe,.recipe-guide-table
  width: 100%
  font-size: 95%
  margin: 1rem 0
  @include sm
    margin: 1.6rem 0
  tr
    th,td
      vertical-align: middle
      word-break: break-all
      > p
        margin: 0 !important
        & + p
          margin-top: .75rem !important
    th
      background: $color-recipe-bg-gray
      text-align: center
  &.center
    tr
      th,td
        text-align: center

.recipe-table-recipe
  table-layout: auto
  tr
    border-bottom: 2px dashed $color-recipe-border-gray
    th,td
      vertical-align: top
  td
    &:first-child
      padding: .75rem .75rem .75rem 0
      font-size: 100%
      font-weight: 700
      min-width: 50%
    &:last-child
      padding: .75rem 0 .75rem .75rem
      border-left: 1px solid $color-recipe-border-gray
      font-size: 98%
      text-align: right
      min-width: 30%

.recipe-table-separate
  border-collapse: separate
  border-spacing: 1rem
  margin-left: -1rem
  margin-right: -1rem
  th
    border: 1px solid $color-recipe-border-gray
    padding: 1rem
    font-weight: 700
    width: 30%
  td
    padding: 1rem 0

.recipe-table-border
  table-layout: fixed
  border-left: 1px solid $color-recipe-border-gray
  border-top: 1px solid $color-recipe-border-gray
  th,td
    border-right: 1px solid $color-recipe-border-gray
    border-bottom: 1px solid $color-recipe-border-gray
  th
    padding: .25rem 1rem
  td
    padding: 1rem
  &.layout-auto
    table-layout: auto
    tr
      th
        width: 25%
      td
        &.nowrap
          white-space: nowrap
          text-align: center
.recipe-search-field
  .recipe-table-separate 
    background: $color-white
    border: 1px solid $color-recipe-border-gray
    margin: 0 0 1rem
    border-collapse: separate
    border-spacing: .5rem
    tr
      th
        text-align: left
        padding: .25rem .5rem
      td
        border: none
        background: $color-white
        padding: 1rem 2rem
    .checkbox
      padding: 0
      > li
        padding: .25rem 1rem
  .search-detail-container
    background: transparent
    padding: 0
    border: none

#pregnancy-food
  .icon, .icon-circle
    &::before
      content: "\e607"
#fertility-food
  .icon, .icon-circle
    &::before
      content: "\e606"

// レシピ検索

#modal-recipe-search-btn,
#modal-recipe-search-framebtn,
#modal-recipe-search-floatingbtn
  .modal-content
    background: $color-recipe-bg-gray
    position: relative
    padding: 15px 10px 0
    overflow-y: auto
  .modal-close
    position: absolute
    right: 5px
  .search-btn
    position: sticky
    bottom: 0
    z-index: 9999
    background: $color-recipe-bg-gray
    width: 100%
    padding: 0 1rem 1.6rem
    margin-top: 1.6rem
    > .recipe-btn
      cursor: pointer
      width: 100%
      margin: auto
      @include sm
        margin-top: 1.6rem

#accordion-recipe-search
  > .accordion-item
    cursor: auto
    > .accordion-title
      display: flex
      align-items: center
      justify-content: center
      padding: 0
      background: transparent
      &::before,&::after
        display: none
      .recipe-headline-search
        display: flex
        align-items: center
        &::after
          content: "\e626"
          font-family: "BabySymbols"
          font-size: 1.6rem
          display: inline-block
          margin-left: .5rem
          color: $color-pink
          transition: all .3s
        .open-text
          display: none
        .close-text
          display: block
    > .accordion-content
      margin: 0
      padding: 0
    &.is-active
      position: relative
      padding-bottom: 2.5rem
      > .accordion-title
        width: 100%
        position: absolute
        bottom: 0
        left: 50%
        transform: translateX(-50%)
        .recipe-headline-search
          .open-text
            display: block
          .close-text
            display: none
          &::after
            content: "\e603"
            position: relative
.recipe-search-area
  .recipe-headline-center
    padding: 0
    margin: 0 0 1rem
    &::after
      display: none
  &.lookup
    .recipe-headline-center
      color: $color-navy

.recipe-headline-h1,.recipe-headline-h2,.recipe-headline-h3,.recipe-headline-recipe,.recipe-headline-center,.recipe-2col-headline
  font-weight: 700
.recipe-headline-h1,.recipe-headline-h2
  & + *:not(.recipe-headline-h2,.gutter-mtb,.gutter-around,.recipe-list,.recipe-list &*,.recipe-movie-list,.recipe-movie-list-static)
    margin-top: 1.6rem
.recipe-headline-h2,.recipe-headline-h3,.recipe-headline-recipe,.recipe-headline-center,.recipe-2col-headline
  margin: 1.6rem 0
.recipe-headline-h2
  font-size: 120%
.recipe-headline-h3,.recipe-headline,.recipe-headline-recipe,.recipe-headline-center,.recipe-2col-headline
  font-size: 115%
.recipe-headline-h1
  position: relative
  font-size: 1.75rem !important
  line-height: 1.5
.recipe-headline-h2
  padding: 8px 15px 7px
  background: $color-recipe-bg-gray
  border-top: 1px solid $color-recipe-border-gray
  border-bottom: 1px solid $color-recipe-border-gray
  span,.color
    color: $color-pink
  &.icon
    padding-left: .5rem
    &::before
      width: 30px !important
      height: 24px !important
      background-position: top right !important
      border-radius: 0 !important
    &.guide, &.symptom // 確認用
      &::before
        width: 54px !important
.recipe-headline-h1
  color: $color-white
  background-color: $color-pink
  padding: 5px 0
  .headline-inner
    text-shadow: 0 -1px 1px rgba(0,0,0,0.25)
    width: 100%
    padding: 7px 15px
    border-top: 2px dashed $color-recipe-border-gray
    border-bottom: 2px dashed $color-recipe-border-gray
    display: flex
    align-items: center
    p
      display: inline-block
    a
      display: block
      font-size: 1.2rem
      margin-top: 1.7rem
      &::after
        position: absolute
        bottom: 0
        right: 0
        content: "一覧を見る >"
        display: inline-block
        padding: 4px 8px
        border-radius: 8px 0 0 0
        color: #ffffff
        background: $color-deeppink
  & + .recipe-headline-h2
    margin-top: 0
.recipe-headline-h3
  position: relative
  padding: 0.5rem 0 0.5rem 2rem
  &::before
    content: ""
    width: 5px
    height: -webkit-calc(100% - 0.5rem)
    height: calc(100% - 1rem)
    background-color: $color-pink
    border-radius: 4px
    position: absolute !important
    top: 0.5rem
    left: 0.5rem
.recipe-headline-recipe
  span
    margin-top: -1px
  &.icon
    &::before
      width: 30px !important
      height: 24px !important
      background-position: center !important
      border-radius: 0 !important
  &.guide, &.symptom // 確認用
    &::before
      width: 54px !important
.recipe-headline-center
  display: block
  text-align: center
  position: relative
  color: $color-pink
  padding-bottom: 1.5rem
  color: $base-font-color
  &::after
    content: ""
    display: block
    width: 20%
    height: 8px
    background-image: url("#{$url_images}/recipe/heading-message-area-dotted.png")
    background-repeat: no-repeat
    background-size: contain
    background-position: center
    background-color: transparent
    position: absolute
    bottom: 0
    left: 50%
    transform: translateX(-50%)
  &.border
    padding-bottom: 1rem
    &::after
      background-image: none
      content: ""
      display: block
      width: 20%
      height: 4px
      border-radius: 4px
      background-color: $color-pink
      position: absolute
      bottom: 0
      left: 50%
      transform: translateX(-50%)
      @include md
        width: 10%
  &.icon,&.icon-circle
    display: block !important
    &::before
      display: inline-block
      top: 7px
.recipe-2col-headline
  display: flex
  align-items: center
  flex-wrap: nowrap
  line-height: 1.25
  margin: 1.6rem 0
  span
    white-space: nowrap
    display: inline-block
    padding: 1rem
    margin-right: 1rem
    background-color: $color-white
    color: $color-pink !important
    font-size: 80%
    border: 2px solid $color-pink
    border-radius: 6px
    height: fit-content
    line-height: 1.5
    &.is-circle
      text-align: center
      width: 34px
      height: 34px
      border-radius: 200px
      padding: 0
      line-height: 32px

// 妊娠食・妊活食 デザインパーツ ボタン

.recipe-btn
  display: block
  margin: 1.6rem auto
  padding: 10px 30px 10px 10px
  border-radius: 3px
  font-weight: 700
  font-size: 1.6rem
  text-align: center
  position: relative
  color: $color-white !important
  background-color: $color-pink
  text-shadow: 0 -1px 1px rgba(0,0,0,0.25)
  @include md
    padding: 15px 30px 15px 15px
    margin: 10px auto
    max-width: 460px
    min-width: 240px
  &::after
    position: absolute
    display: inline-block
    width: 15px
    height: 15px
    content: ''
    background-repeat: no-repeat
    background-size: contain
    background-image: url("#{$url_images}/recipe/arrow_w.svg")
    right: 10px
    bottom: 50%
    transform: translateY(50%)
    @include md
      right: 20px
  &.amazon
    background: #ff9902 !important
    color: #121921!important
  &.rakuten
    background: #bf0000 !important
    color: $color-white!important
  &.yahoo
    background: #4070ff !important
    color: $color-white!important
.recipe-btn-outline
  display: block
  margin: 1.6rem auto
  height: auto
  padding: 8px 10px
  border-radius: 3px
  font-weight: 700
  font-size: 1.6rem
  letter-spacing: 0.1em
  white-space: normal
  position: relative
  border: 2px solid $color-pink
  text-align: center
  @include md
    padding: 10px
    max-width: 460px
    min-width: 240px
  &.default
    color: $color-pink !important
    border-color: $color-pink !important
    > i.babysymbol::before
      color: $color-pink !important
    &:link,&:visited
      color: $color-pink
  &:link,&:visited
    color: $color-pink
  i.babysymbol::before
    position: relative
    top: 1px

// 妊娠食・妊活食 デザインパーツ リスト

.recipe-list-circle,.recipe-list-check,.recipe-list-number,.recipe-list-timeline
  display: block
  max-width: 100%
  min-width: 50%
  margin: 1.4rem 0
  @include sm
    margin: 1.6rem 0
  > li
    display: block
    padding: .25rem 0 .25rem 3rem
    position: relative
    &::before
      width: 14px
      height: 14px
      font-family: "BabySymbols"
      font-size: 1.4rem
      transform: translateY(-50%)
      color: $color-pink
      position: absolute
      top: 1rem
      left: .75rem
      @include md
        right: 20px
    &:last-child
      padding-bottom: 0
.recipe-list-circle
  > li
    &::before
      content: "\e60f"
.recipe-list-check
  > li
    &::before
      content: "\e642"
.recipe-list-number
  margin: 1.6rem 0
  counter-reset: number
  > li
    position: relative
    padding-left: 3rem
    &::before
      counter-increment: number
      content: counter(number)'．'
      position: absolute
      left: .75rem
      top: .9rem
.recipe-list-timeline
  > li
    margin-left: .5rem
    padding: 0 0 1.5rem 3rem
    position: relative
    &:last-child
      &::before
        display: none
    &::before,&::after
      content: ""
      display: block
      position: absolute
      background-color: $color-pink
    &::before
      top: 3.5rem
      left: .6rem
      width: 2px
      height: calc(100% - 2.5rem)
    &::after
      top: .6rem
      border-radius: 100%
      left: 0px
      width: 15px
      height: 15px
      background-color: $color-pink
    span
      display: inline-block
      vertical-align: middle
      font-weight: bold
      &.time
        font-size: 1.75rem
        width: 55px
        text-align: right
        margin-right: 1rem


.nutritional-values, .recipe-anchor
  width: 100%
  display: flex
  flex-wrap: wrap
  align-items: stretch
  font-size: 90%
  margin: 1rem 0
  border-top: 1px solid $color-recipe-border-gray
  border-left: 1px solid $color-recipe-border-gray
  @include sm
    margin: 1.6rem 0
  > li
    flex: 1 1 50%
    border-bottom: 1px solid $color-recipe-border-gray
    border-right: 1px solid $color-recipe-border-gray
    background: $color-white
    padding: .5rem 1rem
    &:last-child
      max-width: 50%
    &:first-child
      background: $color-white
    &:nth-child(4n+2),&:nth-child(4n+3)
      background: $color-recipe-bg-gray
    @include md
      flex: 1 1 33.33%
      &:last-child, &:nth-last-child(2)
        max-width: 33.33%
      &:nth-child(4n+2),&:nth-child(4n+3)
        background: $color-white
      &:nth-child(2n)
        background: $color-recipe-bg-gray
    > dl
      width: 100%
      display: flex
      flex-wrap: nowrap
      justify-content: space-between
      > dt
        text-align: left
        margin-right: .5rem
        //font-weight: 700
        &.icon
          &::before
            width: 14px !important
            height: 14px !important
            background-position: center !important
            border-radius: 0 !important
            @include md
              width: 20px !important
              height: 20px !important
      > dd
        text-align: right

.recipe-anchor
  > li
    text-align: center
    padding: 1rem

.recipe-guide-table
  table-layout: fixed
  border-collapse: separate
  border-spacing: .2rem
  border: 1px solid $color-recipe-border-gray
  tr
    th,td
      border: none
      line-height: 1.25
      border-right: 1px solid $color-recipe-border-gray
      &:last-child
        border-right: none
    th
      padding: .25rem .25rem .5rem
      width: 33%
      &:last-child
        letter-spacing: -.05rem
      &.c-gray
        background-color: $color-gray !important
        color: $color-white !important
    td
      padding: .5rem .25rem
      text-align: center
  &.default
    border-color: $color-recipe-border-gray !important
    tr
      th,td
        background-color: $color-recipe-bg-gray !important
      &:nth-child(2n)
        th,td
          background-color: $color-white !important
  &.col-3
    tr
      th,td
        padding: .5rem 1rem
        height: 44px
        &:first-child
          width: 25%
          border: none
        &:nth-child(2)
          width: 55px
      td
        &:last-child
          text-align: left

.recipe-table-border, .recipe-guide-table
  tr
    th, td
      a
        color: $color-blue
        &.recipe, &.ingredients
          &::after
            font-size: 1rem
            display: block
            text-decoration: underline
        &.recipe
          &::after
            content: "レシピ"
        &.ingredients
          &::after
            content: "食材"
  .babysymbol
    font-size: 2rem
    &.babysymbol-circle::before
      color: $color-pink !important
    &.babysymbol-triangle::before
      color: $color-green !important
    &.babysymbol-close::before
      color: $color-gray !important

// 妊娠食・妊活食 デザインパーツ 目次・関連リンク・テキストエリア・アンカーリンク

.recipe-contents-list,.recipe-related-list,.recipe-textarea
  display: block
  border: 1px solid $color-recipe-border-gray
  border-radius: 3px
  overflow: hidden
  margin: 1rem 0
  @include sm
    margin: 1.6rem 0
  .area-headline
    margin: -1rem -1rem .75rem
    text-align: center
    border-top: none !important
    @include sm
      margin: -1.6rem -1.6rem 1.6rem
  > .recipe-2col-headline
    margin-top: 0
  .row
    margin-bottom: 0
    div:not(:first-child)
      margin-top: 1.6rem
      @include md
        margin-top: inherit
  & *:first-child:not(.area-headline)
    margin-top: 0
.recipe-textarea
  padding: 1rem
  @include md
    padding: 1.6rem
  > .recipe-headline-h2
    margin-top: -1.6rem !important
  p:last-child
    margin-bottom: 0 !important
.recipe-contents-list
  &::before
    content: "目次"
    display: block
    color: $base-font-color
    font-weight: 700
    background: $color-recipe-bg-gray
    border-bottom: 1px solid $color-recipe-border-gray
    padding: 3px 12px
  > ul
    padding: 6px 12px 6px 0
    display: flex
    flex-flow: wrap
    @include md
      flex-flow: column
    > li
      padding: 3px 0 0 12px
      &::before
        content: "\e600"
        display: inline-block
        font-family: "BabySymbols"
        margin-right: 7px
        color: $color-pink

.recipe-related-list
  &::before
    content: "関連リンク"
    display: block
    color: $base-font-color
    font-weight: 700
    background: $color-recipe-bg-gray
    border-bottom: 1px solid $color-recipe-border-gray
    padding: 3px 12px
  > ul
    padding: 6px 12px 6px 0
    > li
      padding: 3px 0 0 12px
      &::after
        content: "\e602"
        display: inline-block
        font-family: "BabySymbols"
        margin-left: 7px
        color: $color-pink

// Ｑ＆Ａ、口コミ

.recipe-kuchikomi,.recipe-qa
  margin: 1.6rem 0
  .qa-wrap, .kuchikomi-wrap
    border: 2px solid $color-palepink
    border-radius: 8px
    .qa-a, .qa-q, .kuchikomi
      padding: 1.6rem
      .headline
        display: flex
        align-items: center
        font-weight: 700
        padding-right: 80px
        &::before
          align-self: start
          font-family: "BabySymbols"
          font-size: 175%
          font-weight: 400
          color: $color-pink
          margin-right: 1rem
          margin-top: -.15rem
        & + p
          margin-top: .5rem !important
      p + p
          margin-top: .5rem !important
    & + .qa-wrap, & + .kuchikomi-wrap
      margin-top: 1.6rem
  .qa-wrap
    overflow: hidden
    .qa-a
      background: #fff7f7
      position: relative
      .headline
        &::before
          content: "\e605"
      &::after
        content: ""
        display: block
        width: 80px
        height: 80px
        border-radius: 80px
        background: top 5px center / 100% url("https://static.baby-calendar.jp/upload/news/files/kansyuu/kanrieiyou.png")no-repeat
        position: absolute
        right: .5rem
        top: -30px
        border: 2px solid $color-palepink
        @include md
          right: 1rem
    .qa-q
      background: $color-white
      .headline
        &::before
          content: "\e604"
  .kuchikomi-wrap
    margin-bottom: 1.6rem
    .kuchikomi
      background: #fff7f7
      position: relative
      p, .headline
        font-size: 90%
      .headline
        padding-right: 0
      &::after
        content: ""
        display: block
        width: 30px
        height: 30px
        border-radius: 30px
        position: absolute
        right: -5px
        top: -10px
        background: #facbcb 50% / 55% url("#{$url_images}/news/comment/user.svg") no-repeat
        border: 2px solid $color-palepink

// 妊娠食・妊活食 レシピ詳細

.recipe-related_category
  display: flex
  flex-wrap: wrap
  gap: .5rem
  margin: 1.6rem 0
  > a
    display: inline-block
    line-height: 1
    padding: .5rem .75rem
    font-size: 1.2rem
    color: $color-pink
    white-space: nowrap
    border-radius: 2rem
    background: transparent
    border: 1px solid $color-pink
    @include md

#recipe-detail-top, .recipe-detail-top, #recipe-detail-bottom, .recipe-detail-bottom
  > .recipe-headline-h2
    &:first-child
      margin-top: 0
  .recipe-list-number
    line-height: 1.75
    margin-top: 0
    > li
      padding: .75rem 0 .75rem 3rem
      border-bottom: 2px dashed $color-recipe-border-gray
      &::before
        top: 1.35rem
#recipe-detail-top, .recipe-detail-top
  &.recipe-wrap
    .recipe-headline-recipe
      border-top: 3px solid $color-pink
      margin-bottom: 0
      position: relative
      &::after
        content: ""
        display: block
        width: calc(100% + 1.6rem + 1.6rem)
        height: 3px
        background: $color-pink
        position: absolute
        top: -3px
        left: 50%
        transform: translateX(-50%)
      > .row
        margin-top: -1px
        .icon-circle
          padding-right: 1.5rem
.recipe-list
  .recipe-wrap
    .nutritional-values
      min-width: 50%
      max-width: 100%
      width: auto
      border-top: none
      border-left: none
      margin: .5rem 0 0
      @include md
        margin: 1rem auto 0 0
      > li
        flex: 1 1 100%
        padding: .5rem 1rem
        font-size: 1.1rem
        border: none
        background: $color-recipe-bg-gray
        border-radius: 8px
        @include md
          flex: 1 1 50%
          padding: 1rem
          font-size: 1.3rem
        &:last-child, &:nth-last-child(2)
          max-width: 100%
          @include md
            max-width: 50% 
        &:first-child, &:nth-child(2)
        > dl
          @include md
            justify-content: start
          dt
            &::after
              @include md
                content: "： "
        & + li
          margin-top: -8px
          border-radius: 0 0 8px 8px
          @include md
            margin-top: 0
            margin-left: -8px
            border-radius: 0 8px 8px 0


// 妊娠食 デザインパーツ ナビゲーション

#pregnancy-food-nav
  padding: .5rem 0 .5rem
  background: $color-recipe-bg-gray
  font-family: $base-font-sans
  @include md
    padding: 0
    margin-bottom: 2rem
    border: 1px solid $color-recipe-border-gray
  .pregnancy-food-nav-link
    display: flex
    @include md
      background: $color-white
      height: 55px
    > li
      position: relative
      width: 50%
      cursor: pointer
      text-align: center
      line-height: 1.25
      font-size: 1.2rem
      font-weight: 700
      border-right: 2px dashed $color-recipe-border-gray
      border-bottom: 1px solid $color-recipe-border-gray
      border-top: 1px solid $color-recipe-border-gray
      transition: all .3s
      display: flex
      align-items: center
      justify-content: center
      @include md
        font-size: 1.6rem
        border-bottom: none
        border-top: none
      &:last-child
        border-right: none
      > a
        display: inline-block
        padding: 1.5rem 0
        color: $base-font-color
        //white-space: nowrap
        @include md
          padding: 0
          color: $base-font-color
      &::after,&::before
        content: ""
        display: inline-block
        width: 20px
        height: 20px
        background-size: contain
        background-repeat: no-repeat
        transition: all .3s
        @include md
          width: 24px
          height: 24px
      &::before
        margin-top: 2px
        margin-right: 8px
      &.recipe
        &::before
          background-image: url("#{$url_images}/recipe/icon-pf-recipe.svg")
      &.guide
        &::before
          width: 36px // 確認用
          background-image: url("#{$url_images}/recipe/icon-pf-guide.svg")
          @include md // 確認用
            width: 44px
      &:hover
        // background: $color-white
        // color: $color-pink
        @include md
          color: inherit
        > a
          color: $color-pink
        &::after
          background-position: center right
      &.is-open
        background: $color-white
        > a
          color: $color-pink
          opacity: 1
        &::after
          display: block
          content: ""
          transition: .3s
          position: absolute
          height: 4px
          border-radius: 2px
          background: $color-pink
          left: 30%
          width: 40%
          bottom: 2px
          margin: 0
          @include sm
            left: 40%
            width: 20%
            bottom: 2px
          @include md
            bottom: 6px

// 妊娠食・妊活食 デザインパーツ コンテンツ下層ナビゲーション

#pregnancy-food-recipe-nav, #pregnancy-food-guide-nav, #fertility-food-recipe-nav
  width: 100vw
  box-sizing: border-box
  margin-left: -1.5rem
  margin-right: -1.5rem
  @include sm
    width: 100%
    margin-left: 0
    margin-right: 0
  > .recipe-headline-h3, > .recipe-headline-recipe
    margin: 1.25rem .75rem .75rem
    @include sm
      margin: 1.25rem 0 .5rem
  > .recipe-headline-h2
    margin: 1.25rem 0 .75rem !important
    @include sm
      margin: 1.25rem -1.6rem 1rem !important
  .nav-link-list
    display: flex
    align-items: center
    flex-flow: wrap
    border-top: 1px solid $color-recipe-border-gray
    @include sm
      border-left: 1px solid $color-recipe-border-gray
    > li
      flex: 0 0 50%
      padding: 8px 4px 8px 8px
      border-bottom: 1px solid $color-recipe-border-gray
      overflow: hidden
      @include sm
        border-right: 1px solid $color-recipe-border-gray
        flex: 0 0 33.3333%
      a
        display: flex
        align-items: center
        justify-content: space-between
        line-height: 1.25
        span
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
        &::after
          content: "\e602"
          display: inline-block
          font-family: BabySymbols
          color: $color-pink
          font-size: 80%
          margin-right: 0px
          margin-left: auto
          padding: 0px 0.25rem

// 妊娠食・妊活食 デザインパーツ スライダー内見出し

#recipe-slider-wrap
  .recipe-headline-h2
    margin-top: -1px
    margin-bottom: 0
  .recipe-headline-h3, .recipe-headline-recipe
    margin: 1rem 0 0
  & + .recipe-headline-h2
    margin-top: -1px

// 妊娠食・妊活食 デザインパーツ タブ、タブ内アコーディオン

#pregnancy-food-guide-tab
  > .tab-group
    .tab
      max-width: 50%
      & + .tab::before
        display: none
  > .panel-group > .panel
    overflow: visible

  .accordion
    .accordion-item
      &:first-child
        border-radius: 8px 8px 0 0
      &:last-child
        border-radius: 0 0 8px 8px
      .accordion-title
        background: $color-white
        color: $base-font-color
        padding-right: 5rem 
        > span
          font-size: 80%
        &::before
          display: none
        &::after
          content: "\e602"
          display: inline-block
          font-family: "BabySymbols"
          color: $color-pink
          width: 24px
          height: 24px
          background: transparent
      &.is-active
        .accordion-title
          &::after
            transform: rotate(-90deg)
            opacity: 1

// 妊娠食・妊活食 デザインパーツ コンテンツ内で幅いっぱいにしたいもの

#pregnancy-food, #fertility-food
  .full-width,
  .recipe-headline-h1,
  .recipe-headline-h2,
  .recipe-tab > .tab-group,
  .recipe-swiper
    width: 100vw
    box-sizing: border-box
    margin-left: -1.5rem
    margin-right: -1.5rem
    @include sm
      width: calc( 100% + 3.2rem )
      margin-left: -1.6rem
      margin-right: -1.6rem

/////////////////////////////
//        ページ別
/////////////////////////////
