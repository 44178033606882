@charset "utf-8"


.bgc-knowledge-palepink,.bgc-palepink
  background: rgba($color-knowledge-pink,50%)
.bgc-knowledge-pink
  background-color: $color-knowledge-pink
.bgc-knowledge-orange
  background-color: $color-knowledge-orange
.bgc-knowledge-yellow
  background-color: $color-knowledge-yellow

.c-knowledge-pink
  color: $color-knowledge-pink
.c-knowledge-orange
  color: $color-knowledge-orange
.c-knowledge-yellow
  color: $color-knowledge-yellow



// search

.knowledge-search
  margin-bottom: 0
  position: relative
  &.has-icon:before
    display: inline-block
    font-family: "BabySymbols"
    content: "\e627"
    position: absolute
    z-index: 1
    top: 50%
    transform: translateY(-50%)
    left: 10px
  >input
    padding: $half-gutter-width
    padding-left: 30px !important
    font-weight: bold


// swiper

.knowledge-tab-menu
  padding: $half-gutter-width $half-gutter-width 0 $half-gutter-width !important
  background: $color-palegray
  .swiper-slide
    width: auto
    padding: 8px 0
    text-align: center
    cursor: pointer
    font-weight: bold
    border-bottom: 0px
    font-size: $size-4
    height: auto
    display: flex
    align-items: center
    justify-content: center
    color: $color-gray
    border-width: 1px 1px 0
    border-style: solid
    border-color: transparent

    + .swiper-slide
      &:before
        width: 2px
        height: 50%
        display: inline-block
        content: ""
        background: $color-supergray
        position: absolute
        left: -2px
        z-index: 1
    &.swiper-slide-thumb-active
      color: $color-pink !important
      position: relative
      background: $color-white
      border-radius: $radius-large $radius-large 0 0
      z-index: 2
      border-color: $color-supergray
      &:before
        opacity: 0
      + .swiper-slide
        &:before
          opacity: 0


// リンク

.knowledge-period-group
  .knowledge-period-group-btn-wrap
    display: flex
    flex-direction: column
    position: relative

    .knowledge-period-group-btn
      flex-grow: 1
      border-radius: 9px
      text-align: center
      padding: 0 0 $gutter-width 0
      font-weight: bold
      &:after
        display: inline-block
        position: absolute
        font-family: "BabySymbols"
        content: "\e602"
        bottom: 50%
        right: 10px
        font-size: 1.2em
        transform: translateY(50%)
      &.bgc-knowledge-pink
        &:after
          color: darken($color-knowledge-pink, 10%)
        .knowledge-period-group-img
          background: rgb(255 255 255 / 40%)
      &.bgc-knowledge-orange
        &:after
          color: darken($color-knowledge-orange, 25%)
        .knowledge-period-group-img
          background: rgb(255 255 255 / 40%)
      &.bgc-knowledge-yellow
        &:after
          color: darken($color-knowledge-yellow, 40%)
        .knowledge-period-group-img
          background: rgb(255 255 255 / 40%)

      .knowledge-period-group-img
        // width: 55%
        margin: auto
        margin-bottom: $half-gutter-width
        img
          max-width: 140px
          width: 100%
        // @include sm
        //   padding: 10px


// ベース調整

.accordion
  &.is-period
    .accordion-content
      padding: 0
  .search-buttons
    .button
      margin: 1.5% 1%


// 時期リンク

.knowledge-period-links
  display: flex
  flex-wrap: wrap
  .is-link
    text-align: center
    font-weight: bold
    display: inline-block
    width: calc(94% /3)
    margin: 0 1%
    position: relative
    padding: $half-gutter-width 0
    border-bottom: 1px solid $color-palepink
    @include sm
      width: calc(90% /5)
    .babysymbol
      color: darken($color-knowledge-pink, 10%)
      
    &:not(:nth-child(3n+1))
      &::after
        content: ""
        display: block
        position: absolute
        width: 1px
        left: -5%
        top: 50%
        height: 60%
        transform: translateY(-50%)
        background: $color-palepink
    @include sm
      &:not(:nth-child(3n+1))
        &::after 
          content: none
      &:not(:nth-child(5n+1))
        &::after
          content: ""
          display: block
          position: absolute
          width: 1px
          left: -5%
          top: 50%
          height: 60%
          transform: translateY(-50%)
          background: $color-palepink

    &.is-now
      color: $color-pink
      &:before
        content: ""
        width: 5px
        height: 5px
        background: $color-pink
        border-radius: 50%
        display: inline-block
        right: 4%
        top: $half-gutter-width
        position: absolute


// qa一覧ページ
.qa-links
  .link
    width: 42%
    margin: 0.5% 3%
    @include sm
      width: 30% 
      margin: 0.5% 1%
    


// pregnancyタブ表示調整
  
.tab-panel
  &.knowledge-pregnancy-tab
    .tab-group
      .tab
        display: flex
        justify-content: center
        align-items: center
        padding: $half-gutter-width 0
        font-weight: bold
        border-radius: 9px 9px 0 0 
        margin: 0 calc( $half-gutter-width / 2 )
        font-size: $size-3

      .knowledge-pregnancy-tab-thumb
        width: 50px
        display: none
        vertical-align: middle
        padding: 5px

      .is-active
        flex-grow: 3
        .knowledge-pregnancy-tab-thumb
          display: inline-block
          vertical-align: middle
          animation: show 0.3s linear 0s

      .tab-trimester-1
        background: $color-knowledge-pink
      .tab-trimester-2
        background: $color-knowledge-orange
      .tab-trimester-3
        background: $color-knowledge-yellow

    .panel-group
      .trimester-btn
        // font-size: $size-3
        padding: $gutter-width
      .panel
        border-radius: 9px
        overflow: hidden
        border: 2px solid 
      .tab-trimester-1
        border-color: $color-knowledge-pink
        .trimester-btn
          background: rgba($color-knowledge-pink,40%)
        .babysymbol 
          color: darken($color-knowledge-pink, 10%)
      .tab-trimester-2
        border-color: $color-knowledge-orange
        .trimester-btn
          background: rgba($color-knowledge-orange,40%)
        .babysymbol 
          color: darken($color-knowledge-orange, 25%)
      .tab-trimester-3
        border-color: $color-knowledge-yellow
        .trimester-btn
          background: rgba($color-knowledge-yellow,40%)
        .babysymbol 
          color: darken($color-knowledge-yellow, 40%)


// masonry調整

.knowledge-grid-sizer,.knowledge-grid-item
  width: 100%
  @include sm
    width: 50%

.knowledge-grid
  .box-border
    box-shadow: 0 0 4px rgba(235,120,140,15%)



// 施設検索

.side-search-box
  overflow: hidden
  animation: show 0.3s linear 0s
  border-radius: $radius-large
  .side-search-title
    background: $color-pink
    font-weight: bold
    color: $color-white
    text-align: center
  .side-search-contents
    padding: $gutter-width
  .side-search-buttons
    .is-button
      border: 2px solid $color-palegray
      background: $color-white
      height: 100%
      text-align: center
      padding: $half-gutter-width 0
      border-radius: $radius-large
      cursor: pointer
      .is-icon
        width: 24px
      .is-em
        font-size: $size-3
        display: block
        font-weight: bold
        margin-bottom: -2px
        margin-top: 5px


// 時期枠
.box-border.is-knowledge-pickup
  border-radius: 0
  padding: $gutter-width
  border: 4px solid #E0CFB8
  background: #FFFCF8
  position: relative
  &::after
    content: ""
    position: absolute
    display: inline-block
    right: $gutter-width
    top: 0
    width: 40px
    height: 40px
    background: url("#{$url_images}/knowledge/deco_ribbon.svg")
    background-repeat: no-repeat
    background-size: contain
  .is-knowledge-pickup-title
    padding-right: 40px
  .is-knowledge-pickup-contents
    margin-bottom: $gutter-width
    padding: $gutter-width 0
    border-top: 1px solid #E0CFB8
    border-bottom: 1px solid #E0CFB8
  .is-knowledge-pickup-img-trim
    position: relative
    overflow: hidden
    padding-top: 100%
    .is-knowledge-pickup-img
      width: 100%
      position: absolute
      top: 50%
      left: 50%
      width: 100%
      height: auto
      transform: translate(-50%, -50%)
      width: 100%
      height: 100%
      object-fit: cover

