@charset "utf-8"



:root 
  --percentage: 100%


// 投票箱
.ballot-card
  position: relative
  margin: 2.5em 0
  padding: 2.5em 1.5em 1em
  border-radius: $radius-large
  background-color: $color-white
  @include sm
    margin: 2.5em 4em
    padding: 2.5em 3.5em 1em

  &.is-before
    border: 2px solid $color-pink
    background-image: url("#{$url_images}/news/ballot/dc_q.svg")
    background-repeat: no-repeat
    background-size: 80px 80px
    background-position: 10px 10px
  &.is-after
    border: 2px solid $color-green
  &.is-end
    border: 2px solid #cccccc

  // 注釈
  .ballot-notes
    font-size: $size-5
    margin: 4px 0
    &.is-right
      text-align: right

  // タイトルなど
  .ballot-card-info
    .ballot-tag
      position: absolute
      top: calc(-1em + 2px)
      left: 1.5em
      padding: 2px 8px
      display: inline-block
      background: #999
      color: $color-white
      font-size: $size-4
      border-radius: $radius-large
      @include sm
        left: 3.5em
      &.is-before
        background: $color-pink
      &.is-after
        background: $color-green
      &.is-end
        background: #cccccc

    .ballot-title
      font-size: $size-3
      text-align: center
      margin-bottom: 1.5em
      @include sm
        font-size: $size-2
      &.is-before
        &::after
          content: "\025bc"
          display: block
          text-align: center
          font-size: $size-5
          color: $color-pink
      &.is-after
      &.is-end



  // ボタン・グラフなど
  .ballot-card-detail

    // ボタン
    .ballot-btn
      widows: 100%
      display: block
      background: #FFF4F4
      border-radius: $radius-rounded
      padding: 8px
      text-align: center
      box-shadow: 0px 3px 5px #F1BCBB57
      border: 1px solid #F1BCBB
      transition: 0.3s
      cursor: pointer

      +.ballot-btn
        margin-top: 0.75em

      &:hover
        background: #ffe7e7
        box-shadow: 0 0 0 #F1BCBB57

      .ballot-btn-text
        font-weight: bold
        color: #795B55
        font-size: $size-3
        display: inline-block
        transition: 0.3s
  
    // グラフ
    .ballot-graph
      height: 36px
      display: flex
      justify-content: space-between
      align-items: center
      padding: 0 16px
      background: #F8F8F8
      position: relative
      border-radius: $radius
      overflow: hidden


      +.ballot-graph
        margin-top: 0.5em

      .ballot-graph-text
        font-weight: bold
        position: relative
        z-index: 1
        // font-size: $size-3

      .ballot-graph-number
        &:after
          color: $color-green
          font-weight: bold
          content: attr(data-percentage)
          position: relative
          z-index: 1

        &::before
          content: ""
          position: absolute
          background: #D3EDED
          width: var(--percentage)
          display: block
          height: 40px
          left: 0
          top: 50%
          transform: translateY(-50%) translateX(-100%)

// アニメーション
.ballot-card
  &.is-in
    .ballot-graph-number
      &::before
        animation: graphAnim 1.5s ease-in 0.3s forwards 

@keyframes graphAnim 
  0% 
    transform: translateY(-50%) translateX(-100%)
  
  100% 
    transform: translateY(-50%) translateX(0)
  
