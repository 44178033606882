.breadcrumb
  padding-right: $size-2
  padding-left: $size-2
  padding-bottom: 4px
  padding-top: 4px
  border-bottom: 1px solid $color-palegray
  margin-bottom: 10px
  @include md
    background: none
    margin-bottom: 0
  nav
    ol
      align-items: flex-start
      display: flex
      flex-wrap: nowrap
      justify-content: flex-start
      overflow: auto
      white-space: nowrap
      @include md
        flex-wrap: wrap
        white-space: inherit

      li
        align-items: center
        display: flex

        &:after
          font-family: "BabySymbols"
          content: "\e602"
          // content: "/"
          display: flex
          padding: 0 4px
          // color: $color-gray

        &:last-child
          // color: $base-font-color
          &:after
            content: none

        a
          text-decoration: none
          padding: 6px 0

          .babysymbol-home
            color: $color-pink
