// 基礎知識・ニュースの記事用css
// 旧 pc style.cssから移植



// 記事

.articleParts
  position: relative
  margin-bottom: 20px

  &.is-article-wrap
    padding: 0
    @include md
      padding: 0 $gutter-width
    > .articleParts
      padding: 0
      
  &:last-child
    margin-bottom: 0px


  // 記事title

  h2
    font-size: $size-1
    font-weight: $title-weight
    line-height: $title-line-height
    border-top: 2px solid $color-supergray
    border-bottom: double 4px $color-supergray
    position: relative
    padding: $gutter-width*0.75 0
    margin-bottom: $gutter-width
    margin-top: $half-gutter-width
    color: #555555
    + h3
      margin-top: -$half-gutter-width
  
  h3
    font-size: $size-2
    font-weight: $title-weight
    line-height: $title-line-height
    position: relative
    padding: .5em 1em .5em 1em
    margin-bottom: 0
    &:before
      position: absolute
      top: .5em
      left: 0
      content: ''
      width: 2px
      height: -webkit-calc(100% - 1em)
      height: calc(100% - 1em)
      background-color: $color-pink
      border-radius: 4px

  h4
    font-size: $title-size
    font-weight: $title-weight
    line-height: $title-line-height
    margin-bottom: $half-gutter-width

  // 段落調整
  p 
    font-size: 16px
    line-height: 1.7

  // 画像
  img
    max-width: 100%
    height: auto
    



  // ボタン

  .linkBtn
    text-align: center
    a
      border-radius: 4px
      box-shadow: none
      display: inline-flex
      line-height: $base-line-height
      position: relative
      vertical-align: top
      cursor: pointer
      justify-content: center
      padding: $size-5 $size-1
      text-align: center
      // white-space: nowrap
      -webkit-appearance: none
      align-items: center
      background-color: $color-pink
      color: $color-white
      font-size: $size-3
      &:hover
        text-decoration: none
      &.att
        background: #E75A73 !important
    &.left
      text-align: left !important
    &.att
      background: #E75A73


    

  // 時期ページヘッダーの前後リンク

  .prevNextHeader
    position: relative
    &.links
      height: 20px
    h1, h2
      text-align: center
      font-size: $size-2
    a, span
      display: block
      position: absolute
      top: 50%
      transform: translateY(-50%)
    .prev:before,.next:after
      color: $color-pink
      font-family: "BabySymbols"
      vertical-align: top
      font-size: 120%
    .prev
      left: 0px
      &:before
        margin-right: 0.2em
        content: "\e601"
    .next
      right: 0px
      &:after
        margin-left: 0.2em
        content: "\e602"
    .prev,.next
      font-size: 0.8em
      letter-spacing: -0.02em
      @include sm
        font-size: $size-4
    span
      &:before, &:after
        display: none !important
    .snsBlock
      display: none

  // youtube

  .youtubeBlock
    position: relative
    width: 100%
    padding-top: 56.25%
    margin: 0px auto 15px
    iframe
      position: absolute
      top: 0
      right: 0
      width: 100%
      height: 100%

  // 体験談リンクとか

  .linkList
    border: 1px solid $color-green
    border-radius: $radius
    overflow: hidden
    .linkHeader
      padding: $gutter-width
      background-color: $color-palegreen
      font-weight: bold
      font-size: $size-3
      &:before
        margin-right: $half-gutter-width
        content: "\e61c"
        font-family: "BabySymbols"
        color: $color-green
    .linkBody li
      position: relative
      + li
        border-top: 1px solid $color-palegray
      a, span
        position: relative
        display: block
        padding: $half-gutter-width $gutter-width
      a:after, span:after
        content: "\e602"
        font-family: "BabySymbols"
        display: inline-block
        color: $color-gray
        position: absolute
        top: 50%
        transform: translateY(-50%)
        right: $half-gutter-width
        margin-left: 0.2em
        font-size: 120%
        // span
        &:after
          display: none !important


  // 時期記事prの商品リンク

  .imageList li
    margin-bottom: 10px
    border-bottom: 1px solid $color-supergray 
    &:after
      content: ""
      display: block
      clear: both
    a
      display: block
      padding: $half-gutter-width 0
      img
        float: left
        margin-right: 10px
        width: 120px
      span
        display: block
        padding-left: 130px
        &.title
          margin-bottom: 5px
          font-weight: bold



  // 記事内リンク

  .detailList li
    padding: $half-gutter-width $gutter-width
    display: flex
    align-items: center
    border-bottom: 1px dashed $color-supergray
    span
      width: calc(100% - 30px) 
      &.sub
        width: 30px
        color: $color-pink
        text-align: right
      a
        display: block



  // 体験談の投稿ボタンのはずが、タイトルにつかわれててまじなぞ

  .postButton
    margin: $half-gutter-width 0
    a
      border-radius: 4px
      box-shadow: none
      display: block
      line-height: $base-line-height
      position: relative
      vertical-align: top
      cursor: pointer
      justify-content: center
      padding: $half-gutter-width
      text-align: center
      background-color: $color-pink
      color: $color-white
      font-size: $size-3
      width: 75%
      margin: 0 auto



  // 体験談

  .experience
    .experienceBlock
      padding: $gutter-width*1.5
      border: 6px solid $color-palepink
      .experienceBody
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, #ffff 50%, #ffff 50%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 97%, #eeeeee 97%, #eeeeee 100%)
        background-size: 8px 100%, 100% 2em
        p
          line-height: 2
          + p 
            margin-top: 0
      &:nth-of-type(2)
        margin-top: $gutter-width*2
      + .experienceBlock
        margin-top: $gutter-width*2

    .metadata   
      margin-top: 10px
      font-size: 86%
      text-align: right
      span
        display: inline-block
        margin-left: 10px
      a
        color: $color-blue



  // 時期記事の体験談リンクなど

  .doubleList
    ul
      display: flex
      flex-wrap: wrap
    li
      margin-bottom: 10px
      width: 50%
      &:before
        margin-right: 0.2em
        content: "\e602"
        font-family: "BabySymbols"
        display: inline-block
        color: $color-pink

  
  // 時期記事の横並びリンク
    
  .textLinkList
    text-align: center
    margin-bottom: $half-gutter-width
    li
      display: inline-block
      &:before
        display: inline-block
        margin: 0px 6px 0px 5px
        content: "|"
        color: $color-gray
        vertical-align: top
      &:first-child:before
        display: none
      a
        padding: 0 $half-gutter-width
        display: inline-block



  // 監修者のメッセージ枠など
  
  .profileBlock
    margin-bottom: $gutter-width

    .profileTitle
      font-size: $size-2
      font-weight: $title-weight
      line-height: $title-line-height
      position: relative
      padding: .5em 1em .5em 1em
      margin-bottom: 0
      &:before
        position: absolute
        top: .5em
        left: 0
        content: ''
        width: 2px
        height: -webkit-calc(100% - 1em)
        height: calc(100% - 1em)
        background-color: $color-pink
        border-radius: 4px

    .profileImage
      float: left
      margin-right: 10px
      margin-bottom: 5px
      img
        max-width: 160px

    .desc
      padding-left: 190px
      zoom: 1
      &:after
        content: ""
        display: block
        clear: both

    .profileComment,.profileInterview
      border: 1px solid $color-supergray
      margin-top: $half-gutter-width
      border-radius: $radius
      .commentHeader, .interviewHeader
        padding: $gutter-width
        overflow: hidden
        border-radius: 4px 4px 0px 0px
        font-size: $size-4
        font-weight: bold
      .commentHeader
        background: $color-palepink
      .interviewHeader
        background: $color-palegreen
      .commentBody, .interviewBody
        padding: $gutter-width      



  // ページ送りのボタン

  .leftRightLinks
    margin: $half-gutter-width 0
    display: flex
    justify-content: space-between
    
    .left-button, .right-button
      display: inline-block
      position: relative
      padding:  $half-gutter-width $gutter-width
      border: 1px solid $color-supergray
      border-radius: $radius
      &:after
        position: absolute
        top: 50%
        transform: translateY(-50%)
        font-family: "BabySymbols"
        color: $color-pink
        font-size: 120%

    .left-button
      padding-left: $gutter-width*2
      &:after
        left: $half-gutter-width
        content: "\e601"

    .right-button
      padding-right: $gutter-width*2
      &:after
        right: $half-gutter-width
        content: "\e602"



  // 時期記事下部の２列ボタン
  
  .doubleButtons
    display: flex
    width: 100%
    a, input[type=button], button
      width: 50%
      display: block
      margin: $half-gutter-width
      padding: 15px
      background: $color-pink
      border-radius: $radius
      color: #FFF
      text-align: center




  // Q&A
  .questionList
    border: 1px solid $color-green
    border-radius: $radius
    overflow: hidden
    .listTitle
      background-color: $color-palegreen
      a
        display: block
        padding: $gutter-width
        position: relative
        font-weight: bold
        font-size: $size-3
        &:before
          margin-right: $half-gutter-width
          content: "\e604"
          font-family: "BabySymbols"
          color: $color-green
        &:after
          margin-left: 0.2em
          content: "\e602"
          font-family: "BabySymbols"
          color: $color-gray
          position: absolute
          top: 50%
          right: $half-gutter-width
          transform: translateY(-50%)

    li
      position: relative
      + li
        border-top: 1px solid $color-palegray
      &.more
        background: $color-palegreen
        text-align: center
        a:after
          display: none
      a
        display: block
        padding: $half-gutter-width calc($gutter-width*2) $half-gutter-width $gutter-width
        position: relative
        &:after
          content: "\e602"
          font-family: "BabySymbols"
          display: inline-block
          color: $color-gray
          position: absolute
          top: 50%
          transform: translateY(-50%)
          right: $half-gutter-width
          font-size: 120%

  // classのないtable
  table:not([class])
    color: $base-font-color
    margin: $gutter-width 0
    width: 100%
    td,
    th
      border: 2px solid $color-palepink
      border-width: 0 0 2px
      vertical-align: top
      padding: $half-gutter-width 0
    th
      &:not([align])
        text-align: inherit

  // classのない p>a,p>span>a,p>strong>a,だけunderline
  p:not([class])
    >span:not([class])>a:not([class]),
    >strong:not([class])>a:not([class]),
    >a:not([class])
      text-decoration: dashed underline $color-gray
      text-underline-position: under
      text-underline-offset: 1px
      text-decoration-thickness: 1px
      word-break: break-all
  p.next a
    text-decoration: dashed underline $color-gray
    text-underline-position: under
    text-underline-offset: 1px
    text-decoration-thickness: 1px
    word-break: break-all

  // 時期記事のtodoリスト なにこれ
  p input[type=checkbox] 
    appearance: auto
    display: inherit
    margin: $half-gutter-width
    height: 16px
    width: 16px

      