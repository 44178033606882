@charset "utf-8"

#baby-food
  overflow-x: hidden
  img
    width: 100%
    height: auto
    & + p
      margin-top: 1.6rem
      @include md
        margin-top: 1.6rem
  h1
    & + *:not(h2)
      margin-top: 1.6rem
    & + h2
      margin-top: 0
  h2
    & + p
      margin-top: 1.6rem
  ul:not([class])
    margin: 1rem 0
    @include sm
      margin: 1.6rem 0
  p
    & + p
      margin-top: 1.6rem
    & + .baby-food-headline-h2
      margin-top: 1.6rem
  hr
    border-top: none
    border-bottom-style: dashed
    border-width: 2px
    border-color: $color-pink
  .gutter-around
    & + .col-xs
      > *:first-child
        margin-top: 0
        @include sm
          margin-top: 1.6rem
  .marker
    background-image: linear-gradient(rgba(255,255,255,0) 30%, rgba(255,116,141,.25) 70%)

// 離乳食バナー、広告バナー
.pr-banner
  > img
    max-width: 640px
    height: auto
#ad-book
  background-image: url("#{$url_images}/recipe/icon_header_book_new.png"),url("#{$url_images}/recipe/cover_header_book_next.png"),url("#{$url_images}/recipe/cover_header_book_wood.png")
  background-repeat: no-repeat,no-repeat,repeat-x
  background-position: left top,right top
  background-size: 35px,auto 100%,auto
  display: block
  line-height: 1
  color: #5d2e2b
  padding: 6px 18px 3px 22px
  display: block
  width: 100%
  img
    height: 60px
    width: auto
  @include sm
    display: none
  > div
    display: flex
    -ms-align-items: center
    align-items: center
    div
      &:first-child
        margin-right: .75rem
    .ad-book-title
      font-size: 1.6rem
      line-height: 1.2
      font-weight: 700
    .ad-book-subtitle
      background: url("#{$url_images}/recipe/cover_header_book_ribbon.png")
      background-repeat: no-repeat
      height: 25px
      background-size: 100% 25px
      line-height: 25px
      position: relative
      margin: .25rem 0
      letter-spacing: -.05rem
      font-size: 1.1rem
      text-align: center
      padding: 0 .75rem
      white-space: nowrap
  & + *
    margin-top: 1.6rem

#type-month-tab,#type-month-tab-bottom,#baby-food-recipe-contents-bottom
  .baby-food-headline-h2
    margin-top: -1px
    margin-bottom: 0
    &.icon
      display: block
      &::before
        display: inline
        width: 24px
        height: 22px
        padding-left: 24px
        top: -2px
      span
        margin: 0 .15rem

// ヘッダー 離乳食ナビゲーション、コンテンツ下部ナビボタン

#baby-food-nav
  padding: .5rem 0 .5rem
  background: $color-recipe-bg-gray
  font-family: $base-font-sans
  @include md
    padding: 0
    margin: 0.75rem 1.4rem 2rem
    border: 1px solid $color-recipe-border-gray
  .baby-food-nav-link
    display: flex
    @include md
      background: $color-white
      height: 55px
    > li
      position: relative
      width: 20%
      cursor: pointer
      text-align: center
      line-height: 1.25
      font-size: 1.2rem
      font-weight: 700
      // background: $color-recipe-bg-gray
      border-right: 2px dashed $color-recipe-border-gray
      border-bottom: 1px solid $color-recipe-border-gray
      border-top: 1px solid $color-recipe-border-gray
      @include md
        display: flex
        align-items: center
        justify-content: center
        font-size: 1.6rem
        background: $color-white
        border-bottom: none
        border-top: none
      > a
        display: block
        padding: 5px 0 12px
        color: $base-font-color
        background: $color-recipe-bg-gray
        @include md
          padding: 0
          color: $base-font-color
          background: $color-white
        &:focus,&:active,&:hover
          background: $color-white
          color: $color-pink
          @include md
            background: inherit
            color: inherit
          .icon-arrow-down
            background-image: url("#{$url_images}/recipe/parts_arrow-down-active.png")
            @include md
              background-image: url("#{$url_images}/recipe/parts_arrow-down.png")
      &.recipe
        .nav-inner
          margin-left: -20vw
          @include md
            margin-left: auto
      &.guide
        .nav-inner
          margin-left: -40vw
          @include md
            margin-left: auto
      &.tips
        .nav-inner
          margin-left: -60vw
          @include md
            margin-left: auto
      &.safety-standard
        border-right: none
        .nav-inner
          margin-left: -80vw
          @include md
            margin-left: auto
      &.is-open,&:focus-within
        > a
          background: $color-white
          color: $color-pink
          opacity: 1
        &::after
          content: ""
          transition: .3s
          position: absolute
          height: 4px
          border-radius: 2px
          background: $color-pink
          left: 30%
          width: 40%
          top: 40px
          @include sm
            top: 25px
          @include md
            left: 45%
            width: 20%
            top: 45px
        .icon-arrow-down
          display: none
      &:hover
        .nav-inner
          //display: block
          visibility: visible
          opacity: 1
          height: auto
          @include md
            top: 100%
      .icon-arrow-down
        display: block
        background-image: url("#{$url_images}/recipe/parts_arrow-down.png")
        background-repeat: no-repeat
        background-size: contain
        background-position: center
        width: 10px
        height: 10px
        position: absolute
        left: 34px
        top: 37px
        @include sm
          left: auto
          top: 12px
          right: 12px
        @include md
          padding-top: 5px
          margin: 5px auto 0
          left: auto
          right: 15px
          top: 50%
          bottom: auto
          transform: translateY(-50%)
          margin: 0
      .nav-inner
        height: 0
        opacity: 0
        visibility: hidden
        background: $color-white
        width: 100vw
        z-index: 9999
        transition: all .2s linear
        display: block
        position: relative
        @include md
          position: absolute
          top: 80%
          left: 0
          width: auto
          padding-top: 0
          box-shadow: 0 4px 5px 0px rgba(152,152,152,0.2)
        > li
          text-align: left
        a
          border-top: 1px solid $color-recipe-border-gray
          padding: 10px 30px 10px 10px
          white-space: nowrap
          position: relative
          font-weight: 700
          display: block
          line-height: 18px
          @include md
            font-size: 1.4rem
            padding: 12px 30px 12px 10px
            min-height: 42.5px
          &::after
            display: inline-block
            font-family: "BabySymbols"
            content: "\e602"
            color: $color-recipe-gray
            margin-left: auto
            position: absolute
            right: 8px
            top: 50%
            transform: translateY(-50%)
    .sm-show
      display: none
      @include md
        display: inline

.baby-food-nav-btn
  padding: 0 1.6rem
  margin-bottom: 1.4rem
  @include sm
    margin-bottom: 1.6rem
  .baby-food-nav-link
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    height: auto
    > li
      position: relative
      width: 50%
      border-bottom: 1px solid $color-recipe-border-gray
      padding: .5rem .75rem
      > a
        padding: 1rem 0
        display: block
        font-weight: 700
        font-size: 1.4rem
        line-height: 1.25
        &::after
          display: inline-block
          font-family: "BabySymbols"
          content: "\e602"
          color: $color-recipe-gray
          margin-left: auto
          position: absolute
          right: 0
          top: 50%
          transform: translateY(-50%)

// フッター 離乳食コンテンツリスト

#baby-food-contentslist
  margin: -2rem 0 0
  position: relative
  padding: 0
  @include md
    margin: 0
    padding-bottom: 0
    background: $color-white
  > .wrapper .row
    @include md
      padding: 0
  .baby-food-nav-link
    width: 100%

    display: flex
    flex-direction: column
    border-top: 1px solid $color-recipe-border-gray
    padding: 0 .25rem
    @include md
      border: none
      padding: 0
    li
      a

        display: flex
        align-items: center
        &::after
          content: "\e602"
          display: inline-block
          font-family: "BabySymbols"
          color: $color-recipe-gray
          padding: 0 0.25rem
          margin-right: 0
          margin-left: auto
          font-size: 1rem
          @include md
            display: none
    > li
      font-size: 1.2rem
      font-weight: 700
      border-bottom: 1px solid $color-recipe-border-gray
      @include md
        font-size: 1.3rem
        border: none
        padding: 0 .75rem
      &:last-child
        border: none
      &.books
        border-bottom: 1px solid $color-recipe-border-gray
        @include md
          border: none
      > a
        padding: 6px 0
        margin: 0
      > ul

        display: flex
        flex-direction: row
        flex-wrap: wrap
        justify-content: space-between
        > li
          width: 48%
          font-weight: 400
          border-top: 1px solid $color-recipe-border-gray
          font-size: 1.1rem
          line-height: 1.25
          @include md
            font-size: 1.3rem
            width: 100%
            border: none
          > a
            padding: 8px 0 8px 16px
            @include md
              padding: 6px 0 6px 18px
          .baby-food-headline-h3
            font-size: inherit !important
            font-weight: inherit !important
            margin: 0
            &::before
              left: .4rem
              top: .5rem
              @include md
                left: .6rem
          &.m5-6 > .baby-food-headline-h3::before
            background-color: $color-recipe-m5-6
          &.m7-8 > .baby-food-headline-h3::before
            background-color: $color-recipe-m7-8
          &.m9-11 > .baby-food-headline-h3::before
            background-color: $color-recipe-m9-11
          &.y1-y1m6 > .baby-food-headline-h3::before
            background-color: $color-recipe-y1-y1m6
    & + .row
      margin: 0
      @include md
        flex-direction: row

// 食材ナビ
.ingredients-list
  display: flex
  flex-wrap: nowrap
  align-items: center
  > p
    font-size: 110%
    font-weight: 700
    line-height: 1.25
    padding-left: 1rem
    > span
      display: block
      font-weight: normal
      font-size: 1.2rem
      margin-top: .5rem
.ingredients-nav
  margin-top: 1.6rem
  .ingredients-list
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    height: auto
    li
      position: relative
      border-bottom: 1px solid $color-recipe-border-gray
      padding: 0.5rem 0
      &:first-child
        padding: .5rem 0
        line-height: 2
        &.icon
          border-bottom: 1px solid $color-recipe-border-gray !important
          &::before
            border-radius: 0 !important
      a
        font-size: 1.2rem
        padding-right: 1.6rem
        font-weight: 700
        line-height: 1.25
        @include md
          font-size: 1.4rem
          padding-right: 1.6rem
        span
          display: block
          font-weight: normal
          font-size: 1.1rem
          line-height: 1.25
          margin-top: .25rem
        &::after
          display: inline-block
          font-family: "BabySymbols"
          content: "\e602"
          color: $color-recipe-gray
          margin-left: auto
          position: absolute
          right: .25rem
          top: 50%
          transform: translateY(-50%)
        &.icon
          font-weight: 700
          &::before
            border-radius: 10px
            height: 45px
            width: 45px
            padding-left: 45px
            @include md
              height: 60px
              width: 60px
              padding-left: 60px
  & + .recipe-more-link
    border-top: none

// 離乳食 時期別タブ

#type-month-tab,#type-month-tab-bottom
  @include sm
    margin-top: 1.6rem
  .panel-group
    > .panel
      overflow: visible
      position: relative
      > .gutter-around:first-child
        padding-top: 0
      > div:first-child
        .baby-food-headline-h1,.baby-food-headline-h2
          &:first-child
            margin-top: 0
        &::after
          content: ""
          display: block
          // width: calc( 100% + 1.6rem + 1.6rem)
          width: 110%
          border-top: 3px solid
          position: absolute
          top: -2px
          left: 50%
          transform: translateX(-50%)
        &.m5-6
          &::after
            border-color: $color-recipe-m5-6-op
        &.m7-8
          &::after
            border-color: $color-recipe-m7-8-op
        &.m9-11
          &::after
            border-color: $color-recipe-m9-11-op
        &.y1-y1m6
          &::after
            border-color: $color-recipe-y1-y1m6-op
        .gutter-around
          padding-top: 0

  .tab-group
    background: none
    display: flex
    justify-content: center
    > .tab
      flex: 1
      line-height: 1.25
      font-size: 1.4rem
      font-weight: 700
      max-width: 165px
      display: block
      height: auto
      padding: 10px 5px 15px
      margin: 0 3px
      border-radius: 3px 3px 0 0
      border: 2px solid $color-pink
      letter-spacing: -0.05rem
      opacity: .75
      &.is-active
        color: $base-font-color
        opacity: 1
        &::after
          content: ""
          transition: .3s
          position: absolute
          bottom: 5px
          width: 80%
          left: 10%
          height: 4px
          border-radius: 2px
          background: $color-pink
      &.m5-6
        border-color: $color-recipe-m5-6
        &.is-active
          &::after
            background: $color-recipe-m5-6-op
      &.m7-8
        border-color: $color-recipe-m7-8
        &.is-active
          &::after
            background: $color-recipe-m7-8-op
      &.m9-11
        border-color: $color-recipe-m9-11
        &.is-active
          &::after
            background: $color-recipe-m9-11-op
      &.y1-y1m6
        border-color: $color-recipe-y1-y1m6
        &.is-active
          &::after
            background: $color-recipe-y1-y1m6-op
      &.icon
        &::before
          background: transparent url("#{$url_images}/recipe/icon_recipe.svg") no-repeat center / contain
          height: 30px
          width: 30px
          display: block
          margin: 0 auto 10px
          opacity: 1
          position: relative
        &.m5-6::before
          background: transparent url("#{$url_images}/recipe/icon_m5-6.svg") no-repeat center / contain
        &.m7-8::before
          background: transparent url("#{$url_images}/recipe/icon_m7-8.svg") no-repeat center / contain
        &.m9-11::before
          background: transparent url("#{$url_images}/recipe/icon_m9-11.svg") no-repeat center / contain
        &.y1-y1m6::before
          background: transparent url("#{$url_images}/recipe/icon_y1-y1m6.svg") no-repeat center / contain

// 離乳食 デザインパーツ 見出し

.baby-food-headline-h1,.baby-food-headline-h2,.baby-food-headline-recipe,.baby-food-headline-h3,.baby-food-headline,.baby-food-headline-center,.baby-food-2col-headline,.area-headline
  font-weight: 700
.baby-food-headline-h1,.baby-food-headline-h2,.baby-food-headline-recipe,.area-headline
  & + *:not(.baby-food-headline-h2,.gutter-mtb,.gutter-around,.recipe-list,.recipe-list &*,.recipe-movie-list,.recipe-movie-list-static)
    margin-top: 1.6rem
.baby-food-headline-h2,.baby-food-headline-h3,.baby-food-headline,.baby-food-headline-center,.baby-food-2col-headline
  margin: 1.6rem 0
.baby-food-headline-h2
  font-size: 125%
.baby-food-headline-h3,.baby-food-headline,.baby-food-headline-center,.baby-food-2col-headline,.area-headline
  font-size: 115%
.baby-food-headline-h1,.baby-food-headline-recipe
  position: relative
  font-size: 1.75rem !important
  line-height: 1.5
.baby-food-headline-h2,.area-headline
  padding: 8px 15px 7px
  background: $color-recipe-bg-gray
  border-top: 1px solid $color-recipe-border-gray
  border-bottom: 1px solid $color-recipe-border-gray
  span,.color
    color: $color-pink
.baby-food-headline-h1
  color: $color-white
  background-color: $color-pink
  padding: 5px 0
  .headline-inner
    text-shadow: 0 -1px 1px rgba(0,0,0,0.25)
    width: 100%
    padding: 7px 15px
    border-top: 2px dashed $color-recipe-border-gray
    border-bottom: 2px dashed $color-recipe-border-gray
    display: flex
    align-items: center
    p
      display: inline-block
    a
      display: block
      font-size: 1.2rem
      margin-top: 1.7rem
      &::after
        position: absolute
        bottom: 0
        right: 0
        content: "一覧を見る >"
        display: inline-block
        padding: 4px 8px
        border-radius: 8px 0 0 0
        color: #ffffff
        background: $color-deeppink
  .recipe-num
    display: inline-block
    font-size: 1.2rem
    background: $color-white
    padding: 2px 8px
    margin-left: 8px
    border-radius: 20px
    text-shadow: none
    color: $color-pink
    top: -2px
    position: relative
    & + p
      padding-left: 30px
  & + .baby-food-headline-h2
    margin-top: 0
.dietitian-recipe
  color: $base-font-color
  position: absolute
  width: 180px
  height: 90px
  background-image: url("#{$url_images}/recipe/parts_dietitian-recipe.png")
  background-repeat: no-repeat
  background-size: contain
  right: 0
  top: -18px
  z-index: 9
  p
    position: absolute
    top: 19px
    left: 40px
    font-size: 14px
    text-align: center
    span
      font-size: 150%
      vertical-align: middle

.baby-food-headline-h3
  position: relative
  padding: 0.5rem 0 0.5rem 2rem
  &::before
    content: ""
    width: 5px
    height: -webkit-calc(100% - 0.5rem)
    height: calc(100% - 1rem)
    background-color: $color-pink
    border-radius: 4px
    position: absolute !important
    top: 0.5rem
    left: 0.5rem
.baby-food-headline
  display: flex
  align-items: center
  margin: 1.6rem 0
.baby-food-headline-center
  display: block
  text-align: center
  // color: $color-pink
  position: relative
  &.default
    color: $color-pink
    padding-bottom: 1.5rem
    color: $base-font-color !important
    &::after
      content: ""
      display: block
      width: 20%
      height: 8px
      background-image: url("#{$url_images}/recipe/heading-message-area-dotted.png")
      background-repeat: no-repeat
      background-size: contain
      background-position: center
      background-color: transparent !important
      position: absolute
      bottom: 0
      left: 50%
      transform: translateX(-50%)
  &.border,&.default-border
    padding-bottom: 1rem
    &::after
      content: ""
      display: block
      width: 20%
      height: 4px
      border-radius: 4px
      background-color: $color-pink
      position: absolute
      bottom: 0
      left: 50%
      transform: translateX(-50%)
      @include md
        width: 10%
  &.default-border
    color: $color-pink !important
    &::after
      background-color: $color-pink !important
  &.icon,&.icon-circle
    display: block !important
    &::before
      display: inline-block
      top: 7px
.baby-food-2col-headline
  display: flex
  align-items: center
  flex-wrap: nowrap
  line-height: 1.25
  margin: 1.6rem 0
  span
    white-space: nowrap
    display: inline-block
    padding: 1rem
    margin-right: 1rem
    background-color: $color-white
    color: $color-pink !important
    font-size: 80%
    border: 2px solid $color-pink
    border-radius: 6px
    height: fit-content
    line-height: 1.5
    &.is-circle
      text-align: center
      width: 34px
      height: 34px
      border-radius: 200px
      padding: 0
      line-height: 32px
.recipe-page-title
  margin-top: 0 !important
  @include md
    margin-top: -1.6rem !important

// 離乳食 デザインパーツ リンク・アロー付きリンク

a.link
  &.text-link
    display: inline
    cursor: pointer
    color: $color-blue
    border-bottom: 1px solid
  &.arrow-right,
  &.arrow-left,
  &.arrow-down,
  &.blank-link
    display: inline-block
    position: relative
    &::before,&::after
      display: inline-block
      font-family: "BabySymbols"
      color: $color-pink
      padding: 0 0.25rem
      position: absolute
      top: 0
    & + .arrow-right,
    & + .arrow-left,
    & + .arrow-down,
    & + .blank-link
      margin-left: 1rem
  &.arrow-right
    padding-right: 2rem
    &::after
      right: 0
      content: "\e602"
  &.arrow-left
    padding-left: 2rem
    &::before
      left: 0
      content: "\e601"
  &.arrow-down
    padding-left: 2rem
    &::after
      left: 0
      content: "\e600"
  &.blank-link
    padding-right: 2rem
    &::after
      right: 0
      content: "\e633"

// 離乳食 デザインパーツ ボタン

.baby-food-btn-round,.baby-food-btn
  background-color: $color-pink
.baby-food-btn-round
  padding: 5px
  border-radius: 100px
  display: flex
  align-items: center
  position: relative
  > p
    margin: 0 !important
    width: 100%
    padding: 4px 20px 8px 30px
    border: 1px dashed $color-white
    border-radius: 100px
    font-weight: 700
    font-size: 1.2rem
    letter-spacing: -0.05rem
    color: $color-white
    text-shadow: 0 -1px 1px rgba(0,0,0,0.25)
    white-space: nowrap
    @include md
      padding: 10px 30px 10px 40px
      font-size: 1.4rem
    span
      font-size: 2rem
      @include md
        font-size: 2.4rem
  &::after
    display: inline-block
    font-family: "BabySymbols"
    content: "\e602"
    color: $color-white
    font-size: 16px
    position: absolute
    right: 10px
    top: 50%
    transform: translateY(-50%)
    @include md
      right: 20px
  &.icon-circle
    position: relative
    &::before
      content: ""
      position: absolute
      width: 28px
      height: 26px
      left: 10px
      top: 50%
      transform: translateY(-50%)
      @include md
        width: 32px
        height: 30px
        left: 12px
.baby-food-btn
  display: block
  margin: 1.6rem auto
  padding: 10px 30px 10px 10px
  border-radius: 3px
  font-weight: 700
  font-size: 1.6rem
  text-align: center
  position: relative
  color: $color-white !important
  text-shadow: 0 -1px 1px rgba(0,0,0,0.25)
  @include md
    padding: 15px 30px 15px 15px
    margin: 10px auto
    max-width: 460px
    min-width: 240px
  &::after
    position: absolute
    display: inline-block
    width: 15px
    height: 15px
    content: ''
    background-repeat: no-repeat
    background-size: contain
    background-image: url("#{$url_images}/recipe/arrow_w.svg")
    right: 10px
    bottom: 50%
    transform: translateY(50%)
    @include md
      right: 20px
  &.amazon
    background: #ff9902 !important
    color: #121921!important
  &.rakuten
    background: #bf0000 !important
    color: $color-white!important
  &.yahoo
    background: #4070ff !important
    color: $color-white!important

// 離乳食 デザインパーツ アウトラインボタン

.baby-food-btn-outline
  display: block
  margin: 1.6rem auto
  height: auto
  padding: 8px 10px
  border-radius: 3px
  font-weight: 700
  font-size: 1.6rem
  letter-spacing: 0.1em
  white-space: normal
  position: relative
  border: 2px solid $color-pink
  text-align: center
  @include md
    padding: 10px
    max-width: 460px
    min-width: 240px
  &.default
    color: $color-pink !important
    border-color: $color-pink !important
    > i.babysymbol::before
      color: $color-pink !important
    &:link,&:visited
      color: $color-pink
  &:link,&:visited
    color: $color-pink
  i.babysymbol::before
    position: relative
    top: 1px

// 離乳食 時期別アンカーリンク

.type-month-anchor
  .type-month-anchor-link
    display: flex
    justify-content: center
    flex-flow: row wrap
    > li
      flex: 1
      position: relative
      margin: 6px 3px
      cursor: pointer
      text-align: center
      line-height: 1.25
      font-size: 1.4rem
      font-weight: 700
      width: calc( 100% / 4 - 6px)
      min-width: calc( 100% / 4 - 6px)
      max-width: calc( 100% / 4 - 6px)
      a
        display: block
        height: 100%
        padding: 15px 8px
        border-radius: 3px
        border: 2px solid $color-pink
        letter-spacing: -0.05rem
        span
          color: $color-pink
          font-size: 2.8rem
          display: block
          margin-bottom: .5rem
      &.m5-6
        a
          border-color: $color-recipe-m5-6
      &.m7-8
        a
          border-color: $color-recipe-m7-8
      &.m9-11
        a
          border-color: $color-recipe-m9-11
      &.y1-y1m6
        a
          border-color: $color-recipe-y1-y1m6
          height: fit-content
    &.is-tips
      @include sm
        flex-flow: row
      > li
        @include sm
          width: calc( 100% / 7 - 42px)
          min-width: auto
          max-width: 165px
          height: 100px
        a
          display: flex
          flex-direction: column
          align-items: center
          &.arrow
            padding: 1rem 0 1.5rem
          &.icon
            justify-content: center
    &.is-tips-2col
      > li
        width: calc( 100% / 4 - 12px)
        min-width: auto
        max-width: 165px
        height: 100px
  .arrow
    position: relative
    &::after
      position: absolute
      display: inline-block
      width: 15px
      height: 15px
      border-radius: 15px
      content: ''
      background-repeat: no-repeat
      background-size: contain
      transform: translateX(-50%) rotate(90deg)
      left: 50%
      bottom: -8px
      background-image: url("#{$url_images}/recipe/arrow_default.svg")

// 離乳食 月齢横断ボタン・食材横断ボタン・月齢5-6カ月の⚫︎カ月目〜ボタン

.type-month-btn,.ingredients-btn,.moon-phase-btn
  display: flex
  flex-flow: wrap
  align-items: center
  justify-content: space-between
  padding: 0 .75rem
  @include sm
    margin-top: 1.6rem
  > li
    text-align: center
    color: $color-white
    width: 49%
    margin: .25rem 0
    a
      display: block
      position: relative
    &.current
      position: relative
      &::after
        content: ""
        display: inline-block
        width: 55px
        height: 35px
        position: absolute
        top: -10px
        right: -10px
        background-repeat: no-repeat
        background-size: contain
        background-position: center
        background-image: url("#{$url_images}/recipe/icon_selected.png")
.ingredients-btn
  > li
    width: calc( (100% / 3) - 6px )
    margin: 0 3px
    @include md
      width: calc( (100% / 6) - 6px )
    .baby-food-btn-outline
      width: 100%
      min-width: 100%
      font-size: 1.4rem
      padding: 10px 8px
      margin: 0 0 .75rem
      justify-content: center
      &::after
        content: ""
        display: inline-block
        width: 15px
        height: 15px
        border-radius: 15px
        background-repeat: no-repeat
        background-size: contain
        background-image: url("#{$url_images}/recipe/arrow_w.svg")
        margin-left: .25rem
.moon-phase-btn
  justify-content: center
  .baby-food-btn-outline
    width: 100%
    min-width: 100%
    font-size: 1.4rem
    padding: 10px 8px
    margin: 0 0 .75rem
    flex-direction: column
    justify-content: center
    span
      color: $base-font-color
      &::after
        content: ""
        display: inline-block
        width: 15px
        height: 15px
        border-radius: 15px
        background-color: $color-pink
        background-repeat: no-repeat
        background-size: contain
        border-color: $color-recipe-m5-6-op
        background-color: $color-recipe-m5-6-op
        background-image: url("#{$url_images}/recipe/arrow_m5-6.svg")
        margin-left: .25rem
        top: 2px
        position: relative
      > em
        font-size: 2rem
        color: $color-recipe-m5-6-op
        font-style: normal
        font-weight: 700
        padding: 0 .25rem
  > li
    &:last-child
      .icon
        &::before
          width: 64px !important
          padding-left: 64px !important
          background-image: url("#{$url_images}/recipe/icon_m5-6-2.svg") !important

// 離乳食 デザインパーツ リスト

.baby-food-list-circle,.baby-food-list-check,.baby-food-list-number,.baby-food-list-timeline
  display: block
  max-width: 100%
  min-width: 50%
  margin: 1.4rem 0
  @include sm
    margin: 1.6rem 0
  > li
    display: block
    padding: .25rem 0 .25rem 3rem
    position: relative
    &::before
      width: 14px
      height: 14px
      font-family: "BabySymbols"
      font-size: 1.4rem
      transform: translateY(-50%)
      color: $color-pink
      position: absolute
      top: 1rem
      left: .75rem
      @include md
        right: 20px
    &:last-child
      padding-bottom: 0
.baby-food-list-circle
  > li
    &::before
      content: "\e60f"
.baby-food-list-check
  > li
    &::before
      content: "\e642"
.baby-food-list-number
  margin: 1.6rem 0
  counter-reset: number
  > li
    position: relative
    padding-left: 3rem
    &::before
      counter-increment: number
      content: counter(number)'．'
      position: absolute
      left: .75rem
      top: .9rem
.baby-food-list-timeline
  > li
    margin-left: .5rem
    padding: 0 0 1.5rem 3rem
    position: relative
    &:last-child
      &::before
        display: none
    &::before,&::after
      content: ""
      display: block
      position: absolute
      background-color: $color-pink
    &::before
      top: 3.5rem
      left: .6rem
      width: 2px
      height: calc(100% - 2.5rem)
    &::after
      top: .6rem
      border-radius: 100%
      left: 0px
      width: 15px
      height: 15px
      background-color: $color-pink
    span
      display: inline-block
      vertical-align: middle
      font-weight: bold
      &.time
        font-size: 1.75rem
        width: 55px
        text-align: right
        margin-right: 1rem
      &.milk,&.babyfood
        margin: 0 5px
        padding: 2px 14px
        background-size: 5px 5px
        background-attachment: fixed
      &.milk
        background-image: linear-gradient(-45deg,#ffffff 25%,#fdd5d0 25%,#fdd5d0 50%,#ffffff 50%,#ffffff 75%,#fdd5d0 75%,#fdd5d0)
      &.babyfood
        background-image: linear-gradient(-45deg,#ffffff 25%,#c5ecd5 25%,#c5ecd5 50%,#ffffff 50%,#ffffff 75%,#c5ecd5 75%,#c5ecd5)
.list-caption
  margin-top: .75rem
  > li
    font-size: 1.2rem
    line-height: 1.25
    padding-left: 1.5rem
    position: relative
    & + li
      margin-top: .25rem
    &::before
      content: "＊ "
      display: inline-block
      position: absolute
      top: .05rem
      left: 0

// 離乳食 デザインパーツ テーブル

.baby-food-table-separate,.baby-food-table-border,.baby-food-table-recipe,.baby-food-guide-table
  width: 100%
  font-size: 95%
  margin: 1rem 0
  @include sm
    margin: 1.6rem 0
  tr
    th,td
      vertical-align: middle
      > p
        margin: 0 !important
        & + p
          margin-top: .75rem !important
    th
      background: $color-recipe-bg-gray
      text-align: center
  &.center
    tr
      th,td
        text-align: center

.baby-food-table-recipe
  table-layout: auto
  tr
    border-bottom: 2px dashed $color-recipe-border-gray
    th,td

      vertical-align: top
  td
    &:first-child
      padding: .75rem .75rem .75rem 0
      font-size: 100%
      font-weight: 700
      min-width: 50%
    &:last-child
      padding: .75rem 0 .75rem .75rem
      border-left: 1px solid $color-recipe-border-gray
      font-size: 98%
      text-align: right
      min-width: 30%

.baby-food-table-separate
  border-collapse: separate
  border-spacing: 1rem
  margin-left: -1rem
  margin-right: -1rem
  th
    border: 1px solid $color-recipe-border-gray
    padding: 1rem
    font-weight: 700
    width: 30%
  td
    padding: 1rem 0

.baby-food-table-border
  table-layout: fixed
  border-left: 1px solid $color-recipe-border-gray
  border-top: 1px solid $color-recipe-border-gray
  th,td
    border-right: 1px solid $color-recipe-border-gray
    border-bottom: 1px solid $color-recipe-border-gray
  th
    padding: .25rem 1rem
  td
    padding: 1rem

.baby-food-guide-table
  table-layout: fixed
  border-collapse: separate
  border-spacing: .2rem
  border: 1px solid $color-recipe-border-gray
  tr
    th,td
      border: none
      line-height: 1.25
      &:first-child
        border-right: 1px solid $color-recipe-border-gray
    th
      padding: .25rem .25rem .5rem
      &:first-child
        width: 25%
      &:last-child
        letter-spacing: -.05rem
      &.c-gray
        background-color: $color-gray !important
        color: $color-white !important
    td
      padding: .5rem .25rem
      text-align: center
      background-color: $color-recipe-bg-gray
      &.text-left
        text-align: left
      &:first-child
        font-weight: 700
      > a
        color: $color-blue
        &::after
          font-size: 1rem
          content: "レシピ"
          display: block
          text-decoration: underline
    &:nth-child(2n)
      th,td
        background-color: $color-white
  &.default
    border-color: $color-recipe-border-gray !important
    tr
      th,td
        background-color: $color-recipe-bg-gray !important
      &:nth-child(2n)
        th,td
          background-color: $color-white !important
  &.col-3
    tr
      th,td
        padding: .5rem 1rem
        height: 44px
        &:first-child
          width: 25%
          border: none
        &:nth-child(2)
          width: 55px
      td
        &:last-child
          text-align: left
  .babysymbol
    font-size: 2rem
    &.babysymbol-circle::before
      color: $color-pink !important
    &.babysymbol-triangle::before
      color: $color-green !important
    &.babysymbol-close::before
      color: $color-gray !important

// 離乳食 デザインパーツ 目次・関連リンク・テキストエリア

.baby-food-contents-list,.baby-food-related-list,.baby-food-textarea
  display: block
  border: 1px solid $color-recipe-border-gray
  border-radius: 3px
  overflow: hidden
  margin: 1rem 0
  @include sm
    margin: 1.6rem 0
  .area-headline
    margin: -1rem -1rem .75rem
    text-align: center
    border-top: none !important
    @include sm
      margin: -1.6rem -1.6rem 1.6rem
  > .baby-food-2col-headline
    margin-top: 0
  .row
    margin-bottom: 0
    div:not(:first-child)
      margin-top: 1.6rem
      @include md
        margin-top: inherit
  & *:first-child:not(.area-headline)
    margin-top: 0

.baby-food-textarea
  padding: 1rem
  @include md
    padding: 1.6rem
  > .baby-food-headline-h2
    margin-top: -1.6rem !important
  p:last-child
    margin-bottom: 0 !important

.doctors-message-area
  padding: 2.6rem 1rem 80px
  @include sm
    padding: 2.6rem
  .message
    padding: 1.6rem
    position: relative
    z-index: 0
    > .note
      background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 97%, #e6d861 97%, #e6d861 100%)
      background-size: 100% 3rem
      line-height: 3rem
      > p
        margin-bottom: 3rem
    @include sm
      padding: 2.6rem
    &::before
      content: ""
      position: absolute
      width: 140px
      height: 140px
      background: center / contain url("#{$url_images}/recipe/img-letter.png") no-repeat
      bottom: -100px
      left: -40px
      z-index: -2
      @include sm
        left: -60px
        bottom: -150px
        width: 200px
        height: 200px
    &::after
      content: ""
      position: absolute
      width: calc(100% + 10px)
      height: calc(100% + 10px)
      border-radius: 6px
      background-color: #fffce6
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      box-shadow: 0px 2px 4px rgba(171,164,164,0.4)
      z-index: -1
      @include sm
        width: calc(100% + 20px)
        height: calc(100% + 20px)
        transform: translate(-50%, -50%) rotate(-1deg)

.baby-food-contents-list
  &::before
    content: "目次"
    display: block
    color: $base-font-color
    font-weight: 700
    background: $color-recipe-bg-gray
    border-bottom: 1px solid $color-recipe-border-gray
    padding: 3px 12px
  > ul
    padding: 6px 12px 6px 0
    display: flex
    flex-flow: wrap
    @include md
      flex-flow: column
    > li
      padding: 3px 0 0 12px
      &::before
        content: "\e600"
        display: inline-block
        font-family: "BabySymbols"
        margin-right: 7px
        color: $color-pink

#contents-list
  margin-bottom: 0
  @include sm
    margin-bottom: 1.6rem

.baby-food-related-list
  &::before
    content: "関連リンク"
    display: block
    color: $base-font-color
    font-weight: 700
    background: $color-recipe-bg-gray
    border-bottom: 1px solid $color-recipe-border-gray
    padding: 3px 12px
  > ul
    padding: 6px 12px 6px 0
    > li
      padding: 3px 0 0 12px
      &::after
        content: "\e602"
        display: inline-block
        font-family: "BabySymbols"
        margin-left: 7px
        color: $color-pink

// 離乳食 デザインパーツ もっと見る

.recipe-more-link
  text-align: right
  margin: 0
  padding: .75rem
  border-top: 1px solid $color-recipe-border-gray
  border-bottom: 1px solid $color-recipe-border-gray
  & + .baby-food-headline-h2
    margin-top: -1px
.m5-6,.m7-8,.m9-11,.y1-y1m6,.template
  & + .baby-food-headline-h2
    margin-top: -1px

// 離乳食 デザインパーツ 追従 検索・クリップボタン

#recipe-floating-btn
  width: 60px
  height: 60px
  position: fixed
  right: -80px
  bottom: 100px
  z-index: 100
  transition: right 300ms ease-in-out
  &.ease-in
    right: 10px
  &.ease-out
    right: -80px
  > button
    cursor: pointer
    font-size: .8rem
    line-height: 1.25
    position: relative
    width: 60px
    height: 60px
    border-radius: 6px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    backdrop-filter: blur(3px)
    box-shadow: 0px 2px 4px rgba(171,164,164,0.4)
    > i
      font-size: 2.8rem
      padding-bottom: .5rem
    &.is-clip
      background: $color-recipe-bg-gray
      border: 1px solid $color-recipe-border-gray
      > i
        color: $color-pink
    &.is-search
      color: $color-white
      background: $color-pink
      background-image: url("#{$url_images}/recipe/icon_search-bgwhite.svg")
      background-size: 90%
      background-repeat: no-repeat
      background-position: center

#recipe-floating-btn,.recipe-clip-btn
  > button
    &.is-clip
      &::after
        color: $color-gray
        content: "クリップする"
      &.is-on
        background-color: $color-palepink
        border-color: $color-palepink
        > i
          color: $color-pink
        &::after
          color: $color-pink
          content: "クリップ済み"
      &.is-off
        &::after
          color: $color-gray
          content: "クリップする"

// 離乳食 デザインパーツ recipe-wrap

.recipe-wrap
  position: relative
  &.new-arrival
    &::after
      content: ""
      display: inline-block
      width: 53px
      height: 33px
      background-repeat: no-repeat
      background-size: contain
      background-image: url("#{$url_images}/recipe/icon_new.png")
      position: absolute
      top: 0
      left: 0
  &.public-relations
    .is-recipe-img-trim
      position: relative
      &::after
        content: "PR"
        display: inline-block
        padding: 6px 10px
        background-color: $color-pink
        color: $color-white
        font-size: 1.2rem
        font-weight: 700
        text-align: center
        line-height: 1
        position: absolute
        top: 0
        right: 0
        opacity: .85
  // レシピタイトル
  .is-recipe-title
    font-weight: 700
    line-height: 1.25
  // レシピイメージ
  .is-recipe-img-trim
    position: relative
    img
      width: 100%
      height: 100% !important
      position: absolute
      top: 50%
      left: 50%
      transform: translate( -50%,-50% )
      object-fit: cover
  // レシピ情報
  .is-recipe-info
    padding: .75rem
    .icon-circle
      font-weight: 700
    span
      font-size: 1.2rem
      white-space: nowrap
      @include sm
        font-size: 1.4rem
      &.month-label
        border-radius: 20px
        font-weight: 700
        padding: 0.15rem 0.5rem 0.25rem
        border: 1px solid
        font-size: 1.1rem
        @include sm
          padding: 0.25rem 0.75rem
          font-size: 1.3rem
  .recipe-nums
    display: flex
    align-items: center
    z-index: 99
    padding: 0
    @include sm
      padding-right: 0.75rem
    .is-item
      display: flex
      align-items: center
      margin-right: 0
      line-height: 1
      @include sm
        margin-right: 1rem
      & + .is-item
        margin-right: .5rem
      i
        top: -1px
        margin-right: 0
        &::before
          color: $color-pink !important
          display: block
          text-align: center
          font-size: 1.4rem
          @include sm
            font-size: 1.8rem
        &::after
          display: block
          bottom: 0
          white-space: nowrap
          letter-spacing: -.175rem
          font-size: .75rem
          position: relative
          @include sm
            bottom: 0
            letter-spacing: -.25rem
            font-size: .9rem
        &.babysymbol-n_recipe
          &::after
            content: "作った！"
        &.babysymbol-clip
          &::after
            left: -1px
            content: "クリップ"
    .cooked-counter,.clip-counter
      color: $color-pink !important
      min-width: 1.3rem
      font-size: 1.3rem
      font-weight: 700
      padding: 0 .25rem
      top: -1px
      position: relative
      @include sm
        padding: 0 .5rem
        font-size: 1.6rem
  .recipe-ingredients
    display: block
    width: 100%
    padding: .5rem
    border: 2px dashed $color-recipe-border-gray
    border-radius: 6px
    font-size: 1.2rem
    @include sm
      padding: .75rem
      font-size: 1.4rem
    > p > span
      display: inline-block
      & + span
        margin-left: 1rem
  // クリップボタン
  button
    cursor: pointer
    &.recipe-clip
      z-index: 99
      span
        font-size: 1.6rem

// 一覧（スライダー、人気順・新着順タブ）
.recipe-swiper
  .is-recipe-info
    min-height: 4.5rem

.recipe-list
  padding: .75rem
  .recipe-wrap
    align-self: stretch
    border: 1px solid $color-recipe-border-gray
    border-radius: 8px
    overflow: hidden
    .is-recipe-title
      font-size: 1.2rem
    .is-recipe-img-trim
      padding-top: 72%
    button
      &.recipe-clip
        position: absolute
        bottom: 51px
        right: 5px
        span
          color: $color-gray
          background: $color-white
          padding: .5rem
          border-radius: 2rem
      &.is-on
        span
          color: $color-white
          background: $color-pink

// レシピトップ スライダー

.recipe-fixed-carousel
  .recipe-list
    padding: .75rem 0
    .recipe-wrap
      border-radius: 0
      @include md
        border-radius: 8px
      .is-recipe-img-trim
        padding-top: 62%
        @include md
          padding-top: 72%
      .label
        position: absolute
        top: 0
        left: 0
        color: $color-white
        font-weight: bold
        width: auto
        display: inline-block
        text-align: center
        padding: 4px 8px
      .is-recipe-info
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        background-color: rgba( 255,255,255,.85)
      .is-recipe-title
        font-size: 1.4rem
        @include md
          font-size: 1.6rem
      .color
        margin-right: .5rem

// 一覧（人気順・新着順タブ）,検索結果、作った！、最近見た

#recipe-list-tab,#recipe-list-static
  .tab-group .tab + .tab::before
    display: none
  .recipe-list
    padding: .75rem 0
    .recipe-wrap
      border: none
      border-bottom: 1px solid $color-recipe-border-gray
      border-radius: 0
      overflow: hidden
      padding: .75rem 0
      .is-recipe-info
        padding: 0 0 .25rem
        flex-wrap: nowrap
      .is-recipe-title
        line-height: 1.5
        font-size: 1.4rem
        margin: .5rem 0
        @include sm
          font-size: 1.6rem
          margin: 1rem 0
      .is-recipe-img-trim
        overflow: hidden
        border-radius: 8px
        padding-top: 100%
        min-height: 110px
        img
          display: block
      button
        &.recipe-clip
          top: calc((100vw / 12) * 4 - 4rem)
          right: auto
          left: calc( ( 100vw / 12 ) * 4 - 4.75rem)
          @include sm
            top: auto
            bottom: 1.6rem
            left: calc((100% / 12) * 3 - 4.25rem)
      > .row
        > .col-xs-8
          padding-left: 0
          @include sm
            padding-left: .75rem

#recipe-list-static
  > .recipe-list
    > .recipe-wrap
      &:first-child
        border-top: 1px solid $color-recipe-border-gray

// レシピ詳細ページ
#recipe-detail-top,.recipe-detail-top
  &.recipe-wrap
    .baby-food-headline-recipe
      border-top: 4px solid $color-pink
    .icon-circle
      display: inline-flex !important
      flex-wrap: nowrap
      white-space: nowrap
      padding: 0 1rem .5rem
      border-radius: 0 0 2rem 0
      font-size: 1.2rem
      @include sm
        font-size: 1.4rem
      &::before
        width: 22px
        height: 24px
        padding-left: 22px
        font-size: 1.4rem
    .is-recipe-title
      font-size: 2rem
      margin: 1rem 1.4rem
      padding: 0
      @include sm
        margin: 1rem 1.6rem
    .is-recipe-info
      display: inline-flex
      flex-flow: row wrap
      align-items: center
      margin: 0 auto 0 1.4rem
      padding-top: 0
      padding-left: 0
      @include sm
        margin: 0 auto 0 1.6rem
      .is-resipe-authr,.is-resipe-date
        // white-space: nowrap
        line-height: 1.1
        font-size: 1.2rem
        padding-bottom: .25rem
        @include sm
          font-size: 1.4rem
      .babysymbol
        &::before
          font-size: 110%
          color: $color-pink
          margin-right: .5rem
          position: relative
          top: 1px
      span
        font-size: inherit
        padding-left: .25rem
    .is-btns
      margin-right: 1.5rem
      position: relative
      @include sm
        top: -.75rem
        margin-right: 2rem
    .recipe-nums-wrap
      z-index: 99
      margin-top: 1.5rem
      text-align: center
      position: absolute
      top: 100%
      right: 50%
      transform: translateX(50%)
      @include sm
        margin-top: 1.5rem
    .recipe-nums
      display: inline-block
      padding: 2px 0 1px
      min-width: 80px
      background-color: $color-recipe-bg-gray
      border-radius: 6px
      box-shadow: 0 2px 2px rgba(102,102,102,0.5)
      position: relative
      margin: 0 auto
      text-align: center
      &::before
        content: ""
        position: absolute
        border: 8px solid transparent
        border-top: 8px solid $color-recipe-bg-gray
        filter: drop-shadow(0 2px 1px rgba(102,102,102,0.5))
        top: 100%
        left: 50%
        transform: translateX(-50%)
      .cooked-counter
        color: $color-gray !important
        font-size: 1.6rem
      i
        color: $color-pink
        font-size: 98%
        top: 0px
    .baby-food-cooked-btn
      display: inline-block
      position: absolute
      left: 50%
      top: calc( 100% + 1rem )
      transform: translateX(-50%)
      button
        &.is-coocked
          position: relative
          display: flex
          flex-wrap: nowrap
          cursor: pointer
          width: 90px
          height: 90px
          padding: 45px
          background-image: url("#{$url_images}/recipe/cooked-btn-off.png")
          background-position: center
          background-size: cover
          &.is-on
            background-image: url("#{$url_images}/recipe/cooked-btn-on.png")
            animation: rotateOn .4s forwards
          &.is-off
            background-image: url("#{$url_images}/recipe/cooked-btn-off.png")
            animation: rotateOff .4s forwards
          @keyframes rotateOn
            0%
              transform: rotateY(0deg)
              background-image: url("#{$url_images}/recipe/cooked-btn-off.png")
            50%
              transform: rotateY(180deg)
              background-image: url("#{$url_images}/recipe/cooked-btn-on.png")
            100%
              transform: rotateY(360deg)
              background-image: url("#{$url_images}/recipe/cooked-btn-on.png")
          @keyframes rotateOff
            0%
              transform: rotateY(0deg)
              background-image: url("#{$url_images}/recipe/cooked-btn-on.png")
            50%
              transform: rotateY(180deg)
              background-image: url("#{$url_images}/recipe/cooked-btn-off.png")
            100%
              transform: rotateY(360deg)
              background-image: url("#{$url_images}/recipe/cooked-btn-off.png")
    .recipe-clip-btn
      display: inline-block
      @include sm
      button
        &.recipe-clip
          padding: 1.4rem 1rem 2.4rem
          position: relative
          color: $color-gray
          min-width: 90px
          .clip-counter
            font-weight: 700
            padding: 0 .5rem
          i
            color: $color-pink
          &::after
            color: $color-gray
            font-size: .8rem
            font-weight: normal
            position: absolute
            bottom: 1px
            left: 50%
            transform: translateX(-50%)
        &.is-clip
          &::after
            content: "クリップ"
          &.is-on
            color: $color-pink
            border-color: $color-recipe-border-gray
            &::after
              content: "クリップ済み"
              color: $color-pink
          &.is-off
            &::after
              content: "クリップ"
    .is-recipe-img-trim
      overflow: hidden
      padding-top: 50%
      height: 240px
      min-height: 240px
      @include md
        height: 480px
        min-height: 480px
.recipe-detail-top
  &.recipe-wrap
    .baby-food-headline-recipe
      .icon-circle
        display: inline-flex
        padding: 0 1.5rem .5rem 1rem
#recipe-detail-bottom, .recipe-detail-bottom
  margin-top: 0 !important
  > .baby-food-headline-h2
    &:first-child
      margin-top: 0
  .baby-food-table-border,.baby-food-list-number
    line-height: 1.75
  .baby-food-table-border
    margin-top: 1.6rem
    td
      text-align: center
  .baby-food-list-number
    margin-top: 0
    > li
      padding: .75rem 0 .75rem 3rem
      border-bottom: 2px dashed $color-recipe-border-gray
      &::before
        top: 1.35rem
  .baby-food-textarea
    &.senmonka
      font-size: 1.4rem
      @include md
        font-size: 1.6rem
// SNS
.article-sns-buttons
  margin-bottom: 1.6rem
  @include sm
    margin-bottom: 0
  &.is-recipe
    display: flex
    align-items: center
    justify-content: space-around
    .sns-button
      display: inline-block
      border-radius: 3px
      width: auto
      min-width: 95px
      height: auto
      margin: 5px
      text-align: center
      padding: 4px 10px
      color: $color-white
      font-weight: 700
      font-size: 1.2rem
      @include sm
        padding: 8px 20px
        font-size: 1.4rem
    .babysymbol
      display: inline-block
      vertical-align: inherit
      &::before
        position: relative
        top: -1px
        font-size: 1.4rem
        margin-right: .5rem
        @include sm
          font-size: 1.6rem

//  動画一覧

.recipe-movie-list,.recipe-movie-list-static
  .movie-wrap
    padding: .75rem
    .row
      margin: 0
    .is-movie-trim
      border: 1px solid $color-recipe-border-gray
      border-radius: 8px
      overflow: hidden
      padding: 0
    .is-movie-title
      font-weight: 700
      line-height: 1.25
      > .babysymbol
        font-size: 175%
        &::before
          position: relative
          top: 3px

.recipe-movie-list-static
  padding-bottom: .75rem
  .movie-wrap
    border-bottom: 1px solid $color-recipe-border-gray
    &:first-child
      border-top: 1px solid $color-recipe-border-gray

// Ｑ＆Ａ、口コミ

.baby-food-kuchikomi,.baby-food-qa
  margin: 1.6rem 0
  .qa-wrap, .kuchikomi-wrap
    border: 2px solid $color-palepink
    border-radius: 8px
    .qa-a, .qa-q, .kuchikomi
      padding: 1.6rem
      .headline
        display: flex
        align-items: center
        font-weight: 700
        padding-right: 80px
        &::before
          align-self: start
          font-family: "BabySymbols"
          font-size: 175%
          font-weight: 400
          color: $color-pink
          margin-right: 1rem
          margin-top: -.15rem
        & + p
          margin-top: .5rem !important
      p + p
          margin-top: .5rem !important
    & + .qa-wrap, & + .kuchikomi-wrap
      margin-top: 1.6rem
  .qa-wrap
    overflow: hidden
    .qa-a
      background: #fff7f7
      position: relative
      .headline
        &::before
          content: "\e605"
      &::after
        content: ""
        display: block
        width: 80px
        height: 80px
        border-radius: 80px
        background: top 5px center / 100% url("https://static.baby-calendar.jp/upload/news/files/kansyuu/kanrieiyou.png")no-repeat
        position: absolute
        right: .5rem
        top: -30px
        border: 2px solid $color-palepink
        @include md
          right: 1rem
    .qa-q
      background: $color-white
      .headline
        &::before
          content: "\e604"
  .kuchikomi-wrap
    margin-bottom: 1.6rem
    .kuchikomi
      background: #fff7f7
      position: relative
      p, .headline
        font-size: 90%
      .headline
        padding-right: 0
      &::after
        content: ""
        display: block
        width: 30px
        height: 30px
        border-radius: 30px
        position: absolute
        right: -5px
        top: -10px
        background: #facbcb 50% / 55% url("#{$url_images}/news/comment/user.svg") no-repeat
        border: 2px solid $color-palepink

// レシピ検索

#modal-baby-food-search,
#modal-baby-food-search-floatingbtn
  .modal-content
    background: $color-recipe-bg-gray
    position: relative
    padding: 15px 10px 0
    overflow-y: auto
  .modal-close
    position: absolute
    right: 5px
  .search-btn
    position: sticky
    bottom: 0
    z-index: 9999
    background: $color-recipe-bg-gray
    width: 100%
    padding: 0 1rem 1.6rem
    margin-top: 1.6rem
    > .baby-food-btn
      cursor: pointer
      width: 100%
      margin: auto
      @include sm
        margin-top: 1.6rem

#accordion-baby-food-search
  > .accordion-item
    cursor: auto
    > .accordion-title
      display: flex
      align-items: center
      justify-content: center
      padding: 0
      background: transparent
      &::before,&::after
        display: none
      .baby-food-headline-search
        display: flex
        align-items: center
        &::after
          content: "\e626"
          font-family: "BabySymbols"
          font-size: 1.6rem
          display: inline-block
          margin-left: .5rem
          color: $color-pink
          transition: all .3s
        .open-text
          display: none
        .close-text
          display: block
    > .accordion-content
      margin: 0
      padding: 0
    &.is-active
      position: relative
      padding-bottom: 3.5rem
      > .accordion-title
        width: 100%
        position: absolute
        bottom: 0
        left: 50%
        transform: translateX(-50%)
        .baby-food-headline-search
          .open-text
            display: block
          .close-text
            display: none
          &::after
            content: "\e603"
            position: relative

.recipe-search-area
  margin: 0
  padding: 0 1.4rem
  background: $color-white
  border: none
  @include md
    padding: 0 1.6rem
  .recipe-search-box
    background: $color-recipe-bg-gray
    border-radius: 8px
    box-shadow: 0 0 4px 2px rgba(152,152,152,0.2)
    .recipe-search-box-inner
      padding: .75rem
      border-radius: 4px
      @include md
        padding: 1rem 1.6rem 1.6rem
  .baby-food-headline-center,.recipe-headline-center
    margin: 0 auto 1rem
  .recipe-headline-center
    padding: 0
    &::after
      display: none
  &.lookup
    .baby-food-headline-center
      color: $color-navy
    .recipe-search-box
      border: 1px solid #f5e5e5
      background: $color-palepink

.recipe-search-field
  .accordion
    border: none
  .select:not(.is-multiple)
    height: auto
  .select select,.input input
    border: 2px solid $color-recipe-border-gray
    height: 37px
  .select::after
    height: 1rem
    width: 1rem
    background-image: none
  .search-detail-container
    background-color: transparent
    border: none
    .baby-food-table-separate
      background-color: $color-white
      border: 1px solid $color-recipe-border-gray
      border-radius: 8px
      overflow: hidden
      padding: 0
      margin-top: .75rem
      @include md
        padding: .5rem
  .baby-food-headline-search, .recipe-headline-search
    text-align: center
    font-weight: 700
    color: $color-pink
    font-size: 1.4rem
    pointer-events: none
    &::before
      display: none
      // width: 18px
      // height: 18px
      content: "\e626"
      font-family: "BabySymbols"
      // background-repeat: no-repeat
      // background-size: contain
      // background-image: url("#{$url_images}/recipe/icon_detail-search-p.svg")
      margin: 0 auto
      margin-right: .5rem
      vertical-align: middle
  .baby-food-table-separate
    margin: 0
    border-spacing: 0
    @include sm
      border-spacing: .5rem
    th,td
      cursor: default
    th
      padding: .5rem .75rem
      font-size: 1.4rem
      line-height: 1.25
      display: block
      width: 100%
      border-top: 1px solid $color-recipe-border-gray !important
      border-bottom: 1px solid $color-recipe-border-gray !important
      background: $color-recipe-bg-gray !important
      border-left: none
      border-right: none
      text-align: left
      @include sm
        display: table-cell
        width: 28%
        border: 1px solid $color-recipe-border-gray !important
        text-align: center
        white-space: nowrap
    td
      padding: .75rem
      display: block
      width: 100%
      border: none
      @include sm
        display: table-cell
        width: 72%
        border-top: 1px solid $color-recipe-border-gray
    tr:first-child
      th
        border-top: none
        @include sm
          border: 1px solid $color-recipe-border-gray
      td
        padding: .5rem 0
        border-top: none
  .baby-food-search-group, .recipe-search-group
    display: flex
    flex-wrap: wrap
    align-items: center
    &.radio,&.checkbox
      label
        cursor: pointer
        font-size: 1.4rem
    &.radio
      justify-content: center
      > li + li
        margin-left: .5rem
    &.checkbox
      margin-bottom: 0
      > li
        min-width: 49%
        padding-right: .75rem
        @include sm
          min-width: 33%
          padding-right: 1.6rem
        > label
          white-space: nowrap
          line-height: 2.4rem
          height: 2.8rem
          &::after
            left: 2px
          &::before
            width: 18px
            height: 18px
            position: static
            transform: none
            margin-right: .25rem
          > p
            margin-left: .25rem
        input:checked + label
          font-weight: 700
      .disabled
        opacity: .5
        > label
          cursor: not-allowed
  .baby-food-btn, .recipe-btn
    cursor: pointer
    width: 100%
    letter-spacing: 1rem
    margin: 1.4rem auto 0
    padding-right: 10px
    transition: all .3s
    @include md
      min-width: 460px
    &:hover
      opacity: .8

// カラー
#baby-food
  .bg
    color: $color-white
    background-color: $color-pink
    &.default
      color: $color-white !important
      background-color: $color-pink !important
  .bg-op
    color: $base-font-color
    background-color: $color-recipe-bg-gray
    border-color: $color-recipe-border-gray
    > span
      color: $color-pink
    &.default
      color: $base-font-color !important
      background-color: $color-recipe-bg-gray !important
      border-color: $color-recipe-bg-gray !important
      > span
        color: $color-pink !important
  .b-color
    border-color: $color-pink
  .color,.fc
    color: $color-pink
    &.default
      color: $color-pink !important
  // 月齢別
  .m5-6
    > .babysymbol::before
      color: $color-recipe-m5-6
    hr.b-color
      border-color: $color-recipe-m5-6-op
    a.link
      &.arrow-right,
      &.arrow-left,
      &.arrow-down,
      &.blank-link
        &::before,&::after
          color: $color-recipe-m5-6
    .bg,> .bg,&.bg,> .bg,.baby-food-headline-h1,> .baby-food-btn,> .baby-food-btn-round
      background-color: $color-recipe-m5-6-op
    .baby-food-headline-h1
      .recipe-num
        color: $color-recipe-m5-6
      .headline-inner
        a::after
          background-color: $color-recipe-m5-6
    .bg-op,> .bg-op,&.bg-op,> .bg-op,.baby-food-headline-h2,.area-headline
      background-color: $color-recipe-m5-6-opp
      border-color: $color-recipe-m5-6-border
    &.bg
      background-color: $color-recipe-m5-6-op !important
    &.bg-op
      background-color: $color-recipe-m5-6-opp !important
    .baby-food-headline-h2
      border-color: $color-recipe-m5-6-border
      span
        color: $color-recipe-m5-6
    .baby-food-headline-h3
      &::before
        background-color: $color-recipe-m5-6
    .color,&.color,> .color,.fc,&.fc,> .fc,.baby-food-headline,.baby-food-headline-center
      color: $color-recipe-m5-6
    .baby-food-headline-center
      &::after
        background-color: $color-recipe-m5-6-op
    .baby-food-2col-headline
      span
        border-color: $color-recipe-m5-6-op
        color: $color-recipe-m5-6-op !important
    .baby-food-headline-recipe
      border-color: $color-recipe-m5-6-op
    > .baby-food-btn-outline
      color: $color-recipe-m5-6
      border-color: $color-recipe-m5-6-op
      &::after
        border-color: $color-recipe-m5-6-op
        background-color: $color-recipe-m5-6-op
      .babysymbol::before
        color: $color-recipe-m5-6
    .baby-food-contents-list,.baby-food-related-list,.baby-food-textarea,.recipe-more-link
      border-color: $color-recipe-m5-6-border
      &::before
        background: $color-recipe-m5-6-opp
        border-color: $color-recipe-m5-6-border
      > ul
        > li
          &::before,&::after
            color: $color-recipe-m5-6
    .baby-food-list-timeline
      > li::before,> li::after
        background-color: $color-recipe-m5-6-op
    ul > li ,ol > li
      &::before
        color: $color-recipe-m5-6
    .baby-food-nav-btn .baby-food-nav-link > li
      border-color: $color-recipe-m5-6-border
      a::after
        color: $color-recipe-m5-6
    .baby-food-table-separate,.baby-food-table-border
      border-color: $color-recipe-m5-6-border
      th
        background-color: $color-recipe-m5-6-opp
        border-color: $color-recipe-m5-6-border
      td
        border-color: $color-recipe-m5-6-border
    .baby-food-guide-table
      border-color: $color-recipe-m5-6-border
      tr
        th,td
          background-color: $color-recipe-m5-6-opp
          border-color: $color-recipe-m5-6-border
        &:nth-child(2n)
          th,td
            background-color: $color-white
    .baby-food-kuchikomi, .baby-food-qa
      .qa-wrap, .kuchikomi-wrap
        border-color: $color-recipe-m5-6-border
        .headline
          &::before
            color: $color-recipe-m5-6-op
        .qa-a
          background: rgb(253,247,241)
          &::after
            border-color: $color-recipe-m5-6-border
        .kuchikomi
          background: rgb(253,247,241)
          &::after
            background: #f7d29f 50% / 55% url("#{$url_images}/news/comment/user.svg") no-repeat
            border: 2px solid $color-recipe-m5-6-opp
  .m7-8
    > .babysymbol::before
      color: $color-recipe-m7-8
    hr.b-color
      border-color: $color-recipe-m7-8-op
    a.link
      &.arrow-right,
      &.arrow-left,
      &.arrow-down,
      &.blank-link
        &::before,&::after
          color: $color-recipe-m7-8
    .bg,> .bg,.baby-food-headline-h1,> .baby-food-btn,> .baby-food-btn-round
      background-color: $color-recipe-m7-8-op
    .baby-food-headline-h1
      .recipe-num
        color: $color-recipe-m7-8
      .headline-inner
        a::after
          background-color: $color-recipe-m7-8
    .bg-op,> .bg-op,&.bg-op,> .bg-op,.baby-food-headline-h2,.area-headline
      background-color: $color-recipe-m7-8-opp
      border-color: $color-recipe-m7-8-border
    .baby-food-headline-h2
      border-color: $color-recipe-m7-8-border
      span
        color: $color-recipe-m7-8
    &.bg
      background-color: $color-recipe-m7-8-op !important
    &.bg-op
      background-color: $color-recipe-m7-8-opp !important
    .baby-food-headline-h3
      &::before
        background-color: $color-recipe-m7-8
    .color,&.color,> .color,.fc,&.fc,> .fc,.baby-food-headline,.baby-food-headline-center
      color: $color-recipe-m7-8
    .baby-food-headline-center
      &::after
        background-color: $color-recipe-m7-8-op
    .baby-food-2col-headline
      span
        border-color: $color-recipe-m7-8-op
        color: $color-recipe-m7-8-op !important
    .baby-food-headline-recipe
      border-color: $color-recipe-m7-8-op
    > .baby-food-btn-outline
      color: $color-recipe-m7-8
      border-color: $color-recipe-m7-8-op
      &::after
        border-color: $color-recipe-m7-8-op
        background-color: $color-recipe-m7-8-op
      .babysymbol::before
        color: $color-recipe-m7-8
    .baby-food-contents-list,.baby-food-related-list,.baby-food-textarea,.recipe-more-link
      border-color: $color-recipe-m7-8-border
      &::before
        background: $color-recipe-m7-8-opp
        border-color: $color-recipe-m7-8-border
      > ul
        > li
          &::before,&::after
            color: $color-recipe-m7-8
    .baby-food-list-timeline
      > li::before,> li::after
        background-color: $color-recipe-m7-8-op
    ul > li ,ol > li
      &::before
        color: $color-recipe-m7-8
    .baby-food-nav-btn .baby-food-nav-link > li
      border-color: $color-recipe-m7-8-border
      a::after
        color: $color-recipe-m7-8
    .baby-food-table-separate,.baby-food-table-border
      border-color: $color-recipe-m7-8-border
      th
        background-color: $color-recipe-m7-8-opp
        border-color: $color-recipe-m7-8-border
      td
        border-color: $color-recipe-m7-8-border
    .baby-food-guide-table
      border-color: $color-recipe-m7-8-border
      tr
        th,td
          background-color: $color-recipe-m7-8-opp
          border-color: $color-recipe-m7-8-border
        &:nth-child(2n)
          th,td
            background-color: $color-white
    .baby-food-kuchikomi, .baby-food-qa
      .qa-wrap, .kuchikomi-wrap
        border-color: $color-recipe-m7-8-border
        .headline
          &::before
            color: $color-recipe-m7-8-op
        .qa-a
          background: rgb(242,250,244)
          &::after
            border-color: $color-recipe-m7-8-border
        .kuchikomi
          background: rgb(242,250,244)
          &::after
            background: #c4efcf 50% / 55% url("#{$url_images}/news/comment/user.svg") no-repeat
            border: 2px solid $color-recipe-m7-8-opp
  .m9-11
    > .babysymbol::before
      color: $color-recipe-m9-11
    hr.b-color
      border-color: $color-recipe-m9-11-op
    a.link
      &.arrow-right,
      &.arrow-left,
      &.arrow-down,
      &.blank-link
        &::before,&::after
          color: $color-recipe-m9-11
    .bg,> .bg,.baby-food-headline-h1,> .baby-food-btn,> .baby-food-btn-round
      background-color: $color-recipe-m9-11-op
    .baby-food-headline-h1
      .recipe-num
        color: $color-recipe-m9-11
      .headline-inner
        a::after
          background-color: $color-recipe-m9-11
    .bg-op,> .bg-op,&.bg-op,> .bg-op,.baby-food-headline-h2,.area-headline
      background-color: $color-recipe-m9-11-opp
      border-color: $color-recipe-m9-11-border
    &.bg
      background-color: $color-recipe-m9-11-op !important
    &.bg-op
      background-color: $color-recipe-m9-11-opp !important
    .baby-food-headline-h2
      border-color: $color-recipe-m9-11-border
      span
        color: $color-recipe-m9-11
    .baby-food-headline-h3
      &::before
        background-color: $color-recipe-m9-11
    .color,&.color,> .color,.fc,&.fc,> .fc,.baby-food-headline,.baby-food-headline-center
      color: $color-recipe-m9-11
    .baby-food-headline-center
      &::after
        background-color: $color-recipe-m9-11-op
    .baby-food-2col-headline
      span
        border-color: $color-recipe-m9-11-op
        color: $color-recipe-m9-11-op !important
    .baby-food-headline-recipe
      border-color: $color-recipe-m9-11-op
    > .baby-food-btn-outline
      color: $color-recipe-m9-11
      border-color: $color-recipe-m9-11-op
      &::after
        border-color: $color-recipe-m9-11-op
        background-color: $color-recipe-m9-11-op
      .babysymbol::before
        color: $color-recipe-m9-11
    .baby-food-contents-list,.baby-food-related-list,.baby-food-textarea,.recipe-more-link
      border-color: $color-recipe-m9-11-border
      &::before
        background: $color-recipe-m9-11-opp
        border-color: $color-recipe-m9-11-border
      > ul
        > li
          &::before,&::after
            color: $color-recipe-m9-11
    .baby-food-list-timeline
      > li::before,> li::after
        background-color: $color-recipe-m9-11-op
    ul > li ,ol > li
      &::before
        color: $color-recipe-m9-11
    .baby-food-nav-btn .baby-food-nav-link > li
      border-color: $color-recipe-m9-11-border
      a::after
        color: $color-recipe-m9-11
    .baby-food-table-separate,.baby-food-table-border
      border-color: $color-recipe-m9-11-border
      th
        background-color: $color-recipe-m9-11-opp
        border-color: $color-recipe-m9-11-border
      td
        border-color: $color-recipe-m9-11-border
    .baby-food-guide-table
      border-color: $color-recipe-m9-11-border
      tr
        th,td
          background-color: $color-recipe-m9-11-opp
          border-color: $color-recipe-m9-11-border
        &:nth-child(2n)
          th,td
            background-color: $color-white
    .baby-food-kuchikomi, .baby-food-qa
      .qa-wrap, .kuchikomi-wrap
        border-color: $color-recipe-m9-11-border
        .headline
          &::before
            color: $color-recipe-m9-11-op
        .qa-a
          background: rgb(255,243,241)
          &::after
            border-color: $color-recipe-m9-11-border
        .kuchikomi
          background: rgb(255,243,241)
          &::after
            background: #fed8d1 50% / 55% url("#{$url_images}/news/comment/user.svg") no-repeat
            border: 2px solid $color-recipe-m9-11-opp

  .y1-y1m6
    > .babysymbol::before
      color: $color-recipe-y1-y1m6
    hr.b-color
      border-color: $color-recipe-y1-y1m6-op
    a.link
      &.arrow-right,
      &.arrow-left,
      &.arrow-down,
      &.blank-link
        &::before,&::after
          color: $color-recipe-y1-y1m6
    .bg,> .bg,.baby-food-headline-h1,> .baby-food-btn,> .baby-food-btn-round
      background-color: $color-recipe-y1-y1m6-op
    .baby-food-headline-h1
      .recipe-num
        color: $color-recipe-y1-y1m6
      .headline-inner
        a::after
          background-color: $color-recipe-y1-y1m6
    .bg-op,> .bg-op,&.bg-op,> .bg-op,.baby-food-headline-h2,.area-headline
      background-color: $color-recipe-y1-y1m6-opp
      border-color: $color-recipe-y1-y1m6-border
    &.bg
      background-color: $color-recipe-y1-y1m6-op !important
    &.bg-op
      background-color: $color-recipe-y1-y1m6-opp !important
    .baby-food-headline-h2
      border-color: $color-recipe-y1-y1m6-border
      span
        color: $color-recipe-y1-y1m6
    .baby-food-headline-h3
      &::before
        background-color: $color-recipe-y1-y1m6
    .color,&.color,> .color,.fc,&.fc,> .fc,.baby-food-headline,.baby-food-headline-center
      color: $color-recipe-y1-y1m6
    .baby-food-headline-center
      &::after
        background-color: $color-recipe-y1-y1m6-op
    .baby-food-2col-headline
      span
        border-color: $color-recipe-y1-y1m6-op
        color: $color-recipe-y1-y1m6-op !important
    .baby-food-headline-recipe
      border-color: $color-recipe-y1-y1m6-op
    > .baby-food-btn-outline
      color: $color-recipe-y1-y1m6
      border-color: $color-recipe-y1-y1m6-op
      &::after
        border-color: $color-recipe-y1-y1m6-op
        background-color: $color-recipe-y1-y1m6-op
      .babysymbol::before
        color: $color-recipe-y1-y1m6
    .baby-food-contents-list,.baby-food-related-list,.baby-food-textarea,.recipe-more-link
      border-color: $color-recipe-y1-y1m6-border
      &::before
        background: $color-recipe-y1-y1m6-opp
        border-color: $color-recipe-y1-y1m6-border
      > ul
        > li
          &::before,&::after
            color: $color-recipe-y1-y1m6
    .baby-food-list-timeline
      > li::before,> li::after
        background-color: $color-recipe-y1-y1m6-op
    ul > li ,ol > li
      &::before
        color: $color-recipe-y1-y1m6
    .baby-food-nav-btn .baby-food-nav-link > li
      border-color: $color-recipe-y1-y1m6-border
      a::after
        color: $color-recipe-y1-y1m6
    .baby-food-table-separate,.baby-food-table-border
      border-color: $color-recipe-y1-y1m6-border
      th
        background-color: $color-recipe-y1-y1m6-opp
        border-color: $color-recipe-y1-y1m6-border
      td
        border-color: $color-recipe-y1-y1m6-border
    .baby-food-guide-table
      border-color: $color-recipe-y1-y1m6-border
      tr
        th,td
          background-color: $color-recipe-y1-y1m6-opp
          border-color: $color-recipe-y1-y1m6-border
        &:nth-child(2n)
          th,td
            background-color: $color-white
    .baby-food-kuchikomi, .baby-food-qa
      .qa-wrap, .kuchikomi-wrap
        border-color: $color-recipe-y1-y1m6-border
        .headline
          &::before
            color: $color-recipe-y1-y1m6-op
        .qa-a
          background: rgb(242,248,250)
          &::after
            border-color: $color-recipe-y1-y1m6-border
        .kuchikomi
          background: rgb(242,248,250)
          &::after
            background: #bddee6 50% / 55% url("#{$url_images}/news/comment/user.svg") no-repeat
            border: 2px solid $color-recipe-y1-y1m6-opp

// アイコン
#baby-food
  // 共通
  .icon,.icon-circle
    display: flex
    // position: relative
    align-items: center
    &::before
      content: ""
      display: inline-block
      width: 32px
      height: 30px
      background-repeat: no-repeat
      background-size: contain
      background-position: left center
      margin-right: .5rem
      position: relative
      padding-left: 32px
    &.sm
      &::before
        width: 22px
        height: 20px
        padding-left: 22px
  .icon-circle
    &::before
      background-image: url("#{$url_images}/recipe/icon-circle_baby-food.svg")

  // 月齢別アイコン
  .m5-6
    .icon,&.icon
      &::before
        background-image: url("#{$url_images}/recipe/icon_m5-6.svg")
    .icon-circle,&.icon-circle
      &::before
        background-image: url("#{$url_images}/recipe/icon-circle_m5-6.svg")
    .arrow
      &::after
        background-image: url("#{$url_images}/recipe/arrow_m5-6.svg")
    .baby-food-headline-h1
      .icon-circle,.icon
        &::before
          background-image: url("#{$url_images}/recipe/icon-circle_m5-6.svg")
  .m7-8
    .icon,&.icon
      &::before
        background-image: url("#{$url_images}/recipe/icon_m7-8.svg")
    .icon-circle,&.icon-circle
      &::before
        background-image: url("#{$url_images}/recipe/icon-circle_m7-8.svg")
    .arrow
      &::after
        background-image: url("#{$url_images}/recipe/arrow_m7-8.svg")
    .baby-food-headline-h1
      .icon-circle,.icon
        &::before
          background-image: url("#{$url_images}/recipe/icon-circle_m7-8.svg")
  .m9-11
    .icon,&.icon
      &::before
        background-image: url("#{$url_images}/recipe/icon_m9-11.svg")
    .icon-circle,&.icon-circle
      &::before
        background-image: url("#{$url_images}/recipe/icon-circle_m9-11.svg")
    .arrow
      &::after
        background-image: url("#{$url_images}/recipe/arrow_m9-11.svg")
    .baby-food-headline-h1
      .icon-circle,.icon
        &::before
          background-image: url("#{$url_images}/recipe/icon-circle_m9-11.svg")
  .y1-y1m6
    .icon,&.icon
      &::before
        background-image: url("#{$url_images}/recipe/icon_y1-y1m6.svg")
    .icon-circle,&.icon-circle
      &::before
        background-image: url("#{$url_images}/recipe/icon-circle_y1-y1m6.svg")
    .arrow
      &::after
        background-image: url("#{$url_images}/recipe/arrow_y1-y1m6.svg")
    .baby-food-headline-h1
      .icon-circle,.icon
        &::before
          background-image: url("#{$url_images}/recipe/icon-circle_y1-y1m6.svg")

  // 丸アイコン
  .icon-circle
    // 月齢
    &.default
      &::before
        background-image: url("#{$url_images}/recipe/icon-circle_baby-food.svg")
    &.m5-6
      &::before
        background-image: url("#{$url_images}/recipe/icon-circle_m5-6.svg")
    &.m7-8
      &::before
        background-image: url("#{$url_images}/recipe/icon-circle_m7-8.svg")
    &.m9-11
      &::before
        background-image: url("#{$url_images}/recipe/icon-circle_m9-11.svg")
    &.y1-y1m6
      &::before
        background-image: url("#{$url_images}/recipe/icon-circle_y1-y1m6.svg")

  // アイコン
  .icon
    // 月齢 透過
    &.baby-food-default
      &::before
        background-image: url("#{$url_images}/recipe/icon_default.svg")
    &.m5-6
      &::before
        background-image: url("#{$url_images}/recipe/icon_m5-6.svg")
    &.m7-8
      &::before
        background-image: url("#{$url_images}/recipe/icon_m7-8.svg")
    &.m9-11
      &::before
        background-image: url("#{$url_images}/recipe/icon_m9-11.svg")
    &.y1-y1m6
      &::before
        background-image: url("#{$url_images}/recipe/icon_y1-y1m6.svg")
    // 食材（カラー含む）
    &.staple // 炭水化物 主食
      &.color,> span
        color: $color-recipe-m5-6!important
      &.b-color
        border-color: $color-recipe-m5-6-op!important
      &::before
        background-image: url("#{$url_images}/recipe/icon-staple.svg")
      &::after
        border-color: $color-recipe-m5-6-op!important
        background-color: $color-recipe-m5-6-op!important
        background-image: url("#{$url_images}/recipe/arrow_m5-6.svg")!important
    &.snack // おやつ
      &.color,> span
        color: $color-recipe-m5-6!important
      &.b-color
        border-color: $color-recipe-m5-6-op!important
      &::before
        background-image: url("#{$url_images}/recipe/icon-snack.svg")
      &::after
        border-color: $color-recipe-m5-6-op!important
        background-color: $color-recipe-m5-6-op!important
        background-image: url("#{$url_images}/recipe/arrow_m5-6.svg")!important
    &.side-dish // ビタミン・ミネラル類 副菜
      &.color,> span
        color: $color-recipe-m7-8!important
      &.b-color
        border-color: $color-recipe-m7-8-op!important
      &::before
        background-image: url("#{$url_images}/recipe/icon-side-dish.svg")
      &::after
        border-color: $color-recipe-m7-8-op!important
        background-color: $color-recipe-m7-8-op!important
        background-image: url("#{$url_images}/recipe/arrow_m7-8.svg")!important
    &.all-dishes // すべて・そのほかの食品
      &.color,> span
        color: $color-recipe-m9-11!important
      &.b-color
        border-color: $color-recipe-m9-11-op!important
      &::before
        background-image: url("#{$url_images}/recipe/icon-all-dishes.svg")
      &::after
        border-color: $color-recipe-m9-11-op!important
        background-color: $color-recipe-m9-11-op!important
        background-image: url("#{$url_images}/recipe/arrow_m9-11.svg")!important
    &.main-dish // たんぱく質類 主菜
      &.color,> span
        color: $color-recipe-m9-11!important
      &.b-color
        border-color: $color-recipe-m9-11-op!important
      &::before
        background-image: url("#{$url_images}/recipe/icon-main-dish.svg")
      &::after
        border-color: $color-recipe-m9-11-op!important
        background-color: $color-recipe-m9-11-op!important
        background-image: url("#{$url_images}/recipe/arrow_m9-11.svg")!important
    &.soup // 汁物
      &.color,> span
        color: $color-recipe-y1-y1m6!important
      &.b-color
        border-color: $color-recipe-y1-y1m6-op!important
      &::before
        background-image: url("#{$url_images}/recipe/icon-soup.svg")
      &::after
        border-color: $color-recipe-y1-y1m6-op!important
        background-color: $color-recipe-y1-y1m6-op!important
        background-image: url("#{$url_images}/recipe/arrow_y1-y1m6.svg")!important
    &.ingredients-01 // 米・パン類
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-01.jpg")
    &.ingredients-02 // めん類
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-02.jpg")
    &.ingredients-03 // そのほかの穀類
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-03.jpg")
    &.ingredients-04 // 大豆・大豆製品・豆類
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-04.jpg")
    &.ingredients-05 // 卵・うづら卵
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-05.jpg")
    &.ingredients-06 // 白身魚
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-06.jpg")
    &.ingredients-07 // 赤身魚
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-07.jpg")
    &.ingredients-08 // 青背魚
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-08.jpg")
    &.ingredients-09 // そのほかの魚介類
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-09.jpg")
    &.ingredients-10 // 魚加工品
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-10.jpg")
    &.ingredients-11 // 乳製品
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-11.jpg")
    &.ingredients-12 // 肉類
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-12.jpg")
    &.ingredients-13 // 肉加工品
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-13.jpg")
    &.ingredients-14 // 野菜類
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-14.jpg")
    &.ingredients-15 // 果物類
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-15.jpg")
    &.ingredients-16 // きのこ・海藻類
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-16.jpg")
    &.ingredients-17 // そのほかの食品
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-17.jpg")
    &.ingredients-19 // 調味料
      &::before
        background-image: url("#{$url_images}/recipe/icon-ingredients-19.jpg")
  .ingredients-list
    li
      &:first-child
        &.icon
          &::before
            display: inline-block
            height: 24px
            width: 24px
            padding-left: 24px
            top: 0
            margin-right: .5rem
    .icon
      font-weight: 700
      &::before
        border-radius: 10px
        height: 45px
        width: 45px
        padding-left: 45px
        @include md
          height: 60px
          width: 60px
          padding-left: 60px
  .ingredients-btn
    .icon
      flex-wrap: wrap
      &::before
        margin: .75rem auto
        display: block
        width: 100%
        background-position: center
  .type-month-anchor-link
    .icon
      flex-direction: column
      &::before
        content: ""
        display: block
        width: 30px
        height: 30px
        margin: 0 auto 10px
  .type-month-btn
    .baby-food-btn-round.icon-circle
      &::before
        content: ""
        position: absolute
        width: 28px
        height: 26px
        left: 10px
        top: 50%
        transform: translateY(-50%)
        @include sm
          width: 32px
          height: 30px
          left: 15px
    > li
      &.m5-6 > .baby-food-btn-round.icon-circle::before
        background-image: url("#{$url_images}/recipe/icon-circle_m5-6.svg")
      &.m7-8 > .baby-food-btn-round.icon-circle::before
        background-image: url("#{$url_images}/recipe/icon-circle_m7-8.svg")
      &.m9-11 > .baby-food-btn-round.icon-circle::before
        background-image: url("#{$url_images}/recipe/icon-circle_m9-11.svg")
      &.y1-y1m6 > .baby-food-btn-round.icon-circle::before
        background-image: url("#{$url_images}/recipe/icon-circle_y1-y1m6.svg")
  .recipe-fixed-carousel
    .icon-circle
      &::before
        width: 24px
        height: 22px
        padding-left: 24px
  #recipe-list-tab,#recipe-list-static
    .icon-circle
      &::before
        width: 24px
        height: 22px
        padding-left: 24px
        margin-right: .25rem
    .recipe-list,.recipe-wrap
      &.m5-6
        .icon-circle::before
          background-image: url("#{$url_images}/recipe/icon-circle_m5-6.svg")
      &.m7-8
        .icon-circle::before
          background-image: url("#{$url_images}/recipe/icon-circle_m7-8.svg")
      &.m9-11
        .icon-circle::before
          background-image: url("#{$url_images}/recipe/icon-circle_m9-11.svg")
      &.y1-y1m6
        .icon-circle::before
          background-image: url("#{$url_images}/recipe/icon-circle_y1-y1m6.svg")

// ナビアイコン
#baby-food,#baby-food-nav,#baby-food-contentslist
  .baby-food-nav-link
    > li
      &::before,a::before
        content: ''
        background-repeat: no-repeat
        background-size: contain
        background-position: left center
        display: inline-block
        position: relative
      &.food-article a,&.food-article
        &::before
          background-image: url("#{$url_images}/recipe/icon_food-article.svg")
      &.recipe a,&.recipe
        &::before
          background-image: url("#{$url_images}/recipe/icon_recipe.svg")
      &.clip a,&.clip
        &::before
          background-image: url("#{$url_images}/recipe/icon_clip.svg")
      &.guide a,&.guide
        &::before
          background-image: url("#{$url_images}/recipe/icon_guide.svg")
      &.tips a,&.tips
        &::before
          background-image: url("#{$url_images}/recipe/icon_tips.svg")
      &.safety-standard a,&.safety-standard
        &::before
          background-image: url("#{$url_images}/recipe/icon_safety-stanfard.svg")
      &.doctor a,&.doctor
        &::before
          background-image: url("#{$url_images}/recipe/icon_tsutsumi.png")
      &.books a,&.books
        &::before
          background-image: url("#{$url_images}/recipe/icon_book.png")
      &.qanda a,&.qanda
        &::before
          background-image: url("#{$url_images}/recipe/icon_qanda.svg")

#baby-food,#baby-food-nav
  .baby-food-nav-link
    > li
      &::before
        width: 24px
        height: 24px
        padding-left: 24px
        top: 0
        @include sm
          margin-right: 8px
      > .nav-inner
        > li
          .icon::before
            width: 18px
            height: 18px
            padding-left: 18px
            margin-right: .5rem
            top: 3px
          &.m5-6
            .icon::before
              background-image: url("#{$url_images}/recipe/icon_m5-6.svg")
          &.m7-8
            .icon::before
              background-image: url("#{$url_images}/recipe/icon_m7-8.svg")
          &.m9-11
            .icon::before
              background-image: url("#{$url_images}/recipe/icon_m9-11.svg")
          &.y1-y1m6
            .icon::before
              background-image: url("#{$url_images}/recipe/icon_y1-y1m6.svg")
#baby-food-nav
  .baby-food-nav-link
    > li
      &::before
        display: none
        @include md
          display: inline-block
#baby-food-contentslist
  .baby-food-nav-link
    > li
      &::before
        display: none
      > a
        &::before
          width: 24px
          height: 24px
          padding-left: 24px
          top: 0
          margin-right: .5rem
      > ul
        > li
          a::before
            background-image: none !important

.right-angle-symbol
  display: flex
  align-items: center
  &::before
    content: ""
    width: 20px
    height: 20px
    padding-left: 20px
    margin-right: .5rem
    background-image: url("#{$url_images}/recipe/icon_right-angle-symbol.svg")

// 離乳食 デザインパーツ コンテンツ内で幅いっぱいにしたいもの
#baby-food
  .full-width,
  .baby-food-headline-h1,
  .baby-food-headline-h2,
  .baby-food-headline-recipe,
  .baby-food-tab > .tab-group,
  .recipe-swiper
    width: 100vw
    box-sizing: border-box
    margin-left: -1.5rem
    margin-right: -1.5rem
    @include sm
      width: calc( 100% + 3.2rem )
      margin-left: -1.6rem
      margin-right: -1.6rem

/////////////////////////////
//        ページ別
/////////////////////////////
// 02.離乳食の進め方
// 見出し：大きさや形状の変化
.top-size-area
  border: none
  padding: 0
  overflow: visible
  @include md
    border: 1px solid $color-recipe-border-gray
    padding: 1.6rem
  .top-size
    margin: 0 -1.6rem
    @include md
      margin: 0
    .top-size-content

      display: flex
      flex-direction: column
      justify-content: space-between
      padding: 2rem 1.6rem 2rem calc( 127px + 2rem )
      position: relative
      @include sm
        padding: 4rem 1.6rem 4rem calc( 104.5px + 2rem )
        min-height: 760px
      @include md
        padding: 4rem 1.6rem 4rem calc( 157px + 2rem - 1.6rem )
        min-height: 500px
      &::before
        content: ""
        background-image: url("#{$url_images}/recipe/img_top-size.jpg")
        background-position: left top
        background-size: contain
        background-repeat: no-repeat
        display: block
        width: 127px
        height: 100%
        position: absolute
        left: 0
        top: 0
        @include sm
          background-position: left center
          width: 104.5px
        @include md
          background-image: url("#{$url_images}/recipe/img_top-size-pc.jpg")
          margin-left: 0
          width: 157px
          left: -1.6rem

// タブ内見出し画像
.top-heading-img
  border-radius: 8px

// 37.離乳食レシピ
// レシピトップ スライダー（カルーセル）
.recipe-fixed-carousel
  margin: 0 -.75rem
  @include sm
    margin: 0 -1.6rem
  > .swiper-wrapper
    width: 100%
  .recipe-fixed-carousel-pagination
    text-align: center
    padding-bottom: .75rem
    .swiper-pagination-bullet.swiper-pagination-bullet-active
      background: $color-pink

// 38,41-43.時期別レシピ・作り方
// 人気順・新着順タブ上見出し
.recipe-num-wrap
  margin: 1.6rem -.75rem
  display: flex
  align-items: center
  justify-content: space-between
  flex-wrap: nowrap
  .recipe-num-wrap-text
    font-weight: 700
    text-align: center
    line-height: 1.25
    > span
      font-size: 2.2rem
      color: $color-pink
  .button.is-primary.is-active
    pointer-events: auto

// レシピ一覧 人気順・新着順タブ

#recipe-list-tab
  > .panel-group > .panel
    overflow: visible

// 55.離乳食レシピ（レシピ詳細）
// 下部共通パーツ

// レシピ用クリップ通知 リンク入れる
#clip_notification_recipe
  &.is-on
    .notification-box
      position: relative
      height: 100%
      width: 100%
      padding: 1.6rem
      text-align: center
      a
        display: inline
        white-space: nowrap
        color: $color-pink
        position: absolute
        bottom: 1rem
        left: 50%
        transform: translateX(-50%)
  &.is-off
    .notification-box
      display: none

// 50.離乳食のきほん
.movie-play-wrap
  position: relative
  padding-bottom: 57%
  height: 0
  overflow: hidden
  > .movie_playbtn
    cursor: pointer
    transition: all .3s
    .btn
      position: absolute
      top: 50%
      left: 49%
      transform: translate( -50%, -49% )
      max-width: 120px
      transition: all 0.2s ease
      border-radius: 50%
  > iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
.howto-wrap
  .baby-food-headline-center,.baby-food-headline
    margin-bottom: 1rem
    // margin-top: 1rem
  img
    border-radius: 8px
  .is-height
    > .baby-food-headline
      @include sm
        height: 55px
