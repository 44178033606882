// size

$size-1: 2rem !default
$size-2: 1.8rem !default
$size-3: 1.6rem !default
$size-4: 1.4rem !default
$size-5: 1.2rem !default
$size-6: 1rem !default

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 !default

// fw

$weight-normal: 400 !default
$weight-bold: 700 !default

// color

$color-white: #ffffff
$color-palegray: #f3f3f3
$color-supergray: #E5E1DE
$color-gray: #999999

$color-pink: #eb748d
$color-green: #6BC3C4
$color-palegreen: #e9f6f6
$color-blue: #075b98
$color-red: #d22407

$color-palepink: #FFEFEF
$color-beige: #F5F2EF

$color-deeppink: #dc657f

$color-navy: #3E3B6F
$color-yellow: #FFD050

$color-facebook: #2E77F3 !important
$color-line: #00B900 !important
$color-twitter: #6AABE9 !important

// color-knowledge
$color-knowledge-pink: #FFF1F0
$color-knowledge-orange: #FFF1DD
$color-knowledge-yellow: #FDF8CE

// color-news
$color-news-talk-blue: #88A5D0
$color-news-talk-red: #CB8C8D

// color-album
$color-album-beige: #F9F5F4
$color-album-pink: #FBB9B9

// color-recipe
$color-recipe-bg-gray: #fcfbfa
$color-recipe-border-gray: #eeeeee
$color-recipe-gray: #e3dfdf
$color-recipe-m5-6: #d59f56
$color-recipe-m5-6-op: #EDB160
$color-recipe-m5-6-opp: rgba(237, 177, 96, 0.1)
$color-recipe-m5-6-border: rgba(237, 177, 96, 0.2)
$color-recipe-m7-8: #6fb683
$color-recipe-m7-8-op: #7bcb92
$color-recipe-m7-8-opp: rgba(123, 203, 146, 0.1)
$color-recipe-m7-8-border: rgba(123, 203, 146, 0.2)
$color-recipe-m9-11: #e07962
$color-recipe-m9-11-op: #F9876D
$color-recipe-m9-11-opp: rgba(249, 135, 109, 0.1)
$color-recipe-m9-11-border: rgba(249, 135, 109, 0.2)
$color-recipe-y1-y1m6: #62a5b7
$color-recipe-y1-y1m6-op: #6DB8CC
$color-recipe-y1-y1m6-opp: rgba(109, 184, 204, 0.1)
$color-recipe-y1-y1m6-border: rgba(109, 184, 204, 0.2)

// boder-radius

$radius-small: 2px !default
$radius: 4px !default
$radius-large: 6px !default
$radius-rounded: 290486px !default

// media query
// @include lg or tmd or sm
// lgいらないかもなあ

$lg: 1200px
$md: 960px
$sm: 560px

@mixin lg
  @media screen and (min-width: ($lg))
    @content

@mixin md
  @media screen and (min-width: ($md))
    @content

@mixin sm
  @media screen and (min-width: ($sm))
    @content

@mixin smmd
  @media screen and (min-width: ($sm)) and (max-width:959px)
    @content

@mixin -sm
  @media screen  and (max-width:($sm))
    @content

// base

$base-font-sans: "Helvetica Neue",Arial,-apple-system,'Noto Sans JP',"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif
$base-font-serif: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "Times New Roman", "游明朝", YuMincho, "メイリオ", Meiryo, Georgia, serif

$base-font-color: #666666
$base-font-size: $size-4
$base-font-weight: $weight-normal
$base-line-height: 1.6
