/* clearfix
 * -----------------------------------------------*/

.clearfix
  zoom: 1
  &:after
    content: ''
    display: table
    clear: both


// overflow-hidden

.ovf-hidden
  overflow: hidden
  &.is-rounded
    border-radius: 290486px



// 960px以上は表示
.md-show
  display: none
  @include md
    display: block

.md-hide
  display: block
  @include md
    display: none

// 560px以上は表示
.sm-show
  display: none
  @include sm
    display: block

.sm-hide
  display: block
  @include sm
    display: none


// position
.pos-r
  position: relative

// hidden

.is-hidden
  display: none !important


// border-radius

.has-rounded
  border-radius: $radius-rounded

// display

.display-ib
  display: inline-block
.display-b
  display: block
.display-i
  display: inline



/* font
 * -----------------------------------------------*/

.ft-serif
  font-family: $base-font-serif

.ft-mon
  font-family: 'Montserrat', sans-serif


/* text^align
 * -----------------------------------------------*/

.text-left
  text-align: left

.text-center
  text-align: center

.text-right
  text-align: right

.text-justify
  text-align: justify

// text-

.text-elps
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.text-du
  text-decoration: underline
  padding: 0 2px


// letter-spacing

.ls-02
  letter-spacing: -0.02em


/* color
 * -----------------------------------------------*/

.c-pink
  color: $color-pink

.c-green
  color: $color-green

.c-blue
  color: $color-blue

.c-navy
  color: $color-navy

.c-red
  color: $color-red

.c-yellow
  color: $color-yellow

.c-beige
  color: $color-beige

.c-gray
  color: $color-gray

.c-palegray
  color: $color-palegray

.c-white
  color: $color-white

.c-album-pink
  color: $color-album-pink

/* background-color
 * -----------------------------------------------*/

.bgc-pink
  background-color: $color-pink

.bgc-green
  background-color: $color-green

.bgc-blue
  background-color: $color-blue

.bgc-red
  background-color: $color-red

.bgc-yellow
  background-color: $color-yellow

.bgc-navy
  background-color: $color-navy

.bgc-beige
  background-color: $color-beige

.bgc-gray
  background-color: $color-gray

.bgc-palegray
  background-color: $color-palegray

.bgc-white
  background-color: #ffffff

.bgc-palepink
  background-color: $color-palepink

.bgc-facebook
  background-color: $color-facebook
.bgc-line
  background-color: $color-line
.bgc-twitter
  background-color: $color-twitter


/* fontsize
 * -----------------------------------------------*/

.fz-i
  font-size: inherit !important

.fz-sm
  font-size: $size-5

.fz-bg
  font-size: $size-2

/* font-weight
 * -----------------------------------------------*/

.fw-bold
  font-weight: bold


/* margin
 * -----------------------------------------------*/

$spaceamounts: (5, 10, 20, 30, 40, 50) // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, right,left) // Leave this variable alone

@each $space in $spaceamounts
  @each $side in $sides
    .m#{str-slice($side, 0, 1)}#{$space}
      @if $space == 0
        margin-#{$side}: #{$space} !important
      @else
        margin-#{$side}: #{$space}px !important

.mb0
  margin-bottom: 0 !important
.mt0
  margin-top: 0 !important

.m0
  margin: 0 !important

.mt-lg-5
  @include lg
    margin-top: 5px

.mt-md-5
  @include lg
    margin-top: 5px

.ml-auto
  margin-left: auto

.mr-auto
  margin-right: auto

/* padding
  * -----------------------------------------------*/

.p0
  padding: 0 !important



/* gutter on off
 * -----------------------------------------------*/

.has-gutter
  margin-right: $half-gutter-width
  margin-left: $half-gutter-width
  @include md
    margin-right:  $gutter-compensation
    margin-left: $gutter-compensation
    // margin-right: 0
    // margin-left: 0

.gutter-mtb
  padding-top: $gutter-width
  padding-bottom: $gutter-width

.gutter-mtb-half
  padding-top: $half-gutter-width
  padding-bottom: $half-gutter-width

.gutter-around
  padding: $gutter-width $half-gutter-width
  // margin-right: 0
  // margin-left: 0

.gutter-around-half
  padding: $half-gutter-width

.no-gutter
  padding: 0 !important
  overflow: hidden

/* flex
 * -----------------------------------------------*/

.flex-between
  +justify-content(space-between)

.flex-center
  +justify-content(center)

.flex-align-center
  +align-items(center)

.flex-align-end
  +align-items(flex-end)


/* img
 * -----------------------------------------------*/

img
  &.is-rounded
    border-radius: $radius-rounded
  &.is-maxwidth
    width: auto
    max-width: 100%
    max-height: 600px
  &.is-obf-c
    object-fit: cover
  &.is-100
    max-height: 100px
    width: 100%
    height: 100%
  &.is-w100
    max-width: 100%
  &.is-border-g
    border: 1px solid $color-palegray

  &.is-m300
    max-width: 300px
    max-height: 300px


/* 枠
 * -----------------------------------------------*/
//　ボーダー
.box-border
  padding: $half-gutter-width
  border: 2px solid $color-palegray
  border-radius: $radius-large
  margin: $half-gutter-width
  &.is-pink
    border-color: $color-palepink
    background-color: #fff7f7
  &.is-white
    background: $color-white
    border-color: $color-white
  &.is-gray
    background: $color-palegray
  &.is-border-ly
    border-left: 2px solid $color-yellow
  &.is-danger
    background: rgba($color-red, 0.1)
    border-color: rgba($color-red, 0.2)
    color: $color-red
    .title
      margin-bottom: $half-gutter-width
  &.is-success
    background: rgba($color-blue, 0.1)
    border-color: rgba($color-blue, 0.2)
    color: $color-blue
    .title
      margin-bottom: $half-gutter-width
  &.is-float
    background: $color-white
    border-color: $color-white
    box-shadow: 0 0 4px rgba(235,120,140,15%)
  &.is-bcpink
    border-color: $color-pink
    background-color: #fff7f7

/* ラベル　専門家と共通
 * -----------------------------------------------*/

.label-round
  border-radius: $radius-rounded
  background: $color-white
  display: inline-block
  font-weight: bold
  padding: 2px 10px
  font-size: $size-5
  text-align: left
  &.is-palepink
    background-color: $color-palepink
    color: $color-pink
  &.is-pink
    background-color: $color-pink
    color: $color-white
  &.is-gray
    background-color: $color-gray
    color: $color-white
  &.is-medium
    font-size: $size-4
  &.is-small
    font-size: $size-5
  &.is-palegreen
    background-color: $color-palegreen
  &.is-palepink2
    background-color: $color-palepink
  &.is-new
    font-size: $size-6
    padding: 1px 4px

/* 複数行の末尾省略
 * -----------------------------------------------*/

.line-clamp
  display: -webkit-box
  overflow: hidden
  -webkit-box-orient: vertical
  overflow: hidden
  &.is-3
    -webkit-line-clamp: 3
  &.is-2
    -webkit-line-clamp: 2
  &.is-1
    -webkit-line-clamp: 1
