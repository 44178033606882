@charset "utf-8"
@import local

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Montserrat|Noto+Sans+JP:400,700&display=optional&subset=japanese')

/* base
 * -----------------------------------------------*/
@import _base/HTML5reset-1.6.1.css
@import _base/flexboxgrid
@import _base/_variables
@import _base/_base


/* modules
 * -----------------------------------------------*/
@import _modules/_modules
@import _modules/_button
@import _modules/_title
@import _modules/_lists
@import _modules/_table
@import _modules/_lcp
@import _modules/balloon

/* form
 * -----------------------------------------------*/
@import _form/_select
@import _form/_help


/* font
 * -----------------------------------------------*/
@import _font/icon_babysymbols


/* parts
 * -----------------------------------------------*/
@import _parts/_header
@import _parts/_header-present
@import _parts/_header-bana
@import _parts/_contents
@import _parts/_side
@import _parts/_footer
@import _parts/_gnav
@import _parts/_breadcrumb
@import _parts/_totop
@import _parts/_clip-detail
@import _parts/_floating_btn-toapp


/* pluginedit
 * -----------------------------------------------*/
@import _pluginedit/_modal
@import _pluginedit/_clip
@import _pluginedit/_accordion


/* pages
 * -----------------------------------------------*/
@import _ss/_ss
@import _ss/_ss-lp

@import _knowledge/_knowledge
@import _news/_news
@import _news/_ballot
@import _recipe/_recipe

/* common
 * -----------------------------------------------*/

@import _common/_article
@import _common/_article-stylecss
@import _common/_pager

/* plugin
 * -----------------------------------------------*/
@import ~swiper/swiper-bundle.css
