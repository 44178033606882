
// クリップ

.button.is-clip
  &.is-on .babysymbol
    -webkit-animation: clip_iconAnime 800ms
    animation: clip_iconAnime 800ms
    -webkit-animation-fill-mode: forwards
    animation-fill-mode: forwards

/* クリップ通知

#clip_notification,
#clip_notification_recipe
  opacity: 0
  position: fixed
  top: 30%
  left: 50%
  margin-left: -80px
  z-index: -9999

#clip_notification
  width: 150px
  height: 150px
  background-repeat: no-repeat
  background-size: contain
  &.is-on
    -webkit-animation: fadeinAnime 1.6s
    animation: fadeinAnime 1.6s
    -webkit-animation-fill-mode: forwards
    animation-fill-mode: forwards
    background-image: url("#{$url_images}/clip/popup_clipped.png")
  &.is-off
    -webkit-animation: fadeoffAnime 1.6s
    animation: fadeoffAnime 1.6s
    -webkit-animation-fill-mode: forwards
    animation-fill-mode: forwards
    background-image: url("#{$url_images}/clip/popup_unclipped.png")

/* レシピ用クリップ通知

#clip_notification_recipe
  background-repeat: no-repeat
  background-size: contain
  &.is-on
    width: 150px
    height: 170px
    -webkit-animation: fadeinAnimeRecipe 3.2s
    animation: fadeinAnimeRecipe 3.2s
    -webkit-animation-fill-mode: forwards
    animation-fill-mode: forwards
    background-image: url("#{$url_images}/clip/popup_clipped-recipe.png")
  &.is-off
    width: 150px
    height: 150px
    -webkit-animation: fadeoffAnime 1.6s
    animation: fadeoffAnime 1.6s
    -webkit-animation-fill-mode: forwards
    animation-fill-mode: forwards
    background-image: url("#{$url_images}/clip/popup_unclipped.png")

/* いいね

// いいね
.btn-iine
  font-size: $size-5
  cursor: pointer
  color: $color-pink
  display: inline-flex
  border: 1px solid $color-pink
  border-radius: $radius-large
  .is-text,.is-num
    line-height: $base-line-height
    display: inline-flex
    height: 2.5em
    align-items: center
    justify-content: center
    padding-left: $size-1
    padding-right: $size-1

  .is-text
    border-radius: $radius 0 0 $radius
    position: relative
  .is-num
    border-radius: 0 $radius $radius 0
    border-left: 1px solid $color-pink

  &.is-on, &.is-on-animationEnd
    cursor: default

  &.is-on
    .is-text
      -webkit-animation: opacity01Anime 800ms
      animation: opacity01Anime 800ms
      -webkit-animation-fill-mode: forwards
      animation-fill-mode: forwards
      background-color: $color-pink
      color: $color-white
      .babysymbol
        -webkit-animation: heartAnime 800ms
        animation: heartAnime 800ms
        -webkit-animation-fill-mode: forwards
        animation-fill-mode: forwards
    .is-num
      span
        -webkit-animation: jumpAnime 800ms
        animation: jumpAnime 800ms
        -webkit-animation-fill-mode: forwards
        animation-fill-mode: forwards

// articletop
.btn-iine-top
  cursor: pointer
  .is-num
    display: inline-flex
  &.is-on,
  &.is-on-animationEnd
    cursor: default
  &.is-on
    .babysymbol
      animation: heartAnime 800ms
      animation-fill-mode: forwards
    .is-num
      span
        animation: jumpAnime 800ms
        animation-fill-mode: forwards

/* アニメ

@keyframes fadeinAnime
  0%
    opacity: 0
    z-index: 9999

  40%
    opacity: 1

  60%
    opacity: 1

  99.9%
    z-index: 9999

  100%
    opacity: 0
    z-index: -9999

@keyframes fadeinAnimeRecipe
  0%
    opacity: 0
    z-index: 9999

  20%
    opacity: 1

  80%
    opacity: 1

  99.9%
    z-index: 9999

  100%
    opacity: 0
    z-index: -9999

@keyframes fadeoffAnime
  0%
    opacity: 0
    z-index: 9999

  40%
    opacity: 1

  60%
    opacity: 1

  99.9%
    z-index: 9999

  100%
    opacity: 0
    z-index: -9999

@keyframes jumpAnime
  0%
    transform: translateY(0)

  10%
    transform: translateY(-4px)

  30%
    transform: translateY(2px)

  40%
    transform: translateY(0)

  100%
    transform: translateY(0)

@keyframes opacity01Anime
  0%
    opacity: 0

  30%
    opacity: 0

  70%
    opacity: 1

  100%
    opacity: 1

@keyframes clip_iconAnime
  0%
    color: #ddd

  70%
    color: #eb748d

  100%
    color: #eb748d

@keyframes heartAnime
  0%
    -webkit-transform: scale(0)
    transform: scale(0)

  30%
    -webkit-transform: scale(0)
    transform: scale(0)

  50%
    -webkit-transform: scale(1.8, 1.8)
    transform: scale(1.8, 1.8)

  60%
    -webkit-transform: scale(1.3, 1.3) translate(0%, -10%)
    transform: scale(1.3, 1.3) translate(0%, -10%)

  65%
    -webkit-transform: scale(1.3, 0.8) translate(0%, 5%)
    transform: scale(1.3, 0.8) translate(0%, 5%)

  80%
    -webkit-transform: scale(0.8, 1.3) translate(0%, -3%)
    transform: scale(0.8, 1.3) translate(0%, -3%)

  100%
    -webkit-transform: scale(1.3, 1.3) translate(0%, 0%)
    transform: scale(1.3, 1.3) translate(0%, 0%)
