.nav-pager
  margin-top: $gutter-width

  ul
    
    max-width: 400px
    margin: 0 auto
    li
      width: calc(100% / 11)
      text-align: center
      > a, > span
        color: $color-gray
        padding: $half-gutter-width 0
        display: block
        border-radius: $radius-large
        font-weight: bold
        .babysymbol
          color: rgba($color-gray,75%)
      >span 
        pointer-events: none
      &.is-current
        a
          background-color: $color-pink
          color: $color-white
          pointer-events: none