.balloon-right
  position: relative
  background: rgba($color-green, 0.2)
  padding: $half-gutter-width $gutter-width
  border-radius: $radius
  font-size: $size-5
  font-weight: bold
  &:after
    left: 100%
    top: 50%
    border: solid transparent
    content: ""
    height: 0
    width: 0
    position: absolute
    pointer-events: none
    border-left-color: rgba($color-green, 0.2)
    border-width: $half-gutter-width
    margin-top: -10px