
// 記事

.article-list
  margin-bottom: $half-gutter-width
  padding: 0 $half-gutter-width
  position: relative
  .is-article
    border-bottom: 1px solid $color-palegray
    padding: $half-gutter-width 0
    .is-article-thumb
      .is-article-img-trim
        position: relative
        overflow: hidden
        padding-top: 72%
        border: 1px solid $color-palegray
        .is-article-img 
          width: 100%
          position: absolute
          top: 50%
          left: 50%
          width: 100%
          height: auto
          transform: translate(-50%, -50%)
          width: 100%
          height: 100%
          object-fit: cover
    .is-article-info
      position: relative
      line-height: 1.3
      .is-article-title
        margin-bottom: 3px
        font-weight: bold
      .is-article-status
        font-size: $size-5
        position: absolute
        bottom: 0
        display: flex
        width: 95%
        .is-category
          color: $color-gray
        .is-date  
          color: $color-pink
    .is-shindan-img-trim
      overflow: hidden
      border-radius: $radius-rounded



.recipe-list
  .is-recipe
    border-radius: $radius-large
    overflow: hidden
    border: 1px solid $color-supergray
    display: block

    .is-recipe-img-trim
      position: relative
      overflow: hidden
      padding-top: 72%
      .is-recipe-img 
        width: 100%
        position: absolute
        top: 50%
        left: 50%
        width: 100%
        height: auto
        transform: translate(-50%, -50%)
        width: 100%
        height: 100%
        object-fit: cover
    .is-recipe-info
      padding: $half-gutter-width
  
      .is-recipe-title
        color: #889A27
        font-weight: bold
        line-height: 1.25
        margin-bottom: $half-gutter-width
      .is-recipe-author
        font-size: $size-6
        color: $color-gray

// ボーダー
.box-border
  &.is-author
    border: none
    border-radius: 0
    border-top: 2px solid $color-palegray
    border-bottom: 2px solid $color-palegray
    padding: $gutter-width 0
    margin: 1.5rem $half-gutter-width
    .is-author-img-trim
      position: relative
      overflow: hidden
      padding-top: 100%
      border-radius: 290486px
      .is-author-img
        width: 100%
        position: absolute
        top: 50%
        left: 50%
        width: 100%
        height: auto
        transform: translate(-50%, -50%)
        width: 100%
        height: 100%
        object-fit: cover
    .is-detail
      a:not([class])
        text-decoration: dashed underline $color-gray
        text-underline-position: under
        text-underline-offset: 1px
        text-decoration-thickness: 1px
        word-break: break-all
  + .is-author
      border-top: none

  &.is-question
    border-color: $color-green
    position: relative
    margin-top: 10px
    .title
      padding-right: 60px 
      @include sm
        padding-right: 80px 
    &::after
      content: ""
      position: absolute
      display: inline-block
      right: $gutter-width
      top: -30px
      right: -10px
      width: 80px
      height: 80px
      background-repeat: no-repeat
      background-size: contain
      @include sm
        right: 15px
    &.is-question-1
      &:after
        background-image: url("#{$url_images}/knowledge/img_qa_icon_1.png")
    &.is-question-2
      &:after
        background-image: url("#{$url_images}/knowledge/img_qa_icon_2.png")

  &.is-answer
    background: $color-palegreen
    border-color: $color-palegreen

// sns
.article-sns-buttons
  .sns-button
    display: inline-block
    border-radius: 3px
    width: 32px
    height: 32px
    margin: 5px
    text-align: center
    .babysymbol
      color: $color-white
      vertical-align: middle
      top: 3px
    &.is-facebook
      background: #2E77F3
    &.is-line
      background: #00B900
    &.is-twitter
      background: #6AABE9
    &.is-snsx
      background: #000000

