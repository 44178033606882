/* ヘッダーバナー

.l-anime-bana
  display: block
  right: 106px
  top: 8px
  position: fixed
  z-index: 105
  width: 104px
  height: 40px
  background:$color-pink
  padding: 4px
  border-radius: 4px
  background-image: linear-gradient(to right, rgb(184 203 184 / 75%) 0%, rgb(184 203 184 / 75%) 0%, rgb(180 101 218 / 75%) 0%, rgb(207 108 201 / 75%) 33%, rgb(238 96 156 / 75%) 66%, rgb(238 96 156 / 75%) 100%)
  backdrop-filter: blur(2px)
  .swiper-slide-shadow-left
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0)) !important
  .swiper-slide-shadow-right
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0)) !important

  &.is-orange
    background-image: linear-gradient( 135deg, #f0960f 10%,#ff6a25 50%, #ff0648 100%)
    .l-anime-bana-link .l-anime-bana-txt
      text-shadow: 0 0 2px #F55555
  &.is-blue
    background-image: linear-gradient( 35deg, #6B73FF 10%,#4277e9 50%, #130CB7 100%)
    .l-anime-bana-link .l-anime-bana-txt
      text-shadow: 0 0 2px #130CB7
  &.is-green
    background-image: linear-gradient( 120deg, #119992 10%,#3b9380 50%, #054c8b 100%)
    .l-anime-bana-link .l-anime-bana-txt
      text-shadow: 0 0 2px #0b4f37
  &.is-bluegreen
    background-image: linear-gradient( 240deg, #05e2e4 10%, #31cbcd 80%)
    .l-anime-bana-link .l-anime-bana-txt
      text-shadow: 0 0 2px #239d9f
  i
    font-size: 90%
    font-weight: 700
    padding-left: 2px
  &.ani-py
    animation-name: ani-pyy
    animation-delay: 1s
    animation-duration: 1.5s
    animation-iteration-count: infinite
    right: 112px

  @include md
    display: none
  @media screen and ( max-width: 349px )
    display: none

  .l-anime-bana-link
    .l-anime-bana-txt
      text-align: center
      font-size: 12px
      line-height: 1.25
      font-weight: bold
      color: $color-white
      text-shadow: 0 0 2px #dc0077

@keyframes ani-pyy
  to,from
    transform: none
  10%
    transform: scale(1.2)
  40%
    transform: scale(1)
  60%
    transform: scale(1.15)
  80%
    transform: scale(1.05)
  100%
    transform: scale(1)
