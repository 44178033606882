#totop
  width: 40px
  height: 40px
  position: fixed
  right: -50px
  bottom: 50px
  // opacity: 0.8
  // display: none
  z-index: 100
  &.ease-in
    right: 20px
    transition: right 300ms
  &.ease-out
    right: -40px
    transition: right 300ms

  a
    position: relative
    // display: inline-block
    width: 40px
    height: 40px
    // display: flex
    // background: url("#{$url_images}/totop.svg")
    // background-repeat: no-repeat
    // background-size: contain
    border-radius: 6px
    // box-shadow: 0 0 2px #ddd
    // background: $color-pink
    display: flex
    justify-content: center
    align-items: center
    background: rgba($color-pink,80%)
    backdrop-filter: blur(3px)
    box-shadow: 0px 2px 4px rgb(171 164 164 / 40%)
    &:before
      color: $color-white
      font-family: "BabySymbols", sans-serif
      content: "\e603"
