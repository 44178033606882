@charset 'utf-8';

@font-face {
  font-family: 'BabySymbols';
  src: url("#{$url_fonts}/BabySymbols.eot");
  src: url("#{$url_fonts}/BabySymbols.eot?#iefix") format("embedded-opentype"),
  url("#{$url_fonts}/BabySymbols.ttf") format("truetype"),
  url("#{$url_fonts}/BabySymbols.woff") format("woff"),
  url("#{$url_fonts}/BabySymbols.svg#BabySymbols") format("svg");
  font-weight: normal;
  font-style: normal;
}

.babysymbol {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "BabySymbols", sans-serif;
  font-size: 120%;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.babysymbol-arrowdown:before {
  content: "\e600";
}

.babysymbol-arrowup:before {
  content: "\e603";
}

.babysymbol-arrowleft:before {
  content: "\e601";
}

.babysymbol-arrowright:before {
  content: "\e602";
}

.babysymbol-question:before {
  content: "\e604";
}

.babysymbol-answer:before {
  content: "\e605";
}

.babysymbol-waitingbaby:before {
  content: "\e606";
}

.babysymbol-maternity:before {
  content: "\e607";
}

.babysymbol-baby:before {
  content: "\e608";
}

.babysymbol-kids:before {
  content: "\e609";
}

.babysymbol-babyfood:before {
  content: "\e60a";
}

.babysymbol-bookmarkblank:before {
  content: "\e60b";
}

.babysymbol-calendar:before {
  content: "\e60c";
}

.babysymbol-camera:before {
  content: "\e60d";
}

.babysymbol-caution:before {
  content: "\e60e";
}

.babysymbol-clip:before {
  content: "\e610";
}

.babysymbol-edit:before {
  content: "\e613";
}

.babysymbol-flowerblank:before {
  content: "\e614";
}

.babysymbol-flower:before {
  content: "\e615";
}

.babysymbol-heartblank:before {
  content: "\e616";
}

.babysymbol-heart:before {
  content: "\e617";
}

.babysymbol-starblank:before {
  content: "\e645";
}

.babysymbol-star:before {
  content: "\e646";
}

.babysymbol-home:before {
  content: "\e618";
}

.babysymbol-hospital:before {
  content: "\e619";
}

.babysymbol-inbox:before {
  content: "\e61a";
}

.babysymbol-info:before {
  content: "\e61b";
}

.babysymbol-lock:before {
  content: "\e61d";
}

.babysymbol-login:before {
  content: "\e61e";
}

.babysymbol-mail:before {
  content: "\e61f";
}

.babysymbol-map:before {
  content: "\e620";
}

.babysymbol-menu:before {
  content: "\e621";
}

.babysymbol-news:before {
  content: "\e622";
}

.babysymbol-notify:before {
  content: "\e623";
}

.babysymbol-phone:before {
  content: "\e624";
}

.babysymbol-pin:before {
  content: "\e625";
}

.babysymbol-plus:before {
  content: "\e626";
}

.babysymbol-search:before {
  content: "\e627";
}

.babysymbol-setting:before {
  content: "\e628";
}

.babysymbol-smartphone:before {
  content: "\e629";
}

.babysymbol-tag:before {
  content: "\e62a";
}

.babysymbol-talk:before {
  content: "\e62b";
}

.babysymbol-trash:before {
  content: "\e62c";
}

.babysymbol-circle:before {
  content: "\e60f";
}

.babysymbol-triangle:before {
  content: "\e62d";
}

.babysymbol-doublecircle:before {
  content: "\e612";
}

.babysymbol-ban:before {
  content: "\e632";
}

.babysymbol-close:before {
  content: "\e611";
}

.babysymbol-user:before {
  content: "\e62e";
}

.babysymbol-useradd:before {
  content: "\e630";
}

.babysymbol-userremove:before {
  content: "\e631";
}

.babysymbol-view:before {
  content: "\e62f";
}

.babysymbol-blanklink:before {
  content: "\e633";
}

.babysymbol-browserback:before {
  content: "\e634";
}

.babysymbol-browsernext:before {
  content: "\e635";
}

.babysymbol-ranking:before {
  content: "\e636";
}

.babysymbol-dropdown:before {
  content: "\e637";
}

.babysymbol-dropdownreset:before {
  content: "\e638";
}

.babysymbol-fastforward:before {
  content: "\e639";
}

.babysymbol-fastreverse:before {
  content: "\e63a";
}

.babysymbol-girl:before {
  content: "\e63b";
}

.babysymbol-image:before {
  content: "\e63c";
}

.babysymbol-link:before {
  content: "\e61c";
}

.babysymbol-linkadd:before {
  content: "\e63d";
}

.babysymbol-linkadded:before {
  content: "\e63e";
}

.babysymbol-linkremove:before {
  content: "\e63f";
}

.babysymbol-nexttrack:before {
  content: "\e640";
}

.babysymbol-pretrack:before {
  content: "\e641";
}

.babysymbol-ok:before {
  content: "\e642";
}

.babysymbol-postrecipe:before {
  content: "\e643";
}

.babysymbol-report:before {
  content: "\e644";
}

.babysymbol-birth:before {
  content: "\e64d";
}

.babysymbol-myfolder:before {
  content: "\e647";
}

.babysymbol-facebook:before {
  content: "\e648";
}

.babysymbol-youtube:before {
  content: "\e915";
}

.babysymbol-line:before {
  content: "\e649";
}

.babysymbol-twitter:before {
  content: "\e64a";
}

.babysymbol-instagram:before {
  content: "\e90b";
}

.babysymbol-up:before {
  content: "\e64b";
}

.babysymbol-reload:before {
  content: "\e64c";
}

.babysymbol-n_prize:before {
  content: "\e904";
}

.babysymbol-n_arrow-right:before {
  content: "\e909";
}

.babysymbol-n_arrow-left:before {
  content: "\e90a";
}

.babysymbol-n_park:before {
  content: "\e903";
}

.babysymbol-n_beginner:before {
  content: "\e901";
}

.babysymbol-n_knowledge:before {
  content: "\e902";
}

.babysymbol-n_books:before {
  content: "\e908";
}

.babysymbol-n_talk:before {
  content: "\e907";
}

.babysymbol-n_question:before {
  content: "\e906";
}

.babysymbol-n_cart:before {
  content: "\e905";
}

.babysymbol-n_hotel:before {
  content: "\e900";
}

.babysymbol-n_recipe:before {
  content: "\e90c";
}

.babysymbol-studio:before {
  content: "\e90d";
}

.babysymbol-n_handshake:before {
  content: "\e90e";
}

.babysymbol-n_logout:before {
  content: "\e912";
}

.babysymbol-n_car:before {
  content: "\e90f";
}

.babysymbol-n_bus:before {
  content: "\e910";
}

.babysymbol-n_train:before {
  content: "\e911";
}

.babysymbol-n_present:before {
  content: "\e913";
}

.babysymbol-youtube:before {
  content: "\e915";
}

.babysymbol-tsubuyaki:before {
  content: "\e914";
}

.babysymbol-snsx:before {
  content: "\e916";
}
