@charset "utf-8"

// エコー動画館
.echo-date
  font-weight: bold
  text-align: center
  margin: $half-gutter-width 0

  .echo-date-title
    padding: 2px 0
    background: $color-navy
    color: $color-beige
  .echo-date-figure
    padding: 2px 0
    background: $color-beige
    color: $color-navy

.echo-info-table
  width: 100%
  margin-bottom: $half-gutter-width
  tr
    border-bottom: 2px dashed $color-beige
  th,td
    padding: 3px 0
  th
    text-align: left
  td
    text-align: right


// 一覧ページ
.echo-list
  padding: $half-gutter-width
  .echo-date
    border-radius: 6px
    overflow: hidden

.qrcode-wrap
  #qrcode
    border: 2px solid $color-palegray
    width: 274px
    height: 274px
    margin: auto
    padding: 20px

// 詳細ページ
.echo-detail
  .echo-date
    .echo-date-title
      padding: 3px 0
    .echo-date-figure
      padding: $gutter-width 0


// おぎゃー写真館

.ogyaa-img
  overflow: hidden
  height: 110px
  position: relative
  @include sm
    height: 130px
  video,img
    width: 120%
    position: absolute
    top: 50%
    left: 50%
    -webkit-transform: translate(-50%, -50%)
    transform: translate(-50%, -50%)
  &.is-video
    &:before
      content: ""
      display: inline-block
      width: 30px
      height: 30px
      top: 0
      left: 0
      position: absolute
      z-index: 100
      background-image: url("#{$url_images}/ss/img_ogyaa-movie-badge.png")
      background-size: contain
      background-repeat: no-repeat

.ogyaa-date
  word-break: break-all

.ogyaa-list
  display: block
  padding: 0
  border: 1px solid #dbdbdb
  .ogyaa-list-bottom
    padding: 5px


// おぎゃー写真館pp

.ogyaa-pp
  background: #FFF6E2
  .ogyaa-pp-btn
    display: block
    max-width: 400px
    margin: 0 auto
  .box-border
    a[target="_blank"]
      color: $color-blue
      border-bottom: 1px solid
      display: inline-block

  .pp-checkbox
    width: 100%
    label
      background: $color-palegray
      padding: $gutter-width
      padding-left: 4.5rem
      margin-bottom: 8px
      width: 100%
      border-radius: $radius-small
      position: relative
      font-weight: bold
      font-size: $size-3
      cursor: pointer
      transition: background 0.3s

      &:before
        position: absolute
        z-index: 1
        top: 1.55rem
        left: 1.5rem
        width: 1.5rem
        height: 0.75rem
        content: ''
        -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75)
        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75)
        -webkit-transform: rotate(-45deg) scale(0, 0)
        transform: rotate(-45deg) scale(0, 0)
        border: 3px solid $color-pink
        border-top-style: none
        border-right-style: none
      &:after
        position: absolute
        top: 1rem
        left: 1rem
        width: 2.5rem
        height: 2.5rem
        content: ''
        cursor: pointer
        border: 4px solid $color-gray
        background: $color-white
    input:checked + label
      background: $color-pink
      color: $color-white
      background: $color-pink
      transition: background 0.3s
      &:before
        -webkit-transform: rotate(-45deg) scale(1.3, 1.3)
        transform: rotate(-45deg) scale(1.3, 1.3)
      &:after
        border: 4px solid $color-white

.ogyaa-pp-form
  .input
    input
      padding: $gutter-width $half-gutter-width


// 順番

.entry-step
  max-width: 768px
  border-radius: .25em
  background-color: transparent
  text-align: center
  margin: 0 auto $half-gutter-width auto
  padding: 0


  &:after
    content: ""
    display: table
    clear: both

  li
    counter-increment: steps
    vertical-align: top
    display: inline-block
    position: relative
    float: none
    width: 28%
    text-align: center
    margin: 0.4em 0

    &::after
      display: inline-block
      color: $base-font-color
      position: absolute
      content: ''
      height: 2px
      background: $color-palegray
      left: 50%
      width: 100%
      top: 11px
      margin: 0

    &:last-of-type
      &::after
        display: none

      margin-right: 0

    > em
      display: inline-block
      font-size: $size-5
      color: $base-font-color
      padding-top: 32px
      position: relative
      line-height: 1.2
      font-feature-settings: "palt"
      font-style: normal

    > em::before
      content: counter(steps)
      position: absolute
      z-index: 1
      left: 50%
      right: auto
      -webkit-transform: translateX(-50%)
      -moz-transform: translateX(-50%)
      -ms-transform: translateX(-50%)
      -o-transform: translateX(-50%)
      transform: translateX(-50%)
      height: 24px
      width: 24px
      border-radius: 50%
      background-color: $color-palegray
      top: 0
      line-height: 24px
      color: $color-white
      font-weight: 700

    &:not(.is-current) em::before
      color: $base-font-color

    &.is-visited em::before
      color: $color-white

    &.is-current > em
      font-weight: bold
      color: $color-pink

    &.is-visited > em::before, &.is-current > em::before, &.is-visited::after
      background-color: $color-pink



// 詳細ページ
.nav-page
  border-top: 1px solid $color-palegray
  border-bottom: 1px solid $color-palegray
  .nav-page-link
    color: $color-pink
    line-height: 1.4
    padding: $gutter-width 0
    display: -webkit-box
    display: -ms-flexbox
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center

    &:nth-child(2)
      border-left: 1px solid $color-palegray
      border-right: 1px solid $color-palegray
