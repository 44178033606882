// エラー等の表示

.help
  display: block
  margin-top: 5px
  margin-bottom: 20px
  &.is-danger
    color: $color-red
  &.is-success
    color: $color-blue

.input
  &+.help
    margin-top: -10px
