/* プレゼント

.l-present
  a
    display: block
    position: fixed
    right: 6rem
    top: 8px
    width: 40px
    height: 40px
    cursor: pointer
    z-index: 115
    border-radius: 20px
    display: flex
    justify-content: center
    align-items: center
    background: rgba($color-pink,80%)
    backdrop-filter: blur(3px)
    .babysymbol
      color: $color-white
      font-size: $size-1
      top: 0