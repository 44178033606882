.lists
  &.is-circle
    li
      position: relative
      padding-left: 20px
      &:before
        content: "●"
        font-size: 8px
        color: $color-pink
        position: absolute
        left: 0px
        top: 5px
    li + li
      margin-top: 8px

  &.is-number
    counter-reset: item
    li
      position: relative
      padding-left: 20px
      &::before
        counter-increment: item
        content: counter(item)'.'
        /* 以下は自由に装飾... */
        padding-right: .5em
        font-weight: bold
        position: absolute
        left: 0px
        top: 0px
        color: $color-pink
    .is-number
      margin-top: 1em
      padding-left: 1.5em
      li::before
        color: lighten( $color-pink, 10% )
    li + li
      margin-top: 8px
