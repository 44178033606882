.floating_btn
  position: fixed
  width: 100%
  z-index: 100
  display: none
  top: 60px
  @include md
    display: none !important
    opacity: 0 !important
    
  

.floating_btn-toapp
  color: #eb748d !important
  letter-spacing: 0.03em
  width: 150px
  // height: 28px
  text-align: center
  border: 2px solid #eb748d
  border-radius: 20px
  // background: #ffffff
  display: block
  // line-height: 28px
  margin: 0 auto
  font-weight: bold
  background: rgba($color-white,90%)
  backdrop-filter: blur(3px)
  box-shadow: 0px 2px 4px rgb(171 164 164 / 40%)
  padding: 2px 0 6px 0 
  img
    width: 100px
  

.floating_btn-close-iconcover
  position: absolute
  top: 50%
  left: 50%
  -webkit-transform: translate(-50%, -50%)
  transform: translate(-50%, -50%)
  font-size: 12px
  margin-left: 95px
  padding: 8px

  .floating_btn-close-icon
    color: #ffffff
    background: #999999
    border-radius: 50%
    padding: 4px