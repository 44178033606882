.html-clipped,
.modal-background,
.modal
  bottom: 0
  left: 0
  position: absolute
  right: 0
  top: 0

.html-clipped
  overflow: hidden


.modal-background
  background-color: rgba(195, 190, 186, 0.7)

.modal
  align-items: center
  display: none
  flex-direction: column
  justify-content: center
  overflow: hidden
  position: fixed
  z-index: 200
  padding: 0 15px
  opacity: 0
  &.is-active
    display: flex
    opacity: 1

    animation-duration: 0.5s
    animation-name: fadein

@keyframes fadein
  0%
    display: none
    opacity: 0

  1%
    display: flex
    opacity: 0

  100%
    display: flex
    opacity: 1



.modal-content
  margin: 0 20px
  padding: 20px
  max-height: calc(100vh - 80px)
  overflow: auto
  position: relative
  width: 100%
  background: $color-white
  border-radius: $radius-large
  max-width: 600px
  box-shadow: 0 0 10px rgba(72, 63, 63, 0.5) !important
  .checkbox
    margin-bottom: 5px

.l-contents
  &.is-bbclapp2
    .modal-content
      max-height: calc(100vh - 110px)

img.modal-content
  padding: 0
  background: transparent
  border-radius: 0
  box-shadow: none !important

.modal-close
  cursor: pointer
  background: 0 0
  height: 30px
  position: fixed
  right: 20px
  top: 5px
  width: 30px
  z-index: 100
  &:before
    font-family: "BabySymbols"
    content: "\e611"
    display: inline-block
    color: $color-pink
    text-indent: 0
    font-size: 24px
    // text-shadow: 0 2px 2px rgba(72, 63, 63, 0.5)
