
// タブ

.tab-panel
  .tab-group
    display: flex
    justify-content: center
  .tab
    flex-grow: 1
    cursor: pointer
    transition: all 0.3s ease-out
  .panel-group
    height: 100%
  .panel
    display: none
    transition: all 0.3s ease-out
  .panel.is-show
    display: block
    animation: show 0.3s linear 0s

  &.is-normal
    margin-top: $half-gutter-width
    .tab-group
      padding: $half-gutter-width $half-gutter-width 0 $half-gutter-width !important
      background: $color-palegray
      .tab
        width: auto
        padding: 8px 0
        text-align: center
        cursor: pointer
        font-weight: bold
        height: auto
        display: flex
        align-items: center
        justify-content: center
        color: $color-gray
        border-width: 1px 1px 0
        border-style: solid
        border-color: transparent
        border-radius: 6px 6px 0 0
        position: relative
        flex-grow: 1
        &.is-active
          border-color: $color-supergray
          color: $color-pink
          background: $color-white
          &:before
            opacity: 0
        + .tab
          &:before
            width: 2px
            height: 50%
            display: inline-block
            content: ""
            background: $color-supergray
            position: absolute
            left: -2px
            z-index: 1
    .panel-group
      .panel
        overflow: hidden
        // border: 2px solid $color-supergray

  &.is-white
    .tab-group
      background: $color-white
      .tab
        border-bottom: 1px solid $color-supergray
        &.is-active
          border-bottom: 1px solid transparent



@keyframes show
  from
      opacity: 0
  to
      opacity: 1




/* アコーディオン

.accordion
  border-radius: $radius-large
  overflow: hidden
  border: 1px solid $color-supergray
  &.is-normal
    .accordion-item
      cursor: pointer
      &.is-active,
      &:last-child
        .accordion-title
          border-bottom: none
      &.is-active
        .accordion-title
          color: $base-font-color


    .accordion-title
      cursor: pointer
      position: relative
      padding: $half-gutter-width $gutter-width*2 $half-gutter-width $gutter-width
      font-weight: bold
      border-bottom: 1px solid $color-supergray
      background: #fbfbfb
      font-size: $size-3
      color: $color-gray
      &::before
        content: ""
        position: absolute
        right: 15px
        top: 0
        bottom: 0
        margin: auto 0
        background-color: $color-pink
        width: 15px
        height: 1px
        transition: all 0.3s
      &::after
        content: ""
        position: absolute
        right: 15px
        top: 0
        bottom: 0
        margin: auto 0
        background-color: $color-pink
        width: 15px
        height: 1px
        transition: all 0.3s
        transform: rotate(90deg)

    .accordion-content
      padding: $half-gutter-width $gutter-width
      display: none
      cursor: pointer
      border-bottom: none
      &.is-open
        display: block
        border-bottom: 1px solid $color-supergray
    .accordion-item.is-active .accordion-title
      &::before
        transform: rotate(180deg)
      &::after
        transform: rotate(180deg)
        opacity: 0

  &.is-faq
    .accordion-title
      color: $base-font-color

  .accordion-item
    &:last-child
      .accordion-content
        &.is-open
          border-bottom: none
