$title-color: $base-font-color !default
$title-family: false !default
$title-size: $size-3 !default
$title-weight: $weight-bold !default
$title-line-height: 1.5 !default
$title-strong-color: inherit !default
$title-strong-weight: inherit !default
$title-sub-size: 0.75em !default
$title-sup-size: 0.75em !default


.title
  word-break: break-word
  .sub
    font-size: $title-sub-size
  sub
    font-size: $title-sub-size
  sup
    font-size: $title-sup-size
  .tag
    vertical-align: middle
  &:not(:last-child)
    margin-bottom: $size-3

.title
  // color: $title-color
  @if $title-family
    font-family: $title-family
  font-size: $title-size
  font-weight: $title-weight
  line-height: $title-line-height
  strong
    color: $title-strong-color
    font-weight: $title-strong-weight
  & + .highlight
    margin-top: -0.75rem

  // Sizes
  @each $size in $sizes
    $i: index($sizes, $size)
    &.is-#{$i}
      font-size: $size

  &.has-icon
    display: flex
    align-items: center

  &.is-circle
    border-radius: $radius-rounded
    border: 2px solid
    padding: 5px 0
    width: 200px
    margin: 0 auto

  &.is-border
    position: relative
    padding: .5em 1em .5em 1.5em
    margin-bottom: 0
    &:before
      position: absolute
      top: .5em
      left: .5em
      content: ''
      width: 5px
      height: -webkit-calc(100% - 1em)
      height: calc(100% - 1em)
      background-color: $color-pink
      border-radius: 4px
    &.is-green
      &:before
        background-color: $color-green

  &.is-border-bottom
    border-bottom: solid 3px $color-palepink
    position: relative
    padding: $half-gutter-width 0
    margin-bottom: $gutter-width
    &:after
      position: absolute
      content: " "
      display: block
      border-bottom: solid 3px $color-pink
      bottom: -3px
      width: 20%

  .babysymbol
    top: 2px
    