.lcp_detail-movie,
.lcp_detail-list,
.lcp_side,
.lcp_footer
  content-visibility: auto

// .lcp_article-list
//   contain-intrinsic-size: 430px
//   @include md
//     contain-intrinsic-size: 280px

// .lcp_recipe-list
//   contain-intrinsic-size: 230px
//   @include md
//     contain-intrinsic-size: 200px

// .lcp_side-senmonka
//   contain-intrinsic-size: 510px
//   @include md
//     contain-intrinsic-size: 450px

// template
.lcp_side
  contain-intrinsic-size: 2400px

.lcp_footer
  contain-intrinsic-size: 900px
  @include md
    contain-intrinsic-size: 350px

// detail
.lcp_detail-movie
  contain-intrinsic-size: 700px
  @include md
    contain-intrinsic-size: 400px

.lcp_detail-list
  contain-intrinsic-size: 400px
  @include md
    contain-intrinsic-size: 300px