@charset "utf-8"

.c-news-talk-blue
  color: $color-news-talk-blue !important
.c-news-talk-red
  color: $color-news-talk-red !important


.u-bg_category-recipe
  background: #eb7474

.u-bg_category-life
  background: #eb9b74

.u-bg_category-mama
  background: #ebc274

.u-bg_category-column
  background: #60d665

.u-bg_category-event
  background: #2fd1e0

.u-bg_category-medical
  background: #6a9ff7

.u-bg_category-entame
  background: #f57eda

.u-bg_category-support
  background: #ae79e4

.u-bg_category-goods
  background: #eb748e

.u-bg_category-special
  background: #947575

.u-bg_category-series
  background: #77dcb7

.u-bg_category-news,
.u-bg_category-none
  background: #8d8d8d

.u-bg_category-pr
  background: #eb748d


.u-c_category-recipe
  color: #eb7474

.u-c_category-life
  color: #eb9b74

.u-c_category-mama
  color: #ebc274

.u-c_category-column
  color: #60d665

.u-c_category-event
  color: #2fd1e0

.u-c_category-medical
  color: #6a9ff7

.u-c_category-entame
  color: #f57eda

.u-c_category-support
  color: #ae79e4

.u-c_category-goods
  color: #eb748e

.u-c_category-special
  color: #947575

.u-c_category-series
  color: #77dcb7



// ニュース用ラベル

.label-news
  color: $color-white !important
  font-size: $size-6
  font-weight: bold
  width: 6rem
  display: inline-block
  text-align: center
  opacity: 0.85
  &.is-big
    font-size: $size-4
    width: auto
    padding: 0 8px





// swiper

.news-tab-menu
  padding: $half-gutter-width $half-gutter-width 0 $half-gutter-width !important
  background: $color-palegray
  overflow: hidden
  .swiper-slide
    width: auto
    padding: 8px 0
    text-align: center
    cursor: pointer
    font-weight: bold
    border-bottom: 0px
    font-size: $size-4
    height: auto
    display: flex
    align-items: center
    justify-content: center
    color: $color-gray
    border-width: 1px 1px 0
    border-style: solid
    border-color: transparent

    + .swiper-slide
      &:before
        width: 2px
        height: 50%
        display: inline-block
        content: ""
        background: $color-supergray
        position: absolute
        left: -2px
        z-index: 1
    &.swiper-slide-thumb-active
      color: $color-pink !important
      position: relative
      background: $color-white
      border-radius: $radius-large $radius-large 0 0
      z-index: 2
      border-color: $color-supergray
      &:before
        opacity: 0
      + .swiper-slide
        &:before
          opacity: 0



// 一覧

.news-article
  position: relative

  .is-article-thumb
    position: relative
    .label-news
      position: absolute
      top: 0
      left: 0
    .is-article-img-trim
      position: relative
      overflow: hidden
      padding-top: 61%
      border: 1px solid $color-palegray
      .is-article-img
        position: absolute
        top: 50%
        left: 50%
        width: 100%
        height: auto
        transform: translate(-50%, -50%)
        object-fit: cover
  .is-article-thumb.is-square
    .is-article-img-trim
      padding-top: 99%

  &.is-inactive
    // pointer-events: none
    > .row
      // opacity: 0.4
      &::before
        content: ""
        background: rgb(255 243 243 / 65%)
        width:105%
        height:100%
        display: block
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        z-index: 1
        -webkit-backdrop-filter : blur(2px)
        backdrop-filter: blur(2px)

    .countdown-timer
      font-size: 1.1em
      text-align: center
      min-width: 320px
      font-weight: bold
      position: absolute
      z-index: 2
      top: 50%
      left: 50%
      transform: translate(-50%,-50%)
      // color: $base-font-color
      color: $color-pink
      text-shadow: 0px 0px 2px white, 0px 0px 3px white, 0px 0px 3px white



.news-article-info
  letter-spacing: -0.02em
  span + span
    margin-left: $half-gutter-width
  i
    font-size: 1em


.news-article-3clm
  .news-article
    border-bottom: 1px solid $color-supergray
    padding: $half-gutter-width
    @include md
      border-bottom: none

.news-article-list
  margin-bottom: $half-gutter-width
  padding: $half-gutter-width
  position: relative
  .news-article
    border-bottom: 1px solid $color-supergray
    padding: $half-gutter-width 0
  .col-sm-4 > .news-article
    @include sm
      border-bottom: none
  &.is-rank
    counter-reset: item
    list-style-type: none
    position: relative
    .news-article
      padding-left: 24px

      &:before
        text-align: center
        position: absolute
        counter-increment: item
        content: counter(item)
        color: $color-white
        top: $half-gutter-width
        left: 1px
        width: 18px
        height: 18px
        line-height: 18px
        z-index: 1
        background: rgba($color-pink,80%)
        backdrop-filter: blur(3px)
  &.is-rank-series
    counter-reset: item
    list-style-type: none
    .news-article a
      position: relative
      display: block
      &:before
        text-align: center
        position: absolute
        counter-increment: item
        content: counter(item)
        color: $color-white
        top: 1px
        left: 1px
        width: 18px
        height: 18px
        line-height: 18px
        z-index: 1
        background: rgba($color-pink,80%)
        backdrop-filter: blur(3px)
  &.is-nav
    li:last-child
      article
        border-bottom: none
  .babysymbol.fz-i
    font-weight: 900 !important

// カテゴリー
.news-category
  a
    padding: $half-gutter-width*0.5 0
    display: block
    text-align: center
    border-bottom: 1px solid $color-supergray
    .babysymbol
      font-size: $size-3
    .is-title
      font-weight: bold
      margin-top: -2px
      font-size: $size-5
    .is-title-en
      display: block
      color: $color-gray
      font-size: $size-6
      margin-top: -2px

.pickup-carousel
  overflow: hidden
  position: relative
  li
    width: 150px
    height: 150px
    margin: 0 $half-gutter-width

// カルーセル

.p-series-carousel
  padding-bottom: 10px
  position: relative
  overflow: hidden
  li
    width: 100px
    height: 100px
    margin: 0 $half-gutter-width
    @include sm
      width: 120px
      height: 120px

  a
    display: block

  .series-article
    .is-article-thumb img
      width: 100%
      height: auto
      border: 1px solid $color-palegray

  .swiper-scrollbar
    background: rgba(153, 153, 153, 0.1)
    height: 2px !important
    bottom: 5px

    .swiper-scrollbar-drag
      background: rgba(128, 193, 195, 0.6)

  &.is-rank
    counter-reset: item
    list-style-type: none

    a:before
      text-align: center
      position: absolute
      counter-increment: item
      content: counter(item)
      color: $color-white
      top: 1px
      left: 1px
      width: 18px
      height: 18px
      line-height: 18px
      z-index: 1
      background: rgba($color-pink,80%)
      backdrop-filter: blur(3px)
.p-series-carousel.p-series-2rows
  min-height: calc(100px + 1em)
  @include sm
    min-height: calc(120px + 1em)
  ol, ul
    height: calc(100px + 1em)
    @include sm
      height: calc(120px + 1em)
.p-series-carousel.p-series-3rows
  min-height: calc(100px + 1em + 1.2rem)
  @include sm
    min-height: calc(120px + 1em + 1.2rem)
  ol, ul
    height: calc(100px + 1em + 1.2rem)
    @include sm
      height: calc(120px + 1em + 1.2rem)

.news-nav-sort
  ul
    display: flex
    justify-content: flex-end
    li
      .is-active
        color: $color-pink
      + li
        &::before
          content: ""
          width: 1px
          height: 100%
          background: $color-supergray
          display: inline-block
          vertical-align: middle
          margin: 0 $half-gutter-width



// 記事 キャッチ画像

.detail-top-img
  position: relative
  .is-detail-top-img-trim
    position: relative
    overflow: hidden
    padding-top: 61%
    .is-detail-top-img-img
      position: absolute
      top: 50%
      left: 50%
      width: 100%
      height: auto
      transform: translate(-50%, -50%)
      object-fit: cover


// 記事 インフォ

.news-detail-info
  .label-news
    font-size: $size-5
    width: 8rem
    opacity: 1
    margin-right: $gutter-width
  &.is-bottom
    .is-item
      font-size: $size-5
      font-weight: bold
      cursor: pointer
      &:not(.btn-iine-top)
        text-decoration: underline

      + .is-item
        margin-left: 6px

    .ani-pyn
      color: #FFFFFF !important
      padding: 2px 10px
      border-radius: 25px
      font-size: 1.4rem
      text-decoration: none !important
      i
        animation-name: ani-py
        animation-delay: 1s
        animation-duration: 1.5s
        animation-iteration-count: infinite
        font-size: 110%
        font-weight: 700


    .c-news-talk-red
      &.ani-pyn
        background-image: linear-gradient(135deg, #f0960f 10%, #ff6a25 50%, #ff0648 100%)

    .c-news-talk-blue
      &.ani-pyn
        background-image: linear-gradient(35deg, #6B73FF 10%, #4277e9 50%, #130CB7 100%)

@keyframes ani-py
  to,from
    transform: none
  10%
    transform: scale(1.4)
  40%
    transform: scale(1)
  60%
    transform: scale(1.15)
  80%
    transform: scale(1.05)
  100%
    transform: scale(1)

// 本文

.news-detail-lead
  font-size: $size-3

.btn_news_digest
  &.is-read
    display: none

  a.display-b
    max-width: 460px
    display: block
    text-align: center
    border-radius: 3px
    cursor: pointer
    background: $color-pink
    padding: 15px 0
    margin: 0 auto
    color: $color-white
    font-size: $size-2
    font-weight: bold
    letter-spacing: 0.1em

  &:not(.is-read) ~ *
    display: none !important

  ~ *
    animation: show 0.3s linear 0s


.button.is-comment
  width: 100%
  max-width: 460px
  text-align: center
  border-radius: 3px
  margin: 0 auto
  font-size: $size-3
  font-weight: bold
  letter-spacing: 0.1em
  color: $color-pink
  border: 2px solid $color-pink
  height: 3em

// コメント

.p-comment-link-title
  display: inline
  border-bottom: 1px solid $color-gray

.p-comments-block
  margin-top: $gutter-width
  border-top: 1px solid $color-supergray
  &.fukidashi-block
    border-top: none
    margin-top: 1rem

.p-comments
  .p-comment
    padding: 10px 0
    border-bottom: 1px solid $color-supergray
    line-height: 1.7

    &:last-child
      border-bottom: none

    &.is-reply-open
      .c-btn-res
        transition: 0.3s all

        .babysymbol
          transform: rotate(180deg)
          transition: 0.3s transform
      .p-comments-replies
        display: block
        opacity: 1
        animation: show 0.3s linear 0s

    &.is-readmore-open >
      .p-comment-text-digest
        display: none

      .p-comment-text-body
        display: block

    .p-comment-text-body
      display: none

  .p-comment-state-top
    position: relative
    margin-bottom: 5px
    font-size: $size-5
    color: $color-gray

    .p-comment-report
      cursor: pointer
      color: $color-gray
      text-decoration: solid underline $color-gray
      text-underline-position: under
      text-underline-offset: 1px
      position: absolute
      right: 0
      top: 0

  .p-comment-state-bottom
    display: flex

  .p-comment-text
    font-size: $size-4

    + .p-comment-state-bottom
      margin-top: 20px

  .p-comment-rate
    display: flex

    .p-comment-rate-plus, .p-comment-rate-minus
      text-align: center
      color: $color-white
      width: 30px
      height: 30px
      display: inline-block
      position: relative
      border-radius: 3px
      transition: all 0.3s

    .p-comment-rate-plus:hover, .p-comment-rate-minus:hover
      cursor: pointer
      margin-top: 2px
      border-bottom: 2px solid
      transition: all 0.3s

    .p-comment-rate-plus::before, .p-comment-rate-minus::before
      content: ""
      display: inline-block
      width: 15px
      height: 15px
      background-repeat: no-repeat
      background-position: 50% 50%
      position: absolute
      top: 50%
      left: 50%
      -webkit-transform: translate(-50%, -50%)
      transform: translate(-50%, -50%)

    .p-comment-rate-plus.is-loading::before, .p-comment-rate-minus.is-loading::before
      width: 1.5em
      height: 1.5em
      background-image: url("#{$url_images}/news/comment/icon-loading.svg")
      -webkit-animation: spin 2s infinite linear
      animation: spin 2s infinite linear

    .p-comment-rate-plus
      background: $color-green
      border-bottom: 3px solid transparent
      border-color: #479496

      &::before
        background-image: url("#{$url_images}/news/comment/icon-plus.svg")

      &.is-pushed
        background: #c5e3e4
        border: none
        cursor: unset

        &:hover
          margin-top: 0

    .p-comment-rate-minus
      background: $color-supergray
      border-bottom: 3px solid transparent
      border-color: #aaaaaa

      &::before
        background-image: url("#{$url_images}/news/comment/icon-minus.svg")

      &.is-pushed
        background: #f7f7f7
        border: none
        cursor: unset

        &:hover
          margin-top: 0

    .p-comment-rate-gage
      width: 24vw
      padding: 0 5px
      @include md
        width: 300px

      .p-comment-rate-bar
        width: 100%
        margin: 0 auto
        background: transparent linear-gradient(180deg, #D3D3D3 0%, #EBEBEB 8%, #F4F4F4 100%) 0% 0% no-repeat padding-box
        height: 12px
        border-radius: 3px
        overflow: hidden

        .p-comment-rate-bar-plus, .p-comment-rate-bar-minus
          height: 12px

        .p-comment-rate-bar-plus
          background: $color-green

        .p-comment-rate-bar-minus
          background: $color-supergray

      .p-comment-rate-text
        position: relative
        display: flex
        font-weight: bold

        .p-comment-rate-text-plus
          color: $color-green

        .p-comment-rate-text-minus
          color: $color-gray
          margin-left: auto

  .p-comment-btn
    margin-left: auto

  .p-comments-replies
    display: none
    opacity: 0
    position: relative
    margin-top: 10px
    border-top: 1px solid $color-supergray

    &::before
      content: ""
      width: 2px
      height: 95%
      background: $color-supergray
      display: block
      position: absolute
      top: 2.5%
      left: 5px
      border-radius: 2px
      @include md
        left: 18px

    li:last-child
      border-bottom: none

    .p-comment
      padding: 10px 0
      margin-left: 15px
      @include md
        margin-left: 35px

    .p-comment-rate .p-comment-rate-gage
      width: 50vw
      @include md
        width: 300px


    .p-comment-area
      padding: 0


  .p-comment-articletitle
    display: flex
    border-bottom: 1px solid $color-supergray
    padding-bottom: 10px

    .c-article-digest-thumbnail
      width: 100px
      height: 60px
      overflow: hidden
      padding-right: 10px

      img
        width: 100%

    .c-article-digest-title
      flex-grow: 1
      font-weight: bold
      font-size: 1.2em
      width: calc(100% - 110px)
      line-height: 1.5

      &:after
        content: ""
        font-family: "BabySymbols"
        color: $color-pink

  &.p-mycomments
    padding-top: 0
    margin: 20px 0

    li + li
      margin-top: 10px

    .p-comment
      padding: 20px
      background: $color-white
      border-radius: 3px
      border: 1px solid $color-supergray
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1)

      &:last-child
        border-bottom: none

    .p-mycomments-list li
      padding-top: 10px

      + li
        border-top: 1px solid $color-supergray

.fukidashi
  .p-comment
    border: none
    padding: 10px
    border-radius: 0.5rem
    position: relative
    z-index: 1
    &::before
      content: ""
      width: 50px
      height: 50px
      border-radius: 50px
      overflow: hidden
      position: absolute
      top: 0
    &::after
      content: ""
      width: 20px
      height: 20px
      position: absolute
      top: 20px
      clip-path: polygon(0 100%, 100% 0, 100% 100%)
      z-index: -1
    &:first-child,&:nth-child(2n+1)
      margin-left: 60px
      &::before
        left: -60px
      &::after
        left: -12px
    &:nth-child(2n)
      margin-right: 60px
      &::before
        right: -60px
      &::after
        clip-path: polygon(0 0, 100% 100%, 0 100%)
        right: -12px
    &:first-child,&:nth-child(3n+1)
      background: #f0f9f9
      &::before
        background: #b6e1e1 center / 55% no-repeat url('#{$url_images}/news/comment/user.svg')
      &::after
        background: #f0f9f9
    &:nth-child(2),&:nth-child(3n+2)
      background: #fffaed
      &::before
        background: #ffe191 center / 55% no-repeat url('#{$url_images}/news/comment/user.svg')
      &::after
        background: #fffaed
    &:nth-child(3),&:nth-child(3n+3)
      background: #fdf3f3
      &::before
        background: #f7d0ce center / 55% no-repeat url('#{$url_images}/news/comment/user.svg')
      &::after
        background: #fdf3f3
    .p-comment
      margin: 0 0 0 15px
      @include md
        margin: 0 0 0 25px
      &::before,&::after
        display: none
        background: none
      &:nth-child(3n+1),&:nth-child(3n+2),&:nth-child(3n+3)
        background: inherit
    & + li
      margin-top: 0.5rem


.p-comment-form
  padding: $gutter-width
  // border: none !important

  .desc
    font-size: $size-5
    line-height: 1.3

  .p-comment-desc
    margin-bottom: 10px

    a
      color: $color-pink
      font-size: $size-4
      border-bottom: 1px solid $color-pink
      font-weight: bold

      &:hover
        text-decoration: none

      .babysymbol
        font-size: 1em

  .p-comment-form-btn
    height: 2.5em
    padding: $half-gutter-width 0
    box-shadow: none
    border: 2px solid $color-pink
    background: $color-pink
    color: $color-white
    font-weight: bold
    display: block
    max-width: 280px
    width: 100%
    margin: 0 auto
    border-radius: 3px
    font-size: $size-3
    transition: all 0.3s

    &.disable
      border: 2px solid transparent
      pointer-events: none
      background: $color-supergray
      color: $color-gray
    &:hover
      opacity: 0.8

// textarea
//   box-shadow: 0px 0px 0px 0px rgba(235, 116, 141, 0.5) inset !important
//   transition: 0.3s box-shadow

//   &:focus
//     border: 1px solid $color-pink !important
//     box-shadow: 0px 0px 0px 1px rgba(235, 116, 141, 0.5) inset !important

.p-comment-sort
  margin: 5px 0

  ul
    display: flex
    justify-content: flex-end
    border-top: none !important

    li
      margin: 5px 10px

      &.is-active
        color: $color-pink

        a
          color: $color-pink

          &:hover
            text-decoration: none

      a
        padding: 2px 10px

      &:last-child:before
        content: ""
        width: 1px
        height: 15px
        display: inline-block
        background: $color-gray
        left: -6px
        position: relative

.p-comment-area
  padding: 0 10px
  margin-bottom: 20px

.p-comment-posted
  background: rgba(128, 193, 195, 0.2)
  padding: 10px
  border-radius: 3px
  font-size: $size-4
  line-height: 1.7


/* 名前ランキング検索フォーム



#nazuke-nameranking

  .nameranking-block_box
    border: 1px solid transparent
    border-radius: 4px
    background: #ffffff
    position: relative
    padding: 10px
    margin-top: 25px

    .title.is-search
      background: transparent
      position: absolute
      left: 50%
      top: -18px
      transform: translateX(-50%)
      border-radius: 290486px
      padding: 2px 0
      color: #ffffff
      font-weight: bold
      letter-spacing: 0.04em
      min-width: 120px
      text-align: center

    &.is-orange
      border-color: #EB9D74

      .title.is-search
        background-color: #EB9D74

    &.is-green
      border-color: #81C1C3

      .title.is-search
        background-color: #81C1C3

    &.is-purple
      border-color: #9b96b7

      .title.is-search
        background-color: #9b96b7


  @keyframes ripple
    0%
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0)

    50%
      box-shadow: 0px 0px 0px 20px rgba(0, 0, 0, 0.05)

    100%
      box-shadow: 0px 0px 0px 20px rgba(0, 0, 0, 0)


  .radio-block
    margin: 16px 0
    display: flex
    justify-content: space-between

  .is-radio
    width: 48%

    input[type=radio]
      display: none

      &:checked + label
        &:before
          border-color: transparent
          animation: ripple 0.2s linear forwards

        &:after
          transform: scale(1) translateY(-50%)

    label
      display: block
      border-radius: 4px
      border: 1px solid rgba(0, 0, 0, 0.2)
      min-height: 20px
      position: relative
      padding: 5px
      padding-left: 20px
      margin-bottom: 0
      margin-left: 0
      line-height: inherit
      cursor: pointer
      vertical-align: bottom
      font-weight: bold
      text-align: center
      background: transparent
      font-size: 1.6rem

      &:before, &:after
        position: absolute
        content: ""
        border-radius: 50%
        transition: all 0.3s ease
        transition-property: transform, border-color

      &:before
        left: 6px
        top: 50%
        width: 15px
        height: 15px
        border: 1px solid rgba(0, 0, 0, 0.2)
        transform: translateY(-50%)
        box-sizing: border-box

      &:after
        top: 50%
        left: 10px
        width: 7px
        height: 7px
        transform: scale(0) translateY(-50%)
        background: transparent

    &.is-orange
      input[type=radio]:checked + label
        border-color: #EB9D74
        background: #f8dccd

        &:before
          border-color: #EB9D74

      label:after
        background: #EB9D74

    &.is-green
      input[type=radio]:checked + label
        border-color: #81C1C3
        background: #d7ecec

        &:before
          border-color: #81C1C3

      label:after
        background: #81C1C3

  .input-block
    display: flex

    input[type=text]
      -webkit-appearance: none
      align-items: center
      border: 1px solid transparent
      border-radius: 4px
      box-shadow: none
      display: inline-flex
      height: 2.5em
      justify-content: flex-start
      line-height: 1.5
      padding-bottom: calc(0.5em - 1px)
      padding-left: calc(0.75em - 1px)
      padding-right: calc(0.75em - 1px)
      padding-top: calc(0.5em - 1px)
      position: relative
      vertical-align: top
      background-color: #ffffff
      border-color: transparent
      border-radius: 4px
      color: #666666
      width: 100%
      height: 40px
      border-right: 0
      border-radius: 4px 0 0 4px

    .button
      border-radius: 0 4px 4px 0
      color: #ffffff
      font-weight: bold
      height: 40px
      line-height: 10px
      margin: 0

  .is-orange .input-block
    input[type=text]
      border-color: #EB9D74

    .button
      background: #EB9D74
      border-color: #EB9D74

  .is-green .input-block
    input[type=text]
      border-color: #81C1C3

    .button
      background: #81C1C3
      border-color: #81C1C3

  .is-purple .input-block
    input[type=text]
      border-color: #9b96b7

    .button
      background: #9b96b7
      border-color: #9b96b7


  .title.is-nameranking
    text-align: center
    font-weight: bold
    color: #eb748d
    padding: 0 10px 5px
    border-bottom: 1px solid
    margin-bottom: 5px

  .input-block .is-select
    position: relative

    &:before
      position: absolute
      top: 18px
      right: 6px
      width: 0
      height: 0
      padding: 0
      content: ''
      border-left: 4px solid transparent
      border-right: 4px solid transparent
      border-top: 4px solid #666666
      pointer-events: none

    select
      align-items: center
      box-shadow: none
      display: inline-flex
      height: 2.5em
      line-height: 1.5
      padding-bottom: calc(0.5em - 1px)
      padding-top: calc(0.5em - 1px)
      padding-left: calc(0.5em - 1px)
      position: relative
      vertical-align: top
      background-color: #ffffff
      color: #333333
      min-width: 10rem
      height: 40px
      letter-spacing: -0.02em
      border-left: 1px solid #dddddd !important
      position: relative
      background: transparent
      background-image: none
      -webkit-box-shadow: none
      box-shadow: none
      -webkit-appearance: none
      -moz-appearance: none
      appearance: none
      white-space: nowrap
      border: 1px solid transparent
      outline: none
      border-radius: 0
      margin: 0

  .is-orange .input-block .is-select select
    border-color: #EB9D74

  .is-green .input-block .is-select select
    border-color: #81C1C3


// ニューストップ固定枠s

.news-top-fixed-carousel

  .news-top-fixed-carousel-wrap
    border: 1px solid #eeeeee
    box-shadow: 0px 3px 5px 0px rgb(221 221 221 / 45%)

    .is-article-title .title
      padding: 12px

  .news-top-fixed-carousel-pagination
    text-align: center
    .swiper-pagination-bullet
      width: 6px
      height: 6px
      margin: 0 4px
      &.swiper-pagination-bullet-active
        background: #eb748d
